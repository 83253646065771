export function formatUSPhoneNumber(input) {
    // Remove all non-digit characters from the input
    const cleaned = input.replace(/\D/g, '');
    // Check if the cleaned number has exactly 10 digits
    if (cleaned.length === 10) {
        // Return the formatted number
        return `+1${cleaned}`;
    }
    else {
        // Return null for invalid input
        return null;
    }
}
