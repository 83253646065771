import { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { QuestionProps } from '../../pa-prop-types';
import { useMask } from '@react-input/mask';
import { Button, InputAdornment, TextField } from '@mui/material';
import { formatUSPhoneNumber, useSendVerificationCodeMutation } from '@cyren/common-lib';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import { useData } from '../../../report/use-data';
import ModalValidateSMS from './components/ModalValidateSMS';
import { FaCheck } from 'react-icons/fa';
import { QsOptions } from '../../patient-types';

function QsInputPhoneNumber(
  props: InputHTMLAttributes<HTMLInputElement> &
    QuestionProps & {
      onUpdate: (value?: string | null) => void;
      focusOnLoad?: boolean;
      typeOptions?: QsOptions | null;
    }
) {
  const { focusOnLoad, onUpdate, value, typeOptions } = props;

  const [sendVerificationCode, { loading: loadingSend }] = useSendVerificationCodeMutation();

  const [
    {
      dataState: { visit },
    }
  ] = useData();
  


  // RE INIT VALUE WHEN NEW VALUE PROVIDED
  const [initialValue, setInitialValue] = useState(value);
  useEffect(() => {
    if (initialValue !== value) {
      setInitialValue(value);
      setValueTemp(value != null ? `${value}` : '');
    }
  }, [value]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (focusOnLoad && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [focusOnLoad]);

  const { sltStr } = useStaticLocales();

  const [valueTemp, setValueTemp] = useState(`${value}` || '');
  // const [valueDebbed] = useDebounce(valueTemp, 300);
  const [valueDebbed] = useDebounce(valueTemp, 150);

  const [validateMessageId, setValidateMessageId] = useState<string | null>(null);

  const mask = "(___) ___-____";
  const inputRefMask = useMask({ mask, replacement: { _: /\d/ }, showMask: true });

  useEffect(() => {
    if (value !== valueDebbed) {
      onUpdate(valueDebbed === mask ? "" : valueDebbed);
    }
  }, [valueDebbed]);

  return (
    <div className="QsInputPhoneNumber">
      {validateMessageId &&
        <ModalValidateSMS messageId={validateMessageId} recipientAddress={valueTemp}
          onClose={() => {
            setValidateMessageId(null);
          }}
        />
      }
      <TextField fullWidth
        ref={inputRef}
        inputRef={inputRefMask}
        value={valueTemp}
        onChange={event => setValueTemp(event.target.value)}
        type="tel"
        disabled={loadingSend}
        autoComplete='off'
        InputProps={typeOptions?.verifyPhoneNumber === 'disabled' ? {} : {
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                onClick={async () => {
                  if (!visit?.id) {
                    return;
                  }
                  const messageID = await sendVerificationCode({
                    variables: {
                      id: visit.id,
                      data: { recipientAddress: valueTemp }
                    }
                  });
                  if (messageID.data?.sendVerificationCode) {
                    setValidateMessageId(messageID.data?.sendVerificationCode);
                  }
                }}
                color="primary"
                disabled={!formatUSPhoneNumber(valueTemp) || loadingSend || 
                  visit?.verifiedPhone === valueTemp}
              >
                {visit?.verifiedPhone === valueTemp ? (
                  <>
                    <FaCheck className="mr-2" />
                    {sltStr({ key: 'btn-verified' })}
                  </>

                ) : (
                  <>
                    {loadingSend && <span className='loading loading-spinner mr-2'></span>}
                    {sltStr({ key: 'btn-verify' })}
                  </>
                )}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </div >
  );
}

export default memo(QsInputPhoneNumber);
