import { atomFamily, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { defaultReportState } from '../../patient/hooks/use-report';
import { Key, ReportStateType } from '../../patient/patient-types';

const { persistAtom } = recoilPersist();

export const AdminPatientSurveyState = atomFamily<ReportStateType, Key | null | undefined>({
  key: 'AdminPatientSurveyState',
  default: defaultReportState,
  effects_UNSTABLE: [persistAtom],
});

export function usePersistSurveyState({ surveyId }: { surveyId?: Key | null }) {
  const [persSurveyState, setPersSurveyState] = useRecoilState(AdminPatientSurveyState(surveyId));

  function resetPersState() {
    setPersSurveyState({
      globalAnswerMap: {},
      nextTreeKeys: [],
      presentIllness: [],
      treeStates: [],
    });
  }
  return [{ persSurveyState }, { resetPersState, setPersSurveyState }] as const;
}
