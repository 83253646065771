import {
  Enum_Question_Type,
  WatchQueryFetchPolicy,
  notNull,
  useQuestionTreesLazyQuery,
  useQuestionsLazyQuery,
  useTransForTreesLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { difference, isEmpty, size } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AppStateCookie } from '../app/use-app-state';
import { convQs, convTrans, convTree, fixLocaleMapping } from '../gql-tools/utils/convert';
import { isEqualTrans } from '../models/translation';
import { PaDataRepoState } from './use-data';
import { usePaSurveyParams } from './use-pa-survey-params';
import { TQuestion } from '../graphql-enhanced';

const pageSize = 10000;

export function usePatientTreeData({
  treeKey: selectedTreeKey,
  autoRefetch,
}: { treeKey?: string | null; autoRefetch?: boolean } = {}) {
  const { treeKey: urlParamTreeKey } = usePaSurveyParams();

  const treeKey = selectedTreeKey || urlParamTreeKey;
  const [appStateCookie] = useRecoilState(AppStateCookie);
  const localeKey = appStateCookie?.localeKey || 'english';

  const [dataState, setDataState] = useRecoilState(PaDataRepoState);
  const { trees, survey } = dataState;

  const selectedTree = trees?.find((t) => t.treeKey === treeKey);

  const [fetchSxQssGql, { data: dataSxQss, loading: loadingSxQss }] = useQuestionsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const list = data?.questions?.data?.map(convQs);

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            if (item == null) return;

            const idx = draft.questions.findIndex((t) => t.questionKey === item?.questionKey);

            if (idx > -1) {
              draft.questions[idx] = item;
            } else {
              draft.questions.push(item);
            }
          });
        });
      });
    },
  });

  const [fetchQssGql, { data: dataQss, loading: loadingQss }] = useQuestionsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const list = data?.questions?.data?.map(convQs);

      const sxQss = list?.filter(
        (q) =>
          q?.type === Enum_Question_Type.Collapsible || q?.type === Enum_Question_Type.SxCollect
      );

      const sxQsKeys = sxQss?.reduce((p, q) => {
        return [...p, ...(q?.answerKeys || [])].filter(notNull);
      }, [] as string[]);

      if (!isEmpty(sxQsKeys)) {
        fetchSxQssGql({
          fetchPolicy: 'cache-first',
          variables: {
            filters: {
              questionKey: {
                in: sxQsKeys,
              },
              survey: {
                id: {
                  eq: survey?.id,
                },
              },
            },
            pagination: {
              pageSize,
            },
          },
        });
      }

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            if (item == null) return;
            const idx = draft.questions.findIndex((t) => t.questionKey === item?.questionKey);

            if (idx > -1) {
              draft.questions[idx] = item;
            } else {
              draft.questions.push(item);
            }
          });
        });
      });
    },
  });

  const [fetchTreesGql, { data: dataTrees, loading: loadingTrees }] = useQuestionTreesLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const list = data?.questionTrees?.data?.map(convTree);

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            const idx = draft.trees.findIndex((t) => t.treeKey === item.treeKey);

            if (idx > -1) {
              draft.trees[idx] = item;
            } else {
              draft.trees.push(item);
            }
          });
        });
      });
    },
  });

  const [fetchTransForTreesGql, { data: dataTrans, loading: loadingTrans }] =
    useTransForTreesLazyQuery({
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        const list = data?.transForTrees?.data?.map(convTrans);

        setDataState((st) => {
          return produce(st, (draft) => {
            list?.forEach((item) => {
              const idx = draft.translations.findIndex((t) =>
                isEqualTrans({ trans1: t, trans2: item })
              );

              if (idx > -1) {
                draft.translations[idx] = item;
              } else {
                draft.translations.push(item);
              }
            });
          });
        });
      },
    });

  const fetchTransForTrees = async ({
    fetchPolicy,
    treeKey: treeKeyIn,
  }: {
    fetchPolicy?: WatchQueryFetchPolicy;
    treeKey: string;
  }) => {
    if (survey?.id == null || treeKeyIn == null) {
      return;
    }

    fetchTransForTreesGql({
      fetchPolicy,
      variables: {
        input: {
          survey: survey?.id,
          keys: [treeKeyIn],
          // @ts-ignore
          locale: fixLocaleMapping(localeKey),
        },
      },
    });
  };

  const fetchTrees = async ({
    fetchPolicy,
    treeKey: treeKeyIn,
    treeKeyList
  }: {
    fetchPolicy?: WatchQueryFetchPolicy;
    treeKey?: string;
    treeKeyList?: string[];
  }) => {
    if (survey?.id == null || (treeKeyIn == null && size(treeKeyList) === 0)) {
      return;
    }

    // check cache and skip it when there is one fetched
    let uncachedKeys = [] as string[];
    if (!treeKeyList) {
      const cachedTree = trees?.find((tr) => tr.treeKey === treeKey);
      if (cachedTree != null) return;
    }
    else {
      const cachedKeys = [] as string[];
      treeKeyList.forEach((aTreeKey) => {
        if (trees?.find((tr) => tr.treeKey === aTreeKey)) {
          cachedKeys.push(aTreeKey);
        }
      });
      if (size(cachedKeys) === size(treeKeyList)) {
        return;
      }
      uncachedKeys = difference(treeKeyList, cachedKeys);
    }

    fetchTreesGql({
      fetchPolicy,
      variables: {
        filters: {
          treeKey: treeKeyList ?
            { in: uncachedKeys } :
            {
              eq: treeKeyIn,
            },
          survey: {
            id: {
              eq: survey?.id,
            },
          },
        },
        pagination: {
          pageSize,
        },
      },
    });
  };

  async function fetchQss({ fetchPolicy, qsKeys }: { fetchPolicy?: WatchQueryFetchPolicy, qsKeys?: string[] }): Promise<TQuestion[]> {
    const targetQsKeys = !isEmpty(qsKeys) ? qsKeys : selectedTree?.entryQuestionKeys || [];

    if (survey?.id == null || isEmpty(targetQsKeys)) {
      return [];
    }

    const result = await fetchQssGql({
      fetchPolicy,
      variables: {
        filters: {
          questionKey: {
            in: targetQsKeys,
          },
          survey: {
            id: {
              eq: survey?.id,
            },
          },
        },
        pagination: {
          pageSize,
        },
      },
    });

    // return the results in case the caller needs to see them
    const finalResults: TQuestion[] = result.data?.questions?.data?.map(convQs)
      .filter((i): i is TQuestion => i !== undefined) || [];
    return finalResults;
  }

  // selected treeId
  useEffect(() => {
    if (!autoRefetch || selectedTree?.treeKey == null) return;

    fetchTransForTrees({
      treeKey: selectedTree?.treeKey,
    });
    fetchTrees({
      treeKey: selectedTree?.treeKey,
    });
    fetchQss({
      fetchPolicy: 'cache-first',
    });
  }, [autoRefetch, survey?.id, localeKey, selectedTree?.treeKey]);

  // when selected tree not found
  useEffect(() => {
    if (!autoRefetch || selectedTree != null || treeKey == null) return;

    fetchTrees({
      treeKey,
    });
  }, [autoRefetch, survey?.id, localeKey, selectedTree, treeKey]);

  return [
    {
      dataState,
      dataTrees,
      dataQss,
      dataSxQss,
      loadingQss,
      loadingTrans,
      loadingTrees,
      loadingSxQss,
      dataTrans,
      survey,
    },
    {
      fetchQss,
      fetchTrees,
      fetchTransForTrees,
    },
  ] as const;
}
