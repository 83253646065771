import { subMinutes } from 'date-fns';
import notesRaw from '../../assets/files/doctors-notes.json';
export function getDoctorsNotes() {
    return notesRaw.map((item) => {
        const arrivedAt = subMinutes(new Date(), parseInt(item.arrive));
        return {
            ...item,
            arrivedAt,
        };
    });
}
export const notes = getDoctorsNotes();
