/* eslint-disable no-fallthrough */

import { UploadFileFragment } from '@cyren/common-lib';
import { startsWith } from 'lodash';
import envs from '../common/envs';
import { FormatsEnum } from '../types/image';

export function getMediaUrl(url?: string | null) {
  if (!url) {
    return undefined;
  }

  if (startsWith(url, 'http')) {
    return url;
  }
  return `${envs.backendBaseUrl}${url}`;
}

export function getMediumImgLink(upload?: UploadFileFragment) {
  return getSizedMediaLink({
    upload,
    size: FormatsEnum.medium,
  });
}

export function getSmallLink(upload?: UploadFileFragment) {
  return getSizedMediaLink({
    upload,
    size: FormatsEnum.small,
  });
}

export function getSizedMediaLink({
  upload,
  size = FormatsEnum.small,
}: {
  upload?: UploadFileFragment;
  size?: FormatsEnum;
}) {
  if (!upload) {
    return null;
  }

  const formats = upload?.attributes?.formats;

  // NOTE intentional fallthrough
  switch (size) {
    // @ts-ignore
    case FormatsEnum.thumbnail:
      if (formats?.thumbnail?.url) {
        return getMediaUrl(formats?.thumbnail?.url);
      }
    // @ts-ignore
    case FormatsEnum.small:
      if (formats?.small?.url) {
        return getMediaUrl(formats?.small?.url);
      }
    // @ts-ignore
    case FormatsEnum.medium:
      if (formats?.medium?.url) {
        return getMediaUrl(formats?.medium?.url);
      }
    // eslint-disable-next-line
    case FormatsEnum.large:
      if (formats?.large?.url) {
        return getMediaUrl(formats?.large?.url);
      }
  }

  return getMediaUrl(upload?.attributes?.url);
}
