import classNames from 'classnames';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { InputHTMLAttributes, memo, useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { atomFamily } from 'recoil';
import Modal from '../../../../components/Modal';
import { useOnNavChange } from '../../../../hooks/use-on-nav-change';
import { useTranslations } from '../../../hooks/use-translations';
import { QuestionProps } from '../../../pa-prop-types';
import { AnswerValueT, Keys, QuestionAnswerMap, QuestionType } from '../../../patient-types';
import BodyPartSelector from './BodyPartSelector';
import { useStaticLocales } from '../../../../admin/hooks/locales/use-static-locale';

export const PaAnswerValueState = atomFamily<QuestionAnswerMap, string | null | undefined>({
  key: 'PaAnswerValueState',
  default: {},
});

function ModalBodySelect(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      onSave: (p: AnswerValueT) => void;
      onClose: () => void;
      question: QuestionType;
    }
) {
  const { tOptions, onSave, onClose, question, locale } = props;
  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();
  const { questionKey } = question;
  const [selectedStack, setSelectedStack] = useState<Keys>([]);

  const [interimAnswerValue, setInterimAnswerValue] = useState<AnswerValueT | null>(null);

  const handleNavChange = useCallback(() => {
    if (!isEmpty(selectedStack)) {
      setSelectedStack((st) =>
        produce(st, (dr) => {
          dr.pop();
        })
      );
      return;
    }
    onClose();
  }, [onClose, selectedStack, setSelectedStack]);

  useOnNavChange({
    block: true,
    onChange: handleNavChange,
  });

  return (
    <Modal open className={classNames('p-0 overflow-hidden')} containerCls=''>
      <div className='t_ModalBodySelect flex-1 flex-col overflow-hidden svg-path-disable'>
        <div className='flex-col flex-none overflow-hidden w-full'>
          <div className='flex-center-y gap-2 px-5 border-b flex-1 py-2'>
            <div className='flex-col flex-1'>
              <div className='text-base flex-center'>
                {t(questionKey, tOptions, { fallbackValue: '' })}
              </div>
              <div className='text-sm flex-center opacity-60'>{sltStr({ key: 'label-best-guess' })}</div>
            </div>
            <div className='w-0 overflow-visible'>
              <button
                className={classNames('p-2 inline')}
                onClick={async () => {
                  if (interimAnswerValue) {
                    onSave(interimAnswerValue);
                  }
                  else {
                    onClose();
                  }
                }}
              >
                <MdClose size={20} className='opacity-60 -top-0 relative right-7' />
              </button>
            </div>
          </div>
        </div>

        <div className='flex-col overflow-hidden'>
          <BodyPartSelector
            {...props}
            onSave={(aMap) => {
              onSave(aMap);
            }}
            onInterimChange={(aMap) => {
              setInterimAnswerValue(aMap);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalBodySelect);
