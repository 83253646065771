import { startsWith } from 'lodash';
import { useLocation, useParams } from 'react-router';

export function usePaSurveyParams() {
  const params = useParams<{
    orgId?: string;
    treeKey?: string;
    shortId?: string;
    treeId?: string;
    adhocId?: string;
    referralMessageId?: string;
    '*'?: string;
  }>();
  const loc = useLocation();
  const nParams = { ...params };

  const splatParam = params['*'];
  const splatParams = splatParam?.split('/');

  // patient survey route
  const isPaSurveyRoute = startsWith(loc.pathname, '/s');

  if (isPaSurveyRoute) {
    nParams.orgId = splatParams?.[0];
    nParams.shortId = splatParams?.[1];
  }

  const isPaAddOnForm = splatParams && splatParams?.length > 3 && splatParams[2] === 'addon';
  if (isPaAddOnForm) {
    nParams.referralMessageId = splatParams?.[3];
  }

  // patient adhoc route
  const isPaAdhocRoute = splatParams && splatParams?.length > 3 && splatParams[2] === 'adhoc';

  if (isPaAdhocRoute) {
    nParams.adhocId = splatParams?.[3];
  }

  return nParams;
}
