import { FlowItemConfigT } from '../template-types';

export function consolidateFlowItem({ item }: { item: FlowItemConfigT }) {
  const { type, label, reportConfig } = item;

  switch (type) {
    case 'rep-report-template': {
      return JSON.stringify(reportConfig);
    }
    case 'rep-label': {
      return label;
    }
    default: {
      return '';
    }
  }
}
