import { to2DigitString } from '@cyren/common-lib';
import classNames from 'classnames';
import { differenceInSeconds, intervalToDuration, subSeconds } from 'date-fns';
import { isNaN, toNumber } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useThrottledCallback } from 'use-debounce';
import envs from '../common/envs';

const refreshIntervalMs = 1000;
const timeoutInSecondsConfig = toNumber(envs.autoLogout) * 60;

type Props = {
  position?: 'top' | 'bottom';
};

export function AutoLogoutBody(props: Props) {
  // eslint-disable-next-line
  const { position } = props;
  const nav = useNavigate();
  const [activeDatetime, setActiveDatetime] = useState(new Date());
  // to rerender every second
  const [, setSecondsPassed] = useState(0);

  const timeoutInSeconds = differenceInSeconds(new Date(), activeDatetime);

  let timedOut = false;
  if (timeoutInSecondsConfig < timeoutInSeconds) {
    timedOut = true;
  }

  const tick = useCallback(() => {
    // if (timedOut) return;

    setSecondsPassed((st) => {
      return st + 1;
    });
  }, [timedOut]);

  useEffect(() => {
    if (timedOut) {
      nav('/admin/auth/logout');
    }
  }, [timedOut]);

  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, refreshIntervalMs);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const expTime = subSeconds(new Date(), timeoutInSecondsConfig);
  const interval = intervalToDuration({
    start: activeDatetime,
    end: expTime,
  });

  const handleMouseMove = useThrottledCallback(() => {
    setActiveDatetime(new Date());
  }, 2000);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className={classNames(
        'fixed right-0 z-50 bg-white py-1 px-2 rounded',
        'bottom-0 md:top-0 md:bottom-auto',
      )}
    >
      <div className='flex gap-2 flex-nowrap text-sm'>
        {envs.tenantId && <div className='uppercase'>{envs.tenantId}</div>}
        <div>
          {(interval.hours || 0) > 0 && `${to2DigitString(interval.hours)}:`}
          {to2DigitString(interval.minutes)}:{to2DigitString(interval.seconds)}
        </div>
      </div>
    </div>
  );
}

export function AutoLogout(props: Props) {
  if (!envs.autoLogout) {
    return null;
  }

  if (isNaN(timeoutInSecondsConfig)) {
    return null;
  }

  return <AutoLogoutBody {...props} />;
}

export default memo(AutoLogout);
