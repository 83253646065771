import pmhxQuestions from '../../assets/files/pmhx-questions.json';
import { notNull } from '../utils';
import { convertItemToQuestion, mapItemToDescLocale, mapItemToLocale } from '../utils/csv';
export function fetchPmhxQsLocales() {
    const list = pmhxQuestions.map(mapItemToLocale);
    const descList = pmhxQuestions.map(mapItemToDescLocale);
    return [...list, ...descList];
}
export function fetchPmhxQsTreeByKey(key, options) {
    const trQs = fetchPmhxQuestionsByTreeKey(key);
    return {
        ...options,
        treeKey: key,
        entryQuestionKeys: trQs.map((q) => q.questionKey),
    };
}
export function fetchPmhxQuestions() {
    return pmhxQuestions.map(convertItemToQuestion).filter(notNull);
}
export function fetchPmhxQuestionsByTreeKey(treeKey) {
    return pmhxQuestions
        .filter((item) => item['tree-key'] === treeKey)
        .map(convertItemToQuestion)
        .filter(notNull);
}
