/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@cyren/common-lib';
import { toast } from 'react-toastify';

export function onError(e: ApolloError) {
  // @ts-ignore
  const errors = e.graphQLErrors?.[0]?.extensions?.error?.details?.errors;
  const msgs = errors?.map((i: any) => i.message);
  const msg = msgs?.join(', ');

  // If an apollo error fires, throw it asynchronously so that the 
  // global exception handler can add it to the log
  setTimeout(() => { throw e; }, 0);

  if (msg) {
    toast.error(msg);
    return;
  }

  toast.error(e.message);
}
