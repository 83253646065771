import { MsalProvider, useMsal } from '@azure/msal-react';
import { memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { createAADInstance } from '../admin/auth/aad/aad-client';
import { AppStateCookie } from '../app/use-app-state';
import { loginRequest } from './aad/config';
import useAuth from './use-auth';

// eslint-disable-next-line
// const refreshIntervalMs = 20 * 1000;
const refreshIntervalMs = 5 * 60 * 1000;

export function MsAuthRefresherBody() {
  const [, { handleLogout }] = useAuth();
  const { instance } = useMsal();
  // eslint-disable-next-line
  const [{ msAuth }, setAppStateCookie] = useRecoilState(AppStateCookie);

  async function checkValidToken() {
    // eslint-disable-next-line
    try {
      await instance.initialize();

      const accounts = await instance.getAllAccounts();
      const account = accounts?.[0];

      instance
        .acquireTokenSilent({
          ...loginRequest,
          account,
          forceRefresh: true,
        })
        .catch(function handleError(error) {
          // TODO logout user when failed to refresh token
          // eslint-disable-next-line
          console.error('MS error', error);
          // eslint-disable-next-line
          console.log('accounts', accounts);

          toast.info(
            'There was an issue (active session) with your Microsoft login account. Please try to login again. (Logging you out in 10 seconds...)',
            {
              autoClose: false,
            },
          );

          setTimeout(() => {
            handleLogout();
          }, 10 * 1000);
        });
    } catch (error) {
      // eslint-disable-next-line
      console.error('MsAuthRefresher', error);
    }
  }

  // hook that runs every 10 mins
  useEffect(() => {
    if (!msAuth) return () => {};

    // initial call
    checkValidToken();
    // repeating call every 5 mins
    const interval = setInterval(checkValidToken, refreshIntervalMs);

    return () => {
      clearInterval(interval);
    };
  }, [msAuth]);

  return null;
}

const inst = createAADInstance();

export function MsAuthRefresher() {
  return (
    <MsalProvider instance={inst}>
      <MsAuthRefresherBody />
    </MsalProvider>
  );
}

export default memo(MsAuthRefresher);
