import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export function useAdminSurveyParams() {
  const [params] = useSearchParams();
  const searchKey = params.get('search');

  const paramMap = useParams<{ orgIdParam?: string; surveyId?: string; treeId?: string }>();
  // console.log('paramMap', paramMap);

  return {
    ...paramMap,
    searchKey,
  };
}

export type AdminParams = { orgIdParam?: string; surveyId?: string; treeId?: string };
export type AdminParamKeys = keyof AdminParams;

export function useAdminParams() {
  const [params] = useSearchParams();
  const searchKey = params.get('search');

  const paramMap = useParams<AdminParams>();

  return {
    ...paramMap,
    searchKey,
  };
}
