import { memo, useState, useEffect } from 'react';

import {
  Button,
  FormControl,
  TextField,
  IconButton,
  Box,
  Typography,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Enum_Transapi_Locale, Enum_Translation_Locale, useSendAdHocQuestionsMutation, useUpdatePatientVisitAddAdhocQuestionMutation } from '@cyren/common-lib';
import { size } from 'lodash';
import { TPatientVisit, TTransApi } from '../../../graphql-enhanced';
import { onError } from '../../../utils/apollo-utils';
import { toast } from 'react-toastify';
import { genMessageLongId } from '../../nav-utils';
import { SubmitButtonState } from './ModalSendCommunication';
import { useUserTranslateStrings } from '../../../report/use-user-translate-strings';
import { AdhocQuestionType } from '../../../patient/patient-types';




function PanelAddAdHocQuestions(props: {
  onClose: () => void;
  visit: TPatientVisit;
  setSubmitButtonState: ((newState: SubmitButtonState) => void)
}) {
  const { onClose, visit, setSubmitButtonState } = props;

  const patientLocale: Enum_Transapi_Locale = (visit?.surveyState.reportState?.locale || 'english') as Enum_Transapi_Locale;

  const updateSubmitButtonState = () => {
    setSubmitButtonState(
      {
        loading: addingAdhoc || sending,
        enabled: size(pendingAdHocQuestions) > 0,
        message: "Send",
        callback: handleSave
      });
  }

  useEffect(() => {
    updateSubmitButtonState();
  }, []);


  const [newAdHocQuestion, setNewAdHocQuestion] = useState<string>("");
  const [pendingAdHocQuestions, setPendingAdHocQuestions] = useState<AdhocQuestionType[]>([]);

  const [translateUserStrings, { loading: translating }] = useUserTranslateStrings((results: TTransApi[]) => {
    // add any newly received translated strings into the map, but keep the old ones as well
    const key = newAdHocQuestion;
    const result = results.find(t => t.target === key);
    if (result) {
      setPendingAdHocQuestions([...pendingAdHocQuestions,
      {
        type: "text",
        questionText: key,
        translatedQuestionText: result.output,
        uniqueId: genMessageLongId()
      }
      ])
    }
    setNewAdHocQuestion("");

  }, patientLocale);


  const [addAdhocQuestion, { loading: addingAdhoc }] = useUpdatePatientVisitAddAdhocQuestionMutation({
    onError,
  });

  const [sendAdHocSMS, { loading: sending }] = useSendAdHocQuestionsMutation({
    onError,
  });

  useEffect(() => {
    updateSubmitButtonState();
  }, [pendingAdHocQuestions, addingAdhoc, sending]);

  const handleSave = () => {


    if (!visit.surveyState.reportState?.systemValueMap?.phone) {
      throw new Error("Phone number not found");
    }
    addAdhocQuestion({
      variables: {
        id: visit.id!,
        data: {
          adHocState: {
            adHocQuestions: pendingAdHocQuestions
          }
        }
      },
    }).then(() => {
      sendAdHocSMS({
        variables: {
          id: visit?.id as string,
          data: {
            messageContent: JSON.stringify({
              messageSummary: `${size(pendingAdHocQuestions)} questions`,
              contentDetails: pendingAdHocQuestions.map(i => i.uniqueId)
            }),
            recipientAddress: visit.surveyState.reportState?.systemValueMap?.phone as string,
            uniqueID: genMessageLongId(),
          }
        },
      }).then(() => {
        toast.success('Sent');
        onClose();
      });
    });
  }




  return (
    <Box sx={{ flexDirection: 'column', margin: 2 }}>
      <Typography>Enter a question you would like to send to the patient.</Typography>
      <FormControl fullWidth sx={{ marginTop: 3, marginBottom: 3 }}>
        <TextField value={newAdHocQuestion}
          label="Question"
          placeholder="Do you have any numbness in your hand?"
          fullWidth
          disabled={translating}
          autoComplete="off"  // disable auto-complete to prevent accidental PHI/PII leakage
          onChange={(e) => {
            setNewAdHocQuestion(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="contained"
                  onClick={() => {
                    if (translating) return;
                    // @ts-ignore
                    if (patientLocale === 'english') {
                      setPendingAdHocQuestions([...pendingAdHocQuestions,
                      {
                        type: "text",
                        questionText: newAdHocQuestion,
                        uniqueId: genMessageLongId()
                      }]);
                      setNewAdHocQuestion("");
                    } else {
                      translateUserStrings([newAdHocQuestion], [Enum_Translation_Locale.English]);
                    }
                  }}
                  color="primary"
                  disabled={!size(newAdHocQuestion)}>
                  {translating && <span className='loading loading-spinner'></span>}
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Box>
        <Typography variant="caption" className="opacity-80">If the patient completed the intake in another language, it will be automatically translated to that language.</Typography>
      </Box>
      {size(pendingAdHocQuestions) > 0 &&
        <Box sx={{ marginTop: 2 }}>
          <hr style={{ marginBottom: '1rem' }} />
          {pendingAdHocQuestions.map((question) => {
            return <Box key={question.uniqueId} sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <Typography>{question.questionText}</Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setPendingAdHocQuestions(pendingAdHocQuestions.filter(i => i.uniqueId !== question.uniqueId));
                }}
                sx={{
                  marginLeft: 'auto'
                }}
              >
                <CloseIcon />
              </IconButton>

            </Box>
          })}
        </Box>

      }
    </Box >
  );
}

export default memo(PanelAddAdHocQuestions);
