import classNames from 'classnames';
import { isNaN, omit, size, toNumber } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useAutosizeTextArea from '../../../common/hooks/use-auto-size-textarea';
import { QuestionType } from '../../../report/types';
import { QuestionProps } from '../../pa-prop-types';
import { propsToIgnore } from './qs-const';

function QsTextarea(
  props: InputHTMLAttributes<HTMLTextAreaElement> &
    QuestionProps & {
      question: QuestionType;
      onUpdate: (value?: string) => void;
    }
) {
  const { onUpdate, className, value, question, error, ...rest } = props;
  const { typeOptions } = question || {};
  const { max } = typeOptions || {};
  const maxLength = toNumber(max);

  const [valueTemp, setValueTemp] = useState(`${value}` || '');
  const [valueDebbed] = useDebounce(valueTemp, 300);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef, `${valueTemp}`);

  const textLen = size(`${valueTemp}`);

  useEffect(() => {
    if (value !== valueDebbed) {
      onUpdate(valueDebbed);
    }
  }, [valueDebbed]);

  return (
    <div className='t_QsTextarea form-control'>
      <textarea
        ref={textAreaRef}
        {...omit(rest, propsToIgnore)}
        maxLength={isNaN(maxLength) ? undefined : maxLength}
        className={classNames(
          'textarea textarea-bordered w-full rounded-xl',
          className,
          error && 'textarea-error'
        )}
        onChange={(e) => {
          setValueTemp(e.target.value);
        }}
        value={valueTemp}
      ></textarea>
      {!isNaN(maxLength) && (
        <label className='label'>
          <span
            className={classNames(
              'label-text-alt',
              `${textLen}` === `${maxLength}` && 'text-red-500'
            )}
          >
            {textLen || 0} / {maxLength}
          </span>
        </label>
      )}
    </div>
  );
}

export default memo(QsTextarea);
