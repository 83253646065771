export const propsToIgnore = [
  'tOptions',
  'onUpdate',
  'isDisplayMode',
  'error',
  'updateValue',
  'updateAnswer',
  'onUiMetadataChange',
  'treeState',
  'treeKey',
];
