import { memo, useState, useEffect } from 'react';

import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  SelectChangeEvent,
  InputLabel,
  Box,
  FormControlLabel
} from '@mui/material';
import useAuth from '../../../auth/use-auth';
import { useSendAddOnFormMutation, useSurveysQuery } from '@cyren/common-lib';
import { convSurvey } from '../../../gql-tools/utils/convert';
import { size } from 'lodash';
import { TPatientVisit } from '../../../graphql-enhanced';
import { onError } from '../../../utils/apollo-utils';
import { toast } from 'react-toastify';
import { genMessageLongId } from '../../nav-utils';
import { SubmitButtonState } from './ModalSendCommunication';




function PanelSendAddOnForm(props: {
  onClose: () => void;
  visit: TPatientVisit;
  setSubmitButtonState: ((newState: SubmitButtonState) => void)
}) {
  const { onClose, visit, setSubmitButtonState } = props;

  const [{ selectedOrg }] = useAuth();
  const { data: dataSurveys } = useSurveysQuery({
    skip: selectedOrg?.id == null,
    variables: {
      sort: ['createdAt:desc'],
      filters: {
        deleted: {
          ne: true,
        },
        type: {
          eq: 'addon'
        },
        organization: {
          id: {
            eq: selectedOrg?.id,
          },
        },
      },
      pagination: {
        pageSize: 1000,
      },
    },
  });

  const updateSubmitButtonState = () => {
    setSubmitButtonState(
      {
        loading: sendingAddOn,
        enabled: size(selectedForm) > 0,
        message: "Send",
        callback: handleSave
      });
  }

  useEffect(() => {
    updateSubmitButtonState();
  }, []);




  const [selectedForm, setSelectedForm] = useState<string>("");
  const handleSelectForm = (event: SelectChangeEvent<string>) => {
    setSelectedForm(event.target.value);
  };

  useEffect(() => {
    updateSubmitButtonState();
  }, [selectedForm]);

  const [showUndeployedSurveys, setShowUndeployedSurveys] = useState<boolean>(false);
  const handleChangeUndeployedSurveys = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUndeployedSurveys(event.target.checked);
    setSelectedForm("");
  };

  const [sendAddOn, { loading: sendingAddOn }] = useSendAddOnFormMutation({
    onError,
    onCompleted: () => {
      toast.success('Sent');
      onClose();
    },
  });

  useEffect(() => {
    updateSubmitButtonState();
  }, [sendingAddOn]);


  const surveys = dataSurveys?.surveys?.data?.map(convSurvey).filter(i => {
    return (i.deployment || showUndeployedSurveys)
      && i.id !== visit?.survey?.data?.id;
  });

  const handleSave = () => {

    const form = dataSurveys?.surveys?.data?.map(convSurvey).find(i => i.id === selectedForm);
    if (!form) {
      throw new Error("Add-on questionnaire not found");
    }

    sendAddOn({
      variables: {
        rootPatientVisit: visit?.id as string,
        data: {
          messageContent: JSON.stringify({
            messageSummary: form.title,
            contentDetails: [form.id]
          }),
          recipientAddress: visit.surveyState.reportState?.systemValueMap?.phone as string,
          uniqueID: genMessageLongId(),
          formShortId: form.short_id
        }
      },
    });
  }




  return (
    <Box>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel className="bg-white" htmlFor={'form-select-questionnaire'}>Available Questionnaires</InputLabel>
        <Select value={selectedForm} onChange={handleSelectForm}
          inputProps={{ id: 'form-select-questionnaire' }}
          label="Questionnaire">
          {surveys?.map((survey) => (
            <MenuItem key={survey.id as string} value={survey.id as string}>{survey.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={showUndeployedSurveys}
            onChange={handleChangeUndeployedSurveys}
            name="undeployed"
          />
        }
        label="Show undeployed questionnaires"
      />
    </Box>
  );
}

export default memo(PanelSendAddOnForm);
