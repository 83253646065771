import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

export function useDebInputState({
  initialValue,
  prefix,
  noInitFocus,
}: { noInitFocus?: boolean; initialValue?: string | null; prefix?: string } = {}) {
  const [input, setInput] = useState(initialValue || prefix || '');
  const [debInput, setDebInput] = useDebounce(input, 400);
  const ref = useRef<HTMLInputElement>(null);
  const refTextArea = useRef<HTMLTextAreaElement>(null);

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (ref.current) {
      if (noInitFocus !== true) {
        ref.current?.focus();
      }
      ref.current.onclick = onFocus;
      ref.current.onfocus = onFocus;
      ref.current.onblur = onBlur;
    }
    if (refTextArea.current) {
      if (noInitFocus !== true) {
        refTextArea.current?.focus();
      }
      refTextArea.current.onclick = onFocus;
      refTextArea.current.onfocus = onFocus;
      refTextArea.current.onblur = onBlur;
    }
  }, []);

  return { focused, ref, refTextArea, input, setInput, debInput, setDebInput } as const;
}
