import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export function getErrorMsg(apolloError?: ApolloError) {
  const errorMsg = apolloError?.graphQLErrors.map(({ message }) => message).join(', ');

  return errorMsg;
}

export function getMsgFromErrors(errors?: readonly GraphQLError[] | undefined) {
  const errorMsg = errors?.map(({ message }) => message).join(', ') || '';

  return errorMsg;
}
