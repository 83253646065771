import produce from 'immer';
import { useRecoilState } from 'recoil';
import { getTreeByKey } from '../../report/mock-data';
import { UpdateAnswerHandler } from '../../report/prop-types';
import { ReportStateType, TreeState } from '../patient-types';
import { PaReportState } from './use-report';

export const DefaultTreeStates: TreeState[] = [
  {
    answerMap: {
      'q-preg-screen': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pregnancy-weeks': {
        answerKeys: [],
        answerValues: ['4'],
      },
      'q-last-mens-period': {
        answerKeys: [],
        answerValues: [],
      },
      'q-patient-first-name': {
        answerKeys: [],
        answerValues: ['Jane'],
      },
      'q-patient-last-name': {
        answerKeys: [],
        answerValues: ['Doe'],
      },
      'q-patient-birthday': {
        answerKeys: [],
        answerValues: ['04-17-1986 16:08:50'],
      },
      'q-patient-sex': {
        answerKeys: ['o-female'],
        answerValues: [],
      },
      'q-postpartum-screen': {
        answerKeys: [],
        answerValues: [],
      },
      'q-breastfeeding': {
        answerKeys: [],
        answerValues: [],
      },
    },
    tree: getTreeByKey('patient-info'),
    treeKey: 'patient-info',
  },
  {
    answerMap: {
      'q-chief-complaint-text': {
        answerKeys: [],
        answerValues: [
          'My left ear hurts really bad. I think a bug might have come in and stuck there',
        ],
      },
    },
    tree: getTreeByKey('cc-v2'),
    treeKey: 'cc-v2',
  },
  {
    answerMap: {
      'q-triage-covid-expos-date': {
        answerKeys: [],
        answerValues: ['04-03-2019 11:53:39'],
      },
      'q-travel-history-country': {
        answerKeys: ['AF'],
        answerValues: ['BO'],
      },
      'q-travel-history-country-date': {
        answerKeys: [],
        answerValues: ['04-03-2018 11:53:30'],
      },
      'q-triage-altered-ms': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-triage-chestpain': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-triage-dyspnea': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-triage-stroke': {
        answerKeys: ['sx-gait-disturbance', 'sx-dysarthria'],
        answerValues: [],
      },
      'q-triage-covid': {
        answerKeys: ['sx-fever'],
        answerValues: [],
      },
      'q-triage-covid-expos': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-travel-history': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
    },
    tree: getTreeByKey('triage'),
    treeKey: 'triage',
  },
  {
    answerMap: {
      'q-race': {
        answerKeys: ['o-other'],
        answerValues: [],
      },
      'q-housing-status': {
        answerKeys: ['o-housing'],
        answerValues: [],
      },
      'q-housing-instability': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-education': {
        answerKeys: ['o-highschool'],
        answerValues: [],
      },
      'q-job': {
        answerKeys: ['o-full-time'],
        answerValues: [],
      },
      'q-insurance': {
        answerKeys: ['o-public-nonchip'],
        answerValues: [],
      },
      'q-resources': {
        answerKeys: ['o-clothing'],
        answerValues: [],
      },
      'q-transportation': {
        answerKeys: ['o-transportation-medical'],
        answerValues: [],
      },
      'q-farmwork': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-veteran': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-language': {
        answerKeys: ['o-choose-not'],
        answerValues: [],
      },
      'q-domestic-security': {
        answerKeys: ['o-not-sure'],
        answerValues: [],
      },
      'q-partner': {
        answerKeys: ['o-not-sure'],
        answerValues: [],
      },
      'q-supportgroup': {
        answerKeys: ['o-1-2-week'],
        answerValues: [],
      },
      'q-stress': {
        answerKeys: ['o-little-bit'],
        answerValues: [],
      },
      'q-jail': {
        answerKeys: ['o-choose-not'],
        answerValues: [],
      },
      'q-refugee': {
        answerKeys: ['o-choose-not'],
        answerValues: [],
      },
      'q-income': {
        answerKeys: [],
        answerValues: ['test'],
      },
      'q-address': {
        answerKeys: [],
        answerValues: ['test'],
      },
      'q-family-members': {
        answerKeys: [],
        answerValues: ['3'],
      },
    },
    tree: getTreeByKey('sdoh'),
    treeKey: 'sdoh',
  },
  {
    answerMap: {
      'q-prior-visit-date': {
        answerKeys: [],
        answerValues: [],
      },
      'q-return-problem': {
        answerKeys: [],
        answerValues: [],
      },
      'q-referral-doctor': {
        answerKeys: [],
        answerValues: [],
      },
      'q-doctors-note': {
        answerKeys: [],
        answerValues: [],
      },
      'q-doctors-note-picture': {
        answerKeys: [],
        answerValues: [],
      },
      'q-category': {
        answerKeys: ['sx-injury-vehicle'],
      },
      'q-return-visit': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-referral': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
    },
    tree: getTreeByKey('start'),
    treeKey: 'start',
  },
  {
    answerMap: {
      'q-traffic-pt': {
        answerKeys: ['o-scooter'],
        answerValues: [],
      },
      'q-traffic-opponent': {
        answerKeys: ['o-bicycle'],
        answerValues: [],
      },
      'q-injury-text': {
        answerKeys: [],
        answerValues: [''],
      },
      'q-onset-datetime': {
        answerKeys: [],
        answerValues: ['05-21-2023 21:21:57'],
      },
      'q-traffic-protection-helmet': {
        answerKeys: ['o-helmet'],
        answerValues: [],
      },
      'q-injury-location': {
        answerKeys: ['o-head'],
        answerValues: [],
      },
      'q-injury-type': {
        answerKeys: ['sx-injury-pain'],
        answerValues: [],
      },
      'q-injury-pain': {
        answerKeys: [],
        answerValues: ['6'],
      },
    },
    treeKey: 'sx-injury-vehicle',
    tree: getTreeByKey('sx-injury-vehicle'),
  },
  {
    answerMap: {
      'q-ros-constitutional': {
        answerKeys: ['sx-general-weakness'],
      },
      'q-ros-neurological': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-eyes': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-ent': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-cardiovascular': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-respiratory': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-gastrointestinal': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-genitourinary': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-genitourinary-f': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-genitourinary-m': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-allergic': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-integumentary': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-musculoskeletal': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-psychiatric': {
        answerKeys: ['o-none-above'],
        answerValues: [],
      },
      'q-ros-other': {
        answerKeys: [],
        answerValues: ['test'],
      },
    },
    tree: getTreeByKey('ros'),
    treeKey: 'ros',
  },
  {
    answerMap: {
      'q-shx-smoking-year': {
        answerKeys: [],
        answerValues: ['2'],
      },
      'q-shx-smoking-pack': {
        answerKeys: ['1'],
        answerValues: [],
      },
      'q-shx-alcohol-frequency': {
        answerKeys: [],
        answerValues: [],
      },
      'q-shx-alcohol-amount': {
        answerKeys: [],
        answerValues: [],
      },
      'q-shx-illicit-text': {
        answerKeys: [],
        answerValues: [],
      },
      'q-fhx-text': {
        answerKeys: [],
        answerValues: ['family med problem exam1'],
      },
      'q-fhx': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-shx-illicit': {
        answerKeys: ['no'],
        answerValues: [],
      },
      'q-shx-alcohol': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-shx-smoking': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-ophx-date': {
        answerKeys: [],
        answerValues: ['05-21-2023 16:17:54'],
      },
      'q-ophx': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pmhx-etc': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pmhx-etc-text': {
        answerKeys: [],
        answerValues: ['pmhx etc text sample1'],
      },
      'q-pmhx-first-existing': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-pmhx-existing': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-q-pmhx-cancer': {
        answerKeys: [],
        answerValues: [],
      },
      'q-pmhx-cancer-tx': {
        answerKeys: ['o-chemotherapy'],
        answerValues: [],
      },
      'q-dhx-change': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pmhx-existing-text': {
        answerKeys: [],
        answerValues: ['I got cancer treatment last year'],
      },
      'q-pmhx-cancer-type': {
        answerKeys: [],
        answerValues: ['Kidney'],
      },
      'q-ophx-type': {
        answerKeys: [],
        answerValues: ['Lung'],
      },
      'q-pmhx-cancer': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pmhx-cancer-date': {
        answerKeys: [],
        answerValues: ['05-17-2012 13:40:07'],
      },
      'q-dhtx-change-text': {
        answerKeys: [],
        answerValues: ['doubled the Diltiazem dosage a week ago'],
      },
      'q-pmhx-blood': {
        answerKeys: ['o-yes'],
        answerValues: [],
        notesForKeys: {
          'o-yes': 'bleeding badly',
        },
      },
      'q-pmhx-type': {
        answerKeys: ['o-dm'],
        answerValues: [],
      },
      'q-pmhx-type-text': {
        answerKeys: [],
        answerValues: ['Stroke'],
      },
      'q-dhx': {
        answerKeys: [],
        answerValues: ['Diltiazem'],
      },
      'q-dhx-contraceptives': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-pmhx-allergy': {
        answerKeys: ['o-no'],
        answerValues: [],
      },
      'q-pmhx-allergy-cause': {
        answerKeys: [],
        answerValues: ['test'],
      },
      'q-pmhx-allergy-reaction': {
        answerKeys: ['sx-urticaria'],
        answerValues: [],
      },
      'q-substance': {
        answerKeys: ['o-yes'],
        answerValues: [],
      },
      'q-substance-type-text': {
        answerKeys: [],
        answerValues: ['test'],
      },
      'q-substance-text': {
        answerKeys: [],
        answerValues: ['abuse text example'],
      },
    },
    tree: getTreeByKey('pmhx'),
    treeKey: 'pmhx',
  },
  {
    answerMap: {
      'q-onset-datetime': {
        answerKeys: [],
        answerValues: ['04-29-2023 12:57:14'],
      },
      'q-condition-etiology': {
        answerKeys: ['sx-sleep-deprived', 'sx-during-menstruation'],
        answerValues: [],
      },
    },
    treeKey: 'sx-general-weakness',
    tree: getTreeByKey('sx-general-weakness'),
  },
];

export function useDefaultStates(
  treeKey?: string,
  defaultTreeStates: ReportStateType['treeStates'] = DefaultTreeStates
) {
  const [reportState, setReportState] = useRecoilState(PaReportState);

  const treeIndex = reportState.treeStates.findIndex((treeS) => treeS.treeKey === treeKey);
  const treeState = reportState.treeStates[treeIndex];

  function initDemo(set?: boolean) {
    if (set) {
      setReportState(
        produce(reportState, (draft) => {
          // fix tree objs
          const fixedState = defaultTreeStates.map((ts) => {
            return {
              ...ts,
              tree: getTreeByKey(ts.treeKey),
            };
          });

          draft.treeStates = fixedState;
          draft.isDemo = true;
        })
      );
    } else {
      setReportState(
        produce(reportState, (draft) => {
          // draft.treeStates = [];
          draft.isDemo = false;
        })
      );
    }
  }

  const updateAnswer: UpdateAnswerHandler = (params) => {
    const { questionKey } = params;

    const nState = produce(reportState, (draft) => {
      const { answerMap } = draft.treeStates[treeIndex];

      if (answerMap[questionKey] == null) {
        answerMap[questionKey] = {
          answerKeys: [],
          answerValues: [],
        };
      }

      answerMap[questionKey] = {
        ...answerMap[questionKey],
        ...params,
      };
    });

    // answer states
    setReportState(nState);
  };

  return [
    { treeState, initDemo, reportState },
    { setReportState, updateAnswer },
  ] as const;
}

// NOTE for testing
export const defaultXsTreeState = {
  tree: {
    treeKey: 'sx-abd-pain',
    entryQuestionKeys: [
      'q-onset-date',
      'q-onset-time',
      'q-location-abd',
      'q-pain-character',
      'q-pain-character-etc',
      'q-pain-nrs',
      'q-sx-continuous-intermittent',
      'q-sx-continuous-intermittent-etc',
      'q-sx-aggravation',
      'q-sx-aggravation-etc',
      'q-sx-aggravating-y-n',
      'q-sx-aggravating-factor',
      'q-sx-relieving-y-n',
      'q-sx-relieving-factor',
    ],
  },
  answerMap: {
    'q-onset-date': { answerKeys: [], answerValues: ['2023-01-28 17:56:49'] },
    'q-onset-time': { answerKeys: [], answerValues: ['2023-01-28 17:56:51'] },
    'q-location-abd': { answerKeys: ['left-upper'], answerValues: [] },
    'q-pain-character': { answerKeys: ['Dull pain'], answerValues: [] },
    'q-sx-continuous-intermittent': { answerKeys: ["It's continuous"], answerValues: [] },
    'q-sx-aggravation': { answerKeys: ['better'], answerValues: [] },
    'q-sx-aggravating-y-n': { answerKeys: ['no'], answerValues: [] },
    'q-sx-relieving-y-n': { answerKeys: ['no'], answerValues: [] },
    'q-pain-nrs': { answerKeys: [], answerValues: ['3'] },
  },
  treeKey: 'sx-abd-pain',
};
