import classNames from 'classnames';
import { uniq } from 'lodash';
import { InputHTMLAttributes, memo } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { UpdateAnswerHandler } from '../prop-types';
import { AnswerValue, QuestionType } from '../types';
import { useLocalization } from '../use-localization';

import abdImg from './assets/abdomen.png';

const abdLocs = [
  // 'o-abd-whole',
  'o-abd-epigastric',
  'o-abd-luq',
  'o-abd-ruq',
  'o-abd-lt',
  'o-abd-periumbilical',
  'o-abd-rt',
  'o-abd-llq',
  'o-abd-low',
  'o-abd-rlq',
  // 'o-other',
];

const wholeOptionKey = 'o-abd-whole';
const otherOptionKey = 'o-other';

function QsAbdLocation(
  props: InputHTMLAttributes<HTMLInputElement> & {
    question: QuestionType;
    answerValue?: AnswerValue;
    updateAnswer?: UpdateAnswerHandler;
  }
) {
  const [, { t }] = useLocalization();

  const { updateAnswer, answerValue: answerMap, question } = props;
  const { questionKey } = question;
  const currKeys = answerMap?.answerKeys || [];
  const wholeSelected = currKeys.includes(wholeOptionKey);
  const otherSelected = currKeys.includes(otherOptionKey);

  return (
    // TODO better responsive layout
    <div className='relative max-w-md'>
      <div className=''>
        <img src={abdImg} useMap='#abdmap' />
        <div className='absolute top-0 overflow-visible w-full'>
          <div className='relative py-[3.1rem] px-[4.2rem] w-full flex flex-wrap left-1'>
            {abdLocs.map((abdLoc, i) => {
              const selected = currKeys.includes(abdLoc);

              return (
                <div key={`${i}`} className={classNames('min-w-[33.3%] aspect-square p-[2px]')}>
                  <div
                    className={classNames(
                      'flex-center w-full h-full rounded-2xl cursor-pointer hover:bg-purple-200 hover:bg-opacity-40'
                      //
                      // 'bg-blue-300'
                    )}
                    onClick={() => {
                      if (!updateAnswer || wholeSelected) return;

                      if (selected) {
                        updateAnswer({
                          questionKey,
                          answerKeys: currKeys.filter((ck) => ck !== abdLoc),
                        });
                      } else {
                        updateAnswer({
                          questionKey,
                          answerKeys: uniq([...currKeys, abdLoc]),
                        });
                      }
                    }}
                  >
                    <BiCheckCircle
                      size={33}
                      className={classNames(selected ? 'opacity-90 text-purple-600' : 'opacity-10')}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='form-control'>
        <label className='label cursor-pointer gap-2 py-3'>
          <span className='label-text text-lg'>{t(wholeOptionKey)}</span>
          <input
            type='checkbox'
            className={classNames('checkbox checkbox-primary checkbox-sm')}
            checked={wholeSelected}
            onChange={(e) => {
              const nextKeys = [];
              if (e.target.checked) {
                nextKeys.push(wholeOptionKey);
              }

              if (otherSelected) {
                nextKeys.push(otherOptionKey);
              }

              if (!updateAnswer) return;

              updateAnswer({
                questionKey,
                answerKeys: nextKeys,
              });
            }}
          />
        </label>
      </div>
      <div className='form-control'>
        <label className='label cursor-pointer gap-2 py-3'>
          <span className='label-text text-lg'>{t(otherOptionKey)}</span>
          <input
            type='checkbox'
            className={classNames('checkbox checkbox-primary checkbox-sm')}
            checked={otherSelected}
            onChange={(e) => {
              if (!updateAnswer) return;

              if (e.target.checked) {
                updateAnswer({
                  questionKey,
                  answerKeys: [...currKeys, otherOptionKey],
                });
              } else {
                updateAnswer({
                  questionKey,
                  answerKeys: currKeys.filter((ck) => ck !== otherOptionKey),
                });
              }
            }}
          />
        </label>
      </div>
    </div>
  );
}

export default memo(QsAbdLocation);

/*
  * https://summerstyle.github.io/summer/#
<img src="abdomen.png" alt="" usemap="#map" />
<map name="map">
    <area shape="rect" coords="122, 96, 209, 183" />
    <area shape="rect" coords="217, 95, 318, 182" />
    <area shape="rect" coords="329, 94, 432, 180" />
    <area shape="rect" coords="114, 186, 210, 275" />
    <area shape="rect" coords="218, 188, 318, 275" />
    <area shape="rect" coords="327, 188, 430, 275" />
    <area shape="rect" coords="112, 283, 209, 372" />
    <area shape="rect" coords="217, 280, 318, 373" />
    <area shape="rect" coords="327, 283, 428, 375" />
</map>
*/
