import { toast } from 'react-toastify';
import { useStaticLocales } from './locales/use-static-locale';

export function useToastMsgs() {
  const { sltStr } = useStaticLocales();

  function showCopiedMsg() {
    toast.info('Copied to clipboard', { toastId: 'copied', updateId: 'copied' });
  }

  function showNothingToCopyMsg() {
    toast.info('Nothing to copy', { toastId: 'copied', updateId: 'copied' });
  }

  function showAnswerAll() {
    toast.info(sltStr({ key: 'msg-answer-all' }), {
      toastId: 'answer-all',
    });
  }

  function showRequireNote() {
    const key = 'msg-note-required';
    toast.info(sltStr({ key }), {
      toastId: key,
    });
  }

  return {
    showRequireNote,
    showAnswerAll,
    showCopiedMsg,
    showNothingToCopyMsg,
  } as const;
}
