import es from 'date-fns/locale/es';
import { memo, ReactNode } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-mobile-datepicker';
// https://github.com/lanjingling0510/react-mobile-datepicker#proptypes

registerLocale('es', es);

type Props = {
  value: Date | null | undefined;
  min?: Date;
  max?: Date;
  dateConfig?: JSON;
  isOpen?: boolean;
  showHeader?: boolean;
  onSelect?: (date: Date) => void;
  onCancel?: () => void;
  onChange?: () => void;

  theme?: string;
  customHeader?: ReactNode;
  showFooter?: boolean;
  showCaption?: boolean;
  headerFormat?: string;
  confirmText?: string;
  cancelText?: string;
};

function DatePickerMobile({ dateConfig, ...props }: Props) {
  return (
    <DatePicker
      dateConfig={dateConfig}
      theme='android'
      confirmText='OK'
      cancelText='Cancel'
      {...props}
    />
  );
}

export default memo(DatePickerMobile);
