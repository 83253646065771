export function getEnvs() {
  const envs = {
    // in minutes
    autoLogout: process.env.REACT_APP_AUTO_LOGOUT,
    // autoLogout: 0.2,

    qrBase: process.env.REACT_APP_QR_BASE,
    surveyBase: process.env.REACT_APP_SURVEY_BASE,
    clientUrl: process.env.REACT_APP_CLIENT_URL,
    BackendUrl: `${process.env.REACT_APP_BACKEND_BASE}/graphql`,
    backendBaseUrl: process.env.REACT_APP_BACKEND_BASE,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    isProd: process.env.NODE_ENV === 'production',
    isDev: process.env.NODE_ENV === 'development',
    nodeEnv: process.env.NODE_ENV,
    appEnv: process.env.REACT_APP_NODE_ENV,
    isStagingApp: process.env.REACT_APP_NODE_ENV === 'staging',
    waitlistLink: process.env.REACT_APP_WAITLIST_LINK,

    tenantId: process.env.REACT_APP_TENANT_ID,

    auth: {
      google: process.env.REACT_APP_AUTH_GOOGLE,
      ms: process.env.REACT_APP_AUTH_MS,
    },
    msSSO: {
      clientId: process.env.REACT_APP_AUTH_MS_CLIENT_ID,
      tenantId: process.env.REACT_APP_AUTH_MS_TENANT_ID,
    },
  };

  // console.log('envs', envs);

  return envs;
}

const envs = getEnvs();
const appName = 'CyrenCare';

export function getTitle() {
  if (envs.isProd) {
    return appName;
  }

  return `${appName} (${envs.nodeEnv})`;
}

export default envs;
