class ContentError extends Error {

  static readonly NO_NEXT_TREE = "no next tree";

  constructor(message: string) {
    super(message);
    this.name = "ContentError";
  }
}

export default ContentError; 