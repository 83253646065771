/* eslint-disable jsx-a11y/no-autofocus */
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useElectron } from '../../admin/hooks/use-electron';

function StaffHelmet() {
  const { elecEnvs } = useElectron();

  if (elecEnvs?.appTitle) {
    return (
      <Helmet>
        <title>{elecEnvs?.appTitle}</title>
      </Helmet>
    );
  }

  return null;
}

export default memo(StaffHelmet);
