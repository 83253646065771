import { SVGAttributes } from 'react';
import { Keys } from '../../../../patient-types';
import BodySelectButton from './BodySelectButton';

const FootRightButtons = ({
  selectedKeys,
  ...props
}: SVGAttributes<SVGElement> & {
  selectedKeys: Keys;
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={204} height={268} fill='none' {...props}>
      <path
        fill='#F2F4FC'
        d='M175.634 62.508c2.051-26.192-.855-49.252-2.564-57.508l-74.64.854c-9.401 48.113-16.238 44.697-23.36 67.187-5.698 17.993-7.503 36.156-7.692 42.989-.285 3.226-.684 10.533 0 13.95.854 4.27.284 5.978-.285 18.22-.57 12.242 4.558 15.658 7.976 18.505 2.735 2.278 5.69.949 6.825 0a39.53 39.53 0 0 0-.272 5.694c.285 10.533 7.692 14.519 12.535 14.519 3.874 0 6.014-2.088 6.599-3.131a157.335 157.335 0 0 0-.047 3.131c0 4.271 3.134 10.249 9.971 10.249 5.47 0 8.737-2.847 9.686-4.27 0 7.402 2.279 10.533 12.25 11.957 7.977 1.138 12.341-4.65 13.526-7.687 2.881 5.43 8.408 7.687 15.818 7.687 9.116 0 14.814-2.278 18.802-11.957 3.988-9.68 3.988-17.651 2.279-22.776-1.367-4.099-.57-8.161 0-9.679.855-2.847 2.621-11.786 2.849-24.768.228-12.982-3.324-22.301-5.128-25.338-2.564-5.03-7.179-21.637-5.128-47.828Z'
      />
      <path
        stroke='#C3C7D9'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M173.07 5c1.709 8.256 4.615 31.316 2.564 57.508-2.051 26.191 2.564 42.798 5.128 47.828 1.804 3.037 5.356 12.356 5.128 25.338-.228 12.982-1.994 21.921-2.849 24.768-.57 1.518-1.367 5.58 0 9.679 1.709 5.125 1.709 13.096-2.279 22.776-3.988 9.679-9.686 11.957-18.802 11.957-7.41 0-12.937-2.257-15.818-7.687m3.567-32.74c-2.374 6.643-6.723 21.637-5.127 28.47.373 1.599.896 3.019 1.56 4.27m0 0c-1.185 3.037-5.549 8.825-13.526 7.687-9.971-1.424-12.25-4.555-12.25-11.957m0 0c0-5.922.57-15.184.855-19.075.448-3.585 3.083-5.942 3.419-9.964m-4.274 29.039c-.949 1.423-4.216 4.27-9.686 4.27-6.837 0-9.971-5.978-9.971-10.249 0-.734.018-1.823.047-3.131m3.657-26.762c-.313 3.746-2.791 6.479-3.134 10.249-.149 4.024-.415 11.732-.523 16.513m0 0c-.585 1.043-2.725 3.131-6.6 3.131-4.842 0-12.25-3.986-12.534-14.519a39.53 39.53 0 0 1 .272-5.694m6.565-20.213c0 1.822-1.9 5.314-2.849 6.832-1.095 2.189-2.998 7.304-3.716 13.381m0 0c-1.135.949-4.09 2.278-6.825 0-3.418-2.847-8.546-6.263-7.976-18.505.57-12.242 1.14-13.95.284-18.22-.683-3.417-.284-10.724 0-13.95.19-6.833 1.995-24.996 7.692-42.989 7.123-22.49 13.96-19.074 23.361-67.187'
      />
      <path
        stroke='#C3C7D9'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M172.263 196.753c-8.739-5.747-17.459-3.352-20.727-1.437.374-6.992 3.641-20.745 13.725-19.825 10.083.919 8.87 14.557 7.002 21.262ZM126.977 198.127c4.244-1.099 9.213-.458 11.167 0 .744-3.755.726-11.211-5.304-10.992-6.03.22-6.421 7.42-5.863 10.992ZM115.239 192.629c-3.566-2.024-7.987-2.155-9.751-1.967 0-3.56 1.393-10.624 6.965-10.399 5.571.225 4.179 8.338 2.786 12.366ZM95.795 181.632c-2.516-1.917-6.957-1.797-8.864-1.498.286-3.395 1.945-10.186 6.29-10.186 4.347 0 3.527 7.79 2.574 11.684ZM81.095 163.763c-3.046-1.456-6.542-2.022-7.91-2.123.391-2.729 2.168-8.186 6.153-8.186 3.984 0 2.832 6.872 1.757 10.309Z'
      />
      <path
        stroke='#3455FC'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='m163 187 25 45M133 193v59M111 186l-31 58M92 175l-55 36M77 158H18'
      />
      <BodySelectButton
        id='o-foot-rt-back'
        selectedKeys={selectedKeys}
        cx={133}
        cy={82}
        r={12}
        d='M129.002 82h8M133 78v8'
      />

      <BodySelectButton
        id='o-toes-rt-bigtoe'
        selectedKeys={selectedKeys}
        cx={188}
        cy={232}
        r={12}
        d='M184.002 232h8M188 228v8'
      />

      <BodySelectButton
        id='o-toes-rt-second'
        selectedKeys={selectedKeys}
        cx={133}
        cy={256}
        r={12}
        d='M129.002 256h8M133 252v8'
      />

      <BodySelectButton
        id='o-toes-rt-third'
        selectedKeys={selectedKeys}
        cx={80}
        cy={244}
        r={12}
        d='M76.002 244h8M80 240v8'
      />

      <BodySelectButton
        id='o-toes-rt-fourth'
        selectedKeys={selectedKeys}
        cx={37}
        cy={211}
        r={12}
        d='M33.002 211h8M37 207v8'
      />

      <BodySelectButton
        id='o-toes-rt-fifth'
        selectedKeys={selectedKeys}
        cx={18}
        cy={159}
        r={12}
        d='M14.002 159h8M18 155v8'
      />
    </svg>
  );
};
export default FootRightButtons;
