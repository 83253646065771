export function getChecksum(obj) {
    if (obj == null)
        return null;
    const jsonString = JSON.stringify(obj);
    let checksum = 0;
    for (let i = 0; i < jsonString.length; i++) {
        const charCode = jsonString.charCodeAt(i);
        checksum = (checksum << 5) - checksum + charCode;
        checksum |= 0;
    }
    return checksum.toString();
}
