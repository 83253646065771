import { memo, useState, useEffect } from 'react';

import {
  FormControl,
  TextField,
  Box,
  Typography,
  Autocomplete
} from '@mui/material';
import { useGetPatientNotificationOptionsQuery, useSendPatientNotificationMutation } from '@cyren/common-lib';
import { size } from 'lodash';
import { TPatientVisit } from '../../../graphql-enhanced';
import { onError } from '../../../utils/apollo-utils';
import { toast } from 'react-toastify';
import { genMessageLongId } from '../../nav-utils';
import { SubmitButtonState } from './ModalSendCommunication';
import useAuth from '../../../auth/use-auth';
import LoadingAnim from '../../../components/LoadingAnim';


function PanelNotifyPatient(props: {
  onClose: () => void;
  visit: TPatientVisit;
  setSubmitButtonState: ((newState: SubmitButtonState) => void)
}) {
  const [{ selectedOrg }] = useAuth();
  const orgId = selectedOrg?.id;
  const { onClose, visit, setSubmitButtonState } = props;

  const updateSubmitButtonState = () => {
    setSubmitButtonState(
      {
        loading: sending,
        enabled: size(selectedNotification) > 0,
        message: "Send",
        callback: handleSave
      });
  }

  useEffect(() => {
    updateSubmitButtonState();
  }, []);

  const [sendPatientNotification, { loading: sending }] = useSendPatientNotificationMutation({
    onError,
  });

  const { data: patientNotifiations, loading: loadingNotificationOptions } = useGetPatientNotificationOptionsQuery({
    onError,
    fetchPolicy: 'network-only',
    skip: !orgId,
    variables: {
      orgId: orgId!,
    },
  });

  type PatientNotification = {
    value: string,
    label: string,
  }

  const notifcationOptions: PatientNotification[] = patientNotifiations?.getPatientNotificationOptions?.map(i => {
    return {
      value: i?.key || "",
      label: i?.text || ""
    }
  }) || [];

  const handleSend = (messageKey: string, messageText: string) => {
    sendPatientNotification({
      variables: {
        id: visit?.id as string,
        data: {
          messageContent: JSON.stringify({
            messageKey,
            messageSummary: messageText,
          }),
          recipientAddress: visit.surveyState.reportState?.systemValueMap?.phone as string,
          uniqueID: genMessageLongId(),
        }
      },
    }).then(() => {
      toast.success('Sent');
      onClose();
    });

  }

  const [selectedNotification, setSelectedNotification] = useState<PatientNotification | null>(null);
  const handleSelectNotification = (event: React.SyntheticEvent<Element, Event>, value: PatientNotification | null) => {
    // Handle the change event here
    setSelectedNotification(value);
  };

  useEffect(() => {
    updateSubmitButtonState();
  }, [selectedNotification]);


  const handleSave = () => {

    if (!selectedNotification) {
      return;
    }
    handleSend(selectedNotification.value, selectedNotification.label);
  }

  return (
    <Box>
      {loadingNotificationOptions ? (
        <div className='flex-1 flex-center'>
          <LoadingAnim />
        </div>
      ) : (
        <>
          <Typography>Select a notification message you would like to send to the patient.</Typography>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <Autocomplete
              id="form-select-notification"
              options={notifcationOptions}
              value={selectedNotification} onChange={handleSelectNotification}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Notification Message" />}
            />
          </FormControl>
          <Box>
            <Typography variant="caption" className="opacity-80">If the patient completed the intake in another language, it will be automatically translated to that language.</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default memo(PanelNotifyPatient);
