import { Enum_Question_Type } from '@cyren/common-lib';
import { TQuestion, TQuestionInput } from '../../graphql-enhanced';
import { QuestionType } from '../patient-types';

export function useQuestion(question?: TQuestion | TQuestionInput | QuestionType | undefined) {
  const isSelectType = question?.type === Enum_Question_Type.Select;
  const isSelectTypeV2 = question?.type === Enum_Question_Type.SelectV2;
  const isSelectSheet = question?.type === Enum_Question_Type.SelectSheet;
  const isSxSelectType = question?.type === Enum_Question_Type.SxSelect;
  const isSxCollectType = question?.type === Enum_Question_Type.SxCollect;
  const isSxIntelligentCollectType = question?.type === Enum_Question_Type.SxCollectIntelligent;
  const isMarkdown = question?.type === Enum_Question_Type.Markdown;
  const isStageMileMarker = question?.type === Enum_Question_Type.StageMilemarker;

  const isBodySelect = question?.type === Enum_Question_Type.BodySelect;
  const isBodySelectWhole = question?.type === Enum_Question_Type.BodySelectWhole;
  const isBodySelectHead = question?.type === Enum_Question_Type.BodySelectHead;
  const isBodySelectBelly = question?.type === Enum_Question_Type.BodySelectBelly;
  const isBodySelectBack = question?.type === Enum_Question_Type.BodySelectBack;
  const isBodySelectChest = question?.type === Enum_Question_Type.BodySelectChest;
  const isBodySelectFace = question?.type === Enum_Question_Type.BodySelectFace;
  const isBodySelectJoints = question?.type === Enum_Question_Type.BodySelectJoints;
  const isBodySelectSensory = question?.type === Enum_Question_Type.BodySelectSensory;
  const isBodySelectMouth = question?.type === Enum_Question_Type.BodySelectMouth;
  const isBodySelectDental = question?.type === Enum_Question_Type.BodySelectDental;
  const isBodySelectLimbs = question?.type === Enum_Question_Type.BodySelectLimbs;
  const isBodySelectSubpart = question?.type === Enum_Question_Type.BodySelectSubpart;

  const isBodyWholeSubpartSelectTypes =
    isBodySelectMouth ||
    isBodySelectDental ||
    isBodySelectLimbs ||
    isBodySelectSubpart ||
    isBodySelectHead ||
    isBodySelectBelly ||
    isBodySelectBack ||
    isBodySelectChest ||
    isBodySelectFace ||
    isBodySelectJoints ||
    isBodySelectSensory;

  const isBodyPartSelectTypes = isBodySelectWhole || isBodyWholeSubpartSelectTypes;

  const isBodySelectTypes = isBodySelect || isBodyPartSelectTypes;

  // const isText =
  //   question?.type &&
  //   [Enum_Question_Type.Text, Enum_Question_Type.Textarea].includes(question?.type);
  const isCollapsable = question?.type === Enum_Question_Type.Collapsible;
  const isNumberType = question?.type === Enum_Question_Type.Number;
  const isCollapsableItem = question?.type === Enum_Question_Type.CollapsibleItem;
  const isRange = question?.type === Enum_Question_Type.Range;
  const isText = question?.type === Enum_Question_Type.Text;
  const isTextarea = question?.type === Enum_Question_Type.Textarea;
  const isNumberUnitSelect = question?.type === Enum_Question_Type.NumberUnitSelect;
  // const isCountrySelectType =
  //   question?.type &&
  //   [Enum_Question_Type.SelectCountry, Enum_Question_Type.SelectCountries].includes(question?.type);

  const isSelectTypes = isSelectTypeV2 || isSelectSheet || isSelectType || isBodySelectTypes;

  const isQsRequireOptionEdit =
    isSelectTypes ||
    isSxCollectType ||
    isSxSelectType ||
    isCollapsable ||
    isCollapsableItem ||
    isNumberUnitSelect ||
    isSxIntelligentCollectType;

  // const isRadio = question?.typeOptions?.min === '1' && question?.typeOptions?.max === '1';
  const isRadio = !question?.typeOptions?.multi;

  return {
    isNumberType,
    isRadio,
    isRange,
    isCollapsible: isCollapsable,
    isCollapsableItem,
    isSelectTypes,
    isQsRequireOptionEdit,
    isMarkdown,
    isStageMileMarker,
    isSelectType,
    isSelectTypeV2,
    isSelectSheet,
    isSxSelectType,
    isSxCollectType,
    isNumberUnitSelect,

    isBodySelect,
    isBodySelectWhole,
    isBodyPartSelectTypes,
    isBodyWholeSubpartSelectTypes,
    isBodySelectHead,
    isBodySelectBelly,
    isBodySelectBack,
    isBodySelectChest,
    isBodySelectFace,
    isBodySelectJoints,
    isBodySelectSensory,
    isBodySelectMouth,
    isBodySelectDental,
    isBodySelectLimbs,

    isBodySelectTypes,

    isText,
    isTextarea,
  } as const;
}
