import { isNotEmpty } from '@cyren/common-lib';
import { capitalize, isEmpty, startCase, toUpper } from 'lodash';
import useAppState from '../../../app/use-app-state';
import ar from './static-locales/ar.json';
import bn from './static-locales/bn.json';
import de from './static-locales/de.json';
import el from './static-locales/el.json';
import en from './static-locales/en.json';
import es from './static-locales/es.json';
import fa from './static-locales/fa.json';
import fr from './static-locales/fr.json';
import gu from './static-locales/gu.json';
import he from './static-locales/he.json';
import hi from './static-locales/hi.json';
import hmn from './static-locales/hmn.json';
import hr from './static-locales/hr.json';
import ht from './static-locales/ht.json';
import hy from './static-locales/hy.json';
import it from './static-locales/it.json';
import ja from './static-locales/ja.json';
import km from './static-locales/km.json';
import ko from './static-locales/ko.json';
import pa from './static-locales/pa.json';
import pl from './static-locales/pl.json';
import pt from './static-locales/pt.json';
import ru from './static-locales/ru.json';
import ta from './static-locales/ta.json';
import te from './static-locales/te.json';
import th from './static-locales/th.json';
import tl from './static-locales/tl.json';
import ur from './static-locales/ur.json';
import vi from './static-locales/vi.json';
import yi from './static-locales/yi.json';
import zh from './static-locales/zh.json';

const localeByCodeMap = {
  zh,
  ar,
  bn,
  de,
  el,
  en,
  es,
  fa,
  fr,
  gu,
  he,
  hi,
  hmn,
  hr,
  ht,
  hy,
  it,
  ja,
  km,
  ko,
  pa,
  pl,
  pt,
  ru,
  ta,
  te,
  th,
  tl,
  ur,
  vi,
  yi,
};
export type SystemLocaleKeys = keyof typeof en;
export type SystemLocaleCodes = keyof typeof localeByCodeMap;

function splitStringWithOccurrence(input: string): string[] {
  const regex = /({{v\d+}})/g;
  return input.split(regex);
}

type TransItems = (string | JSX.Element)[];

export function useStaticLocales({
  localeCode: forcedLocaleCode,
}: { localeCode?: SystemLocaleCodes } = {}) {
  const [{ localeCode: localeCodeSystem }] = useAppState();

  const localeCode = forcedLocaleCode || localeCodeSystem;

  // Returns array of string
  function slt({ key, vars }: { key: SystemLocaleKeys; vars?: TransItems }): TransItems {
    const localeMap = localeCode
      ? // @ts-ignore
        localeByCodeMap[localeCode] || en
      : // fallback to en
        en;

    try {
      // @ts-ignore
      const value = localeMap?.[key];

      if (isEmpty(value)) {
        return [en[key]];
      }

      if (vars && isNotEmpty(vars)) {
        let arr: TransItems = splitStringWithOccurrence(value);

        vars?.forEach((varItem, idx) => {
          const replStr = `{{v${idx}}}`;
          arr = arr.map((item) => {
            return item === replStr ? varItem : item;
          });
        });

        return arr;
      }

      return [value];
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }

    return [en[key]];
  }

  // Returns a string
  function sltStr(props: {
    key: SystemLocaleKeys;
    vars?: TransItems;
    upper?: boolean;
    capit?: boolean;
    title?: boolean;
  }): string {
    const arr = slt(props);
    const output = arr.join('');

    if (props.capit) {
      return capitalize(output);
    }
    if (props.upper) {
      // changed from lodash's upperCase to toUpper due to issue with it removing punctuation and other special characters
      return toUpper(output);
    }
    if (props.title) {
      return startCase(output);
    }

    return output;
  }

  return { sltStr, slt } as const;
}
