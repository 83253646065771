import { Key } from '../patient/patient-types';

export const noneAboveKey = 'o-none-above';
export const noneBelowKey = 'o-none-below';
export const otherKey = 'o-other';
export const sexualAssaultKey = 'sx-sexualassault';
export const maleKey = 'o-male';
export const femaleKey = 'o-female';
export const onsetQuestionKey = 'q-onset-datetime';

export const systemKeys = [noneAboveKey, noneBelowKey];
export function isSystemKey(key?: Key | null) {
  return systemKeys?.includes(key || '');
}
