import { isNaN, toNumber } from 'lodash';
// got it from https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function abbreviateNumber(number) {
    const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const sign = number < 0 ? '-1' : '';
    const absNumber = Math.abs(number);
    // eslint-disable-next-line
    const tier = (Math.log10(absNumber) / 3) | 0;
    // if zero, we don't need a prefix
    // eslint-disable-next-line
    if (tier == 0)
        return `${absNumber}`;
    // get postfix and determine scale
    const postfix = SI_POSTFIXES[tier];
    // eslint-disable-next-line
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = absNumber / scale;
    const floored = Math.floor(scaled * 10) / 10;
    // format number and add postfix as suffix
    let str = floored.toFixed(1);
    // remove '.0' case
    str = /\.0$/.test(str) ? str.substr(0, str.length - 2) : str;
    return `${sign}${str}${postfix}`;
}
export function formatDollar(num) {
    var p = num.toFixed(2).split('.');
    return ('$' +
        p[0]
            .split('')
            .reverse()
            .reduce(function (acc, num, i, orig) {
            return num + (num != '-' && i && !(i % 3) ? ',' : '') + acc;
        }, '') +
        '.' +
        p[1]);
}
export function dollarStrToFloat(str) {
    if (!str) {
        return 0;
    }
    return Number(str.replace(/[^0-9.-]+/g, ''));
}
// FIX for JS number precision
// https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
export function precisionRoundMod(number, precision = 2) {
    // eslint-disable-next-line
    const factor = Math.pow(10, precision);
    const n = precision < 0 ? number : 0.01 / factor + number;
    return Math.round(n * factor) / factor;
}
const ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
];
/**
 * format big numbers into human readables
 * @param n input number
 * @returns formatted value as 3.4K, 12.9M
 */
export function formatNumber(n, precision = 1) {
    for (let i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
            return (
            // eslint-disable-next-line
            (n / ranges[i].divider).toFixed(precision).toString() + `${ranges[i].suffix}`);
        }
    }
    return n.toString();
}
export function formatPercent(number) {
    const num = (number || 0) * 100;
    return `${num}%`;
}
export function toValidNumber(num) {
    const n = toNumber(num);
    if (isNaN(n)) {
        return undefined;
    }
    return n;
}
// make number to 2 digit strings i.e. 0 > '00', 4 => '04', 11 => '11'
export function to2DigitString(num) {
    if (num == null)
        return '00';
    return num < 10 ? `0${num}` : `${num}`;
}
