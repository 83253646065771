import classNames from 'classnames';
import logoBlue from '../assets/logos/CC logo blue.svg';
import logoPurple from '../assets/logos/CC logo purple.svg';
import logo from '../assets/logos/CC logo.svg';
import logoVert from '../assets/logos/logo-set-vert.png';

export default function Logo({
  copy,
  className,
  forPatient,
  forDoctor,
}: {
  copy?: 'full' | 'inline';
  className?: string;
  forPatient?: boolean;
  forDoctor?: boolean;
}) {
  const defaultWidth = className?.startsWith('w-') ? '' : 'w-full';
  if (copy === 'full') {
    // if parent is specifying a different width class, stick with the parents so that
    // two conflicting classes don't try to fight it out
    return <img src={logoVert} alt='cyrencare logo' className={classNames(defaultWidth, className)} />;
  }

  let imgSrc = logo;
  if (forDoctor) imgSrc = logoPurple;
  if (forPatient) imgSrc = logoBlue;

  return (
    <img
      src={imgSrc}
      alt='cyrencare logo'
      className={classNames('rounded-full', defaultWidth, className)}
    />
  );
}
