import { Enum_Transapi_Locale, Enum_Transapi_Target_Locale, notNull, useUserNotesTransApisMutation } from '@cyren/common-lib';
import { convToRender, fixLocaleMapping } from '../gql-tools/utils/convert';
import { TTransApi } from '../graphql-enhanced';

/*
  This is a simplifed verison of the existing hook, use-user-note-trans, but it is more generic and just
  takes an arbitrary list of strings to be translated, and then it will return them as a map upon completion
  to an associated callback function
*/
export function useUserTranslateStrings(fComplete: (results: TTransApi[]) => void, locale?: Enum_Transapi_Locale) {

  const [userNotesTransApis, { loading }] = useUserNotesTransApisMutation({
    onCompleted: (data) => {
      fComplete(data.userNotesTransApis?.data
        ?.map(convToRender)
        ?.filter(notNull)
        ?.map((t: TTransApi) => {
          return {
            ...t!, target_locale: t.target_locale || Enum_Transapi_Target_Locale.English
          }
        }) || []);
    },
  });


  const translateFunction = (inputStrings: string[], inputLocales: string[]) => {
    userNotesTransApis({
      variables: {
        input: {
          locale: locale || Enum_Transapi_Locale.English,
          notes: inputStrings,
          specific_target_locales: inputLocales.map(i => fixLocaleMapping(i) as string) as Enum_Transapi_Target_Locale[]
        },
      },
    });
  }

  return [
    translateFunction,
    { loading }
  ] as const;
}