import { focusWithId, focusWithIdTimeOut } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { difference, first, isEmpty, keys, last, trim, uniq } from 'lodash';
import { ChangeEvent, memo, useRef, useState } from 'react';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import useAppState from '../../../app/use-app-state';
import { H3 } from '../../../common/typo/H';
import Modal from '../../../components/Modal';
import { getMutualExclusiveAnswerKeys } from '../../../utils/models/question';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';
import { Keys } from '../../patient-types';
import QsTextInput from './QsTextInput';

function QsInputSelectSheet(props: QuestionProps) {
  const { locale, question, updateAnswer, treeState, tOptions } = props;
  const [, { t }] = useTranslations({ locale });
  const [{ isRtl }] = useAppState();
  const { sltStr } = useStaticLocales();

  const ref = useRef<HTMLDivElement>(null);

  const [openSelect, setOpenSelect] = useState(false);
  const [markError, setMarkError] = useState(false);

  if (!question) {
    return null;
  }

  const { questionKey, answerKeys } = question;

  const answerOptionsMap = question?.answerOptionsMap || {};
  const { answerMap } = treeState || {};

  const single = !question?.typeOptions?.multi;

  const qAnswerMap = answerMap?.[questionKey];
  const { notesForKeys } = qAnswerMap || {};
  const selectedKeys = qAnswerMap?.answerKeys || [];
  const firstSelectedKey = first(selectedKeys);
  const firstSelectedKeyRerquireNote = answerOptionsMap?.[firstSelectedKey || '']?.requireNote;
  const firstKeyNote = firstSelectedKey ? notesForKeys?.[firstSelectedKey] : '';

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });

  const answerKeysRequiringNote =
    selectedKeys.filter((k) => {
      return answerOptionsMap?.[k]?.requireNote;
    }) || [];

  const answerKeysWithInvalidNote =
    answerKeysRequiringNote.filter((k) => {
      return isEmpty(trim(notesForKeys?.[k] || ''));
    }) || [];

  function openSheet() {
    setMarkError(false);
    setOpenSelect(true);
    setTimeout(() => {}, 10);
  }

  function closeSheet() {
    if (!isEmpty(answerKeysWithInvalidNote)) {
      toast.info(sltStr({ key: 'label-detailed-description-required', capit: true }));
      setMarkError(true);
      return;
    }

    setOpenSelect(false);
  }

  function handleChange(v: ChangeEvent<HTMLInputElement>) {
    if (!updateAnswer) return;

    const { value, checked } = v.target;

    const answerKey = value;
    const isMutExcl = mutExcAnswerKeys.includes(answerKey);
    const extNoteRequired = !!answerOptionsMap?.[answerKey]?.requireNote;

    const extNoteId = `${questionKey}-ext-${answerKey}-onsheet`;

    let nKeys: Keys = [];

    if (checked) {
      if (isMutExcl) {
        nKeys = uniq([value]);
      } else {
        nKeys = uniq([...selectedKeys, value]);
        nKeys = nKeys?.filter((k) => {
          return !mutExcAnswerKeys.includes(k);
        });
      }
    } else {
      nKeys = selectedKeys.filter((nv) => nv !== value);
    }

    // removing ext notes for deleted keys
    const currKeys = keys(notesForKeys);
    const nNotesForKeys = produce(notesForKeys, (draft) => {
      const removingKeys = difference(currKeys, nKeys);
      removingKeys?.forEach((rk) => {
        delete draft?.[rk];
      });
    });

    if (single) {
      const lastItem = last(nKeys);
      nKeys = lastItem ? [lastItem] : [];
    }

    updateAnswer({
      questionKey,
      answerKeys: nKeys,
      notesForKeys: nNotesForKeys,
    });

    if (extNoteRequired && checked && ref.current) {
      focusWithId(ref.current, extNoteId);
      focusWithIdTimeOut(ref.current, extNoteId, 400);
    }

    if (single && !extNoteRequired) {
      setOpenSelect(false);
    }
  }

  return (
    <>
      {openSelect && (
        <Modal
          open
          className={classNames('t_QsInputSelectSheet p-0 overflow-hidden rounded-t-3xl')}
          containerCls='pt-20 lg:pt-0'
        >
          <div className='flex-1 flex-col overflow-hidden'>
            <div className='flex-col flex-none gap-2 overflow-hidden pb-4 w-full'>
              <div className='flex-none flex-center'>
                {/* top modal handle */}
                <div className='bg-black bg-opacity-10 rounded-full w-10 h-2 mt-3'></div>
              </div>
              <div className='flex-none flex-center-y gap-2 px-5'>
                <H3 className='flex-1 text-center'>{t(questionKey, tOptions)}</H3>
                <div className='w-0 overflow-visible'>
                  <button
                    className={classNames('p-2 inline')}
                    onClick={async () => {
                      closeSheet();
                    }}
                  >
                    <MdClose size={20} className='opacity-60 -top-4 relative right-7' />
                  </button>
                </div>
              </div>
            </div>

            <div className='flex-col gap-3 overflow-auto px-3 pb-3'>
              {answerKeys?.map((answerKey) => {
                const optionChecked = selectedKeys?.includes(answerKey);
                const requireNoteInput =
                  optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

                const extNoteId = `${questionKey}-ext-${answerKey}-onsheet`;

                const isSelected = selectedKeys.includes(answerKey);
                const note = notesForKeys?.[answerKey] || '';

                return (
                  <div key={`in-sheet-${answerKey}`}>
                    <label className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'>
                      <div className='flex-1 w-full flex-row gap-4'>
                        <input
                          value={answerKey}
                          name={questionKey}
                          checked={isSelected}
                          type={single ? 'radio' : 'checkbox'}
                          className={classNames(
                            !single
                              ? 'checkbox checkbox-lg checkbox-primary'
                              : 'radio radio-lg radio-primary'
                          )}
                          onChange={handleChange}
                        />
                        <span className='pt-[0.2rem]'>{t(answerKey, {})}</span>
                      </div>

                      {requireNoteInput && (
                        <label className='flex flex-col'>
                          <QsTextInput
                            {...props}
                            locale={locale}
                            placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                            value={note}
                            className={classNames(
                              extNoteId,
                              markError && isEmpty(trim(note)) && 'border-error',
                              'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                            )}
                            onUpdate={(value) => {
                              if (!updateAnswer) return;
                              updateAnswer({
                                questionKey,
                                notesForKeys: {
                                  ...notesForKeys,
                                  [answerKey]: value,
                                },
                              });
                            }}
                          />
                        </label>
                      )}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className='flex justify-end px-3 py-3'>
              <button
                className={classNames('btn')}
                onClick={async () => {
                  closeSheet();
                }}
              >
                {sltStr({ key: 'btn-close', capit: true })}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div ref={ref} className='t_QsInputSelectSheet flex-col gap-2'>
        <div className='w-full'>
          <div className='form-control w-full'>
            <div
              className='w-full flex cursor-pointer'
              onClick={() => {
                openSheet();
              }}
            >
              <input
                type='text'
                placeholder={sltStr({ key: 'label-select', upper: true })}
                className={classNames(
                  'flex-1 input pointer-events-none overflow-ellipsis',
                  isRtl ? 'rounded-l-none border-l-0 ' : 'rounded-r-none border-r-0 '
                )}
                value={!single && firstSelectedKey ? '' : t(firstSelectedKey) || ''}
                onChange={() => {}}
              />
              <span
                className={classNames(
                  'border-y flex-center p-2',
                  isRtl ? 'border-l rounded-l-lg' : 'border-r rounded-r-lg'
                )}
              >
                <MdChevronLeft className='-rotate-90 opacity-70' size={23} />
              </span>
            </div>
          </div>
        </div>
        {single && firstSelectedKeyRerquireNote && firstSelectedKey && (
          <label key={`${firstSelectedKey}-note`} className='flex flex-col'>
            <QsTextInput
              {...props}
              locale={locale}
              placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
              value={firstKeyNote || ''}
              className={classNames(
                // extNoteId,
                markError && isEmpty(trim(firstKeyNote || '')) && 'border-error',
                'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
              )}
              onUpdate={(value) => {
                if (!updateAnswer) return;
                updateAnswer({
                  questionKey,
                  notesForKeys: {
                    [firstSelectedKey]: value,
                  },
                });
              }}
            />
          </label>
        )}
        {!single && (
          <div className='flex-col gap-1'>
            {selectedKeys.map((answerKey) => {
              const optionChecked = selectedKeys?.includes(answerKey);
              const showExtNoteInput = optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

              const extNoteId = `${questionKey}-ext-${answerKey}`;
              const note = notesForKeys?.[answerKey] || '';

              return (
                <div key={answerKey}>
                  <label className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'>
                    <div className='flex-1 w-full flex-row gap-4'>
                      <input
                        value={answerKey}
                        name={questionKey}
                        checked
                        type={single ? 'radio' : 'checkbox'}
                        className={classNames(
                          !single
                            ? 'checkbox checkbox-lg checkbox-primary'
                            : 'radio radio-lg radio-primary'
                        )}
                        onChange={handleChange}
                      />
                      <span className='pt-[0.2rem]'>{t(answerKey, {})}</span>
                    </div>

                    {showExtNoteInput && (
                      <label key={`${answerKey}-note`} className='flex flex-col'>
                        <QsTextInput
                          {...props}
                          locale={locale}
                          placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                          value={note}
                          className={classNames(
                            extNoteId,
                            markError && isEmpty(trim(note || '')) && 'border-error',
                            'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                          )}
                          onUpdate={(value) => {
                            if (!updateAnswer) return;
                            updateAnswer({
                              questionKey,
                              notesForKeys: {
                                ...notesForKeys,
                                [answerKey]: value,
                              },
                            });
                          }}
                        />
                      </label>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default memo(QsInputSelectSheet);
