import classNames from 'classnames';
import { useState } from 'react';
import { useVersionQuery } from '@cyren/common-lib';
import config from '../config';


export default function Version() {
  const [backendVersion, setBackendVersion] = useState<string | null | undefined>('x.x.x');
  useVersionQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (myData) => {
      setBackendVersion(myData?.version);
    }

  });


  // This function is intended for use with the electron widget to trigger display of
  // developer tools
  const handleSpecialContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {

    // Check if the shift key was held down during the right-click
    if (event.shiftKey) {
      // Prevent the default context menu from opening
      event.preventDefault();

      // request the widget to open up its debugging tools
      window.electron?.ipcRenderer?.sendMessage('open-debugging-tools');
    }
  };

  return (
    <div className={classNames('text-xs opacity-60 scale-75')}
      onContextMenu={handleSpecialContextMenu}>
      <span>f-{config.version}:b-{backendVersion}</span>
    </div>
  );
}
