import { Enum_Questiontree_Treetype, Enum_Translation_Locale, hasOnlyNumbersAndPunctuationCharacters, isEnglishWithSpecialChars, notNull } from '@cyren/common-lib';
import { forEach, uniq, values } from 'lodash';
import { TQsTree } from '../../graphql-enhanced';
import { Keys, QuestionTreeType, TreeState } from '../../patient/patient-types';
import { AnswerValue } from '../../report/types';
import { getKeysFromAnswerValue } from './template';

export function genStepNameKey(tree?: TQsTree | QuestionTreeType | null) {
  const { treeKey, stepNameKey: stepNameKeyR } = tree || {};

  const stepNameKey = stepNameKeyR || `${treeKey}-name-key`;
  return stepNameKey;
}

export function genStepDescKey(tree?: TQsTree | QuestionTreeType | null) {
  const stepNameKey = genStepNameKey(tree);

  const stepDescKey = `${stepNameKey}-desc-key`;
  return stepDescKey;
}

export function getKeysForTreeStates(treeStates: TreeState[]) {
  const keysInTrees = treeStates.reduce((prev, treeState) => {
    const docTermKeys = values(treeState?.answerMap).reduce((prev2, answerValue) => {
      const answerKeys = getKeysFromAnswerValue(answerValue);
      return uniq([...prev2, ...answerKeys]);
    }, [] as Keys);

    return uniq([...prev, ...docTermKeys]);
  }, [] as Keys);

  return keysInTrees;
}

export function getSxKeysFromTreeStates(treeStates: TreeState[]) {
  const sxTreeKeys = treeStates
    .map((trs) => {
      if (trs.tree?.treeType === Enum_Questiontree_Treetype.Symptom) {
        return trs.treeKey;
      }
      return null;
    })
    .filter(notNull);
  return sxTreeKeys;
}

/*** NOTE: This function has been ported to the backend as well to support additional
 * Workflows.  If making modifications, make sure backend workflow is updated as well
 */
export function getUserNotesForAnswerValue({ answerValue }: { answerValue: AnswerValue }) {
  const valueNotes: string[] = values(answerValue?.notesForKeys).filter(notNull) || [];
  const answerValues: string[] = values(answerValue?.answerValues).filter(notNull) || [];
  const notes: string[] =
    answerValue?.answerMaps?.reduce((prev, answerMapL1) => {
      let notesL1 = [] as string[];
      forEach(values(answerMapL1), (answerValueL1) => {
        const notesL2 = getUserNotesForAnswerValue({ answerValue: answerValueL1 });
        notesL1 = [...notesL1, ...notesL2];
      });

      return [...prev, ...notesL1];
    }, [] as string[]) || [];

  return [...valueNotes, ...answerValues, ...notes];
}


/*** NOTE: This function has been ported to the backend as well to support additional
 * Workflows.  If making modifications, make sure backend workflow is updated as well
 */
export function getNotesFromTreeState({ treeState, locale }: { treeState: TreeState, locale: Enum_Translation_Locale }) {
  let notes: string[] = values(treeState?.answerMap).reduce((prev, answerValue) => {

    // skip keys known to represent names that shouldn't be translated
    if (['q-patient-first-name', 'q-patient-last-name', 'q-patient-middle-name',
      'q-ih-patient-first-name', 'q-ih-patient-last-name',
      'q-ih-first-name', 'q-ih-last-name',
      'q-guardian-name',
      'q-primary-care-doctor-info', 'q-ih-primary-care-doctor-info'
    ].find((i => i === answerValue?.questionKey))) {
      return prev;
    }

    const notesL1 = getUserNotesForAnswerValue({ answerValue });
    return [...prev, ...notesL1];
  }, [] as string[]);

  notes = notes.filter((i => !hasOnlyNumbersAndPunctuationCharacters(i)));
  notes = uniq(notes).filter((i) => locale !== "english" || !isEnglishWithSpecialChars(i));

  return notes;
}
