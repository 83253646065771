import { isEmpty, trim } from 'lodash';
export function isNotFalsy(value) {
    if (value == null) {
        return false;
    }
    return true;
}
export function isNotEmpty(value) {
    if (typeof value === 'string') {
        return !isEmpty(trim(value));
    }
    return !isEmpty(value);
}
