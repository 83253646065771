import { Enum_Question_Type, Enum_Translation_Locale, getChecksum } from '@cyren/common-lib';
import classNames from 'classnames';
import { first } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import { useToastMsgs } from '../../admin/hooks/use-toast-msgs';
import useAppState from '../../app/use-app-state';
import { H3 } from '../../common/typo/H';
import LoadingAnim from '../../components/LoadingAnim';
import Modal from '../../components/Modal';
import { useOnNavChange } from '../../hooks/use-on-nav-change';
import { useData } from '../../report/use-data';
import { usePatientTreeData } from '../../report/use-patient-tree-data';
import { PaReportState, useQsVisibilitySync, useReportState } from '../hooks/use-report';
import { useTranslations } from '../hooks/use-translations';
import { useTreeHelpers } from '../hooks/use-tree-helpers';
import { Key, Keys, ModalSxStateType } from '../patient-types';
import Question from '../tree/Question';
import { useSxModalState } from './use-sx-modal-answers';
import { BiTrash } from 'react-icons/bi';
import useConfirmationDialog from '../../hooks/use-confirmation-dialog';
import { useRecoilState } from 'recoil';

function ModalSxCollector(props: {
  sxKey: Key;
  updateTreeState?: boolean;
  // question: QuestionType
  onClose: () => void;
  onSave: (modalSxState: ModalSxStateType) => void;
  onDelete?: () => void;
  onInterimUpdate: (modalSxState: ModalSxStateType) => void;
  locale: Enum_Translation_Locale;
  suppressSxCollectors?: boolean;
}) {
  const { updateTreeState, sxKey, onClose, onSave, onDelete, onInterimUpdate, locale, suppressSxCollectors } = props;
  const { sltStr } = useStaticLocales();
  const [dirty, setDirty] = useState(false);
  const [isNewSymptom, setIsNewSymptom] = useState(true);
  const [confirmDialog, confirmationDialogUX] = useConfirmationDialog();
  const [reportState] = useRecoilState(PaReportState);

  // const [, { onInterimSave }] = useSurveyState();

  const { showAnswerAll } = useToastMsgs();
  // console.log('props', props);
  // console.log('updateTreeState', updateTreeState);
  /* console.log('sxKey', sxKey); */

  const [{ treeState, selectedSxTrees }] = useReportState({
    treeKey: updateTreeState ? sxKey : undefined,
  });

  const [errorQsKeys, setErrorQsKeys] = useState<Keys>([]);

  const [
    {
      // dataState: { trees },
      loadingTrees,
      loadingTrans,
    },
  ] = usePatientTreeData({
    autoRefetch: true,
    treeKey: sxKey,
  });

  const loadingData = loadingTrees || loadingTrans;

  const [
    { modalSxState },
    { updateAnswer, updateAnswerValue, setModalSxState, onUiMetadataChange },
  ] = useSxModalState({
    sxKey,
    initState: treeState,
  });

  // TODO consolidate sx tree state for sxmodal in sxmodal cases.
  // sxmodal stores final answers in reportState which should be accomodated in validating first sx modal state
  const consolidatedTreeState: ModalSxStateType | null = modalSxState
    ? {
      ...modalSxState,
      answerMap: {
        ...modalSxState?.answerMap,
      },
    }
    : null;

  const consolidatedTreeStateChecksum = getChecksum(consolidatedTreeState);

  const [{ isValid, qsKeysNotAnswered, qsErrorMap }] = useQsVisibilitySync({
    treeState: consolidatedTreeState,
    forSxModal: true,
    suppressSxCollectors
  });

  useEffect(() => {
    if (consolidatedTreeState) {
      onInterimUpdate(consolidatedTreeState);
    }
  }, [consolidatedTreeStateChecksum]);

  useEffect(() => {
    setIsNewSymptom(!selectedSxTrees.find(sx => sx.treeKey === sxKey));
  }, [sxKey]);


  useEffect(() => {
    if (modalSxState && reportState?.globalAnswerMap) {
      const globalStateVariables = modalSxState?.tree?.entryQuestionKeys?.filter(qsKey => Object.prototype.hasOwnProperty.call(reportState?.globalAnswerMap, qsKey));
      globalStateVariables?.forEach(qsKey => {

        // if there are any "global" answers in this form that haven't been answered, go ahead and pre-fill them
        if (!modalSxState.answerMap[qsKey]) {
          const answerValue = reportState?.globalAnswerMap?.[qsKey];
          if (answerValue && answerValue.questionKey) {
            updateAnswer({
              ...answerValue,
              questionKey: qsKey,
            });
          }
        }

      });
    }
  }, [modalSxState]);


  const [, { isQuestionVisible }] = useTreeHelpers({ treeState: consolidatedTreeState });

  const [, { t }] = useTranslations();
  const [, { getQuestionByKey }] = useData();

  const [{ isRtl }, { closeModalWithKey, openModalWithKey }] = useAppState();

  const modalKey = `sx-coll-${sxKey}`;
  useEffect(() => {
    openModalWithKey(modalKey);
    return () => {
      closeModalWithKey(modalKey);
    };
  }, []);

  const { tree } = modalSxState || {};

  // eslint-disable-next-line
  // console.log('modal sx treeState', treeState);
  // eslint-disable-next-line
  // console.log('modalSxState', modalSxState);

  const isDataMissing = !tree || !modalSxState;

  const handleClose = useCallback(() => {
    onClose();
    setModalSxState(null);
  }, [setModalSxState]);

  useOnNavChange({
    block: true,
    onChange: useCallback(() => {
      handleClose();
    }, [handleClose]),
  });

  return (
    <Modal
      key={modalKey}
      open
      className='t_ModalSxCollector h-full rounded-t-xl mt-6 px-0 overflow-hidden py-0'
      id={sxKey}
    >
      {/* <Prompt when={showPrompt} message='Unsaved changes detected, continue?' beforeUnload /> */}

      <div className='flex-col flex-1 overflow-hidden'>
        <div className='flex-col flex-1 overflow-hidden'>
          <div className='flex-col flex-none gap-2 overflow-hidden pb-4'>
            <div className='flex-none flex-center'>
              {/* top modal handle */}
              <div className='bg-black bg-opacity-10 rounded-full w-10 h-2 mt-3'></div>
            </div>
            <div className='flex-none flex-center-y gap-2 px-5'>
              <H3 className='flex-1 text-center'>{t(sxKey)}</H3>
              <div className='w-0 overflow-visible'>
                <button
                  className='p-2 inline'
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <MdClose
                    size={20}
                    className={classNames(
                      'opacity-60 relative -top-4 ',
                      isRtl ? 'left-7' : 'right-7'
                    )}
                  />
                </button>
              </div>
            </div>
          </div>

          {loadingData || isDataMissing ? (
            <div className='flex-1 flex-center py-20'>
              <LoadingAnim className='h-auto' />
            </div>
          ) : (
            <div className='flex-1 flex-col gap-10 overflow-auto px-5'>
              {/* tree not found */}
              {!tree && 'Data not found'}

              {/* render sx tree */}
              {tree?.entryQuestionKeys.map((questionKey) => {
                const question = getQuestionByKey(questionKey);
                if (!question) return null;
                const visible = isQuestionVisible({ question, treeKey: tree.treeKey });
                if (!visible) return null;

                if (suppressSxCollectors && question.type === Enum_Question_Type.SxSelect) {
                  return null;
                }

                return (
                  <Question
                    locale={locale}
                    dirty={dirty}
                    level={1}
                    key={questionKey}
                    error={errorQsKeys.includes(questionKey)}
                    errorMsgs={qsErrorMap?.[question?.questionKey || '']}
                    treeKey={sxKey}
                    question={question}
                    treeState={consolidatedTreeState}
                    updateAnswer={updateAnswer}
                    tOptions={{ symptom: t(sxKey) }}
                    onUiMetadataChange={onUiMetadataChange}
                    updateValue={updateAnswerValue}
                  />
                );
              })}
              <div className='flex-col gap-4         pb-16'>
                <button
                  className={classNames('btn btn-primary flex-1 btn-lg')}
                  onClick={() => {
                    setDirty(true);
                    if (!isValid) {
                      const firstKeyNotAnswered = first(qsKeysNotAnswered);
                      setErrorQsKeys(qsKeysNotAnswered);

                      if (firstKeyNotAnswered) {
                        const selectKey = `#${sxKey} #${firstKeyNotAnswered}`;
                        const el = first(document.querySelectorAll(selectKey));

                        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        showAnswerAll();
                      }
                      return;
                    }

                    if (consolidatedTreeState == null) return;

                    onSave(consolidatedTreeState);
                    setModalSxState(null);
                  }}
                >
                  {sltStr({ key: 'btn-save', capit: true })}
                </button>
                {(!isNewSymptom && onDelete) &&
                  <>
                    {confirmationDialogUX}
                    <button
                      className={classNames('btn flex-1 btn-lg')}
                      onClick={async () => {
                        const yes = await confirmDialog("", sltStr({ key: 'msg-delete-answers' }), undefined, sltStr({ key: 'btn-delete-symptom' }));
                        if (!yes) return;

                        onDelete();


                        setModalSxState(null);
                      }}
                    >
                      <BiTrash size={22} className={classNames('opacity-60')} />
                      {sltStr({ key: 'btn-delete-symptom', title: true })}
                    </button>
                  </>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal >
  );
}

export default memo(ModalSxCollector);
