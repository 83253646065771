import { createArray } from '@cyren/common-lib';
import classNames from 'classnames';
import { memo } from 'react';
import useAppState from '../../app/use-app-state';

function ProgressBar({ stepCount, current }: { stepCount?: number; current: number }) {
  const cls = classNames('flex-1 h-[0.8125rem]', 'bg-primary');
  const [{ isRtl }] = useAppState();

  const stepCountFixed = stepCount || 7;
  const maxLen = stepCountFixed - (current + 1) < 0 ? 0 : stepCountFixed - (current + 1);

  return (
    <div className={classNames('flex-col gap-2 w-full items-center')}>
      <div className='flex w-[60%]'>
        <div className='inline-block rounded-xl overflow-hidden w-full'>
          <span className='prog flex-row w-full bg-primary bg-opacity-20'>
            {createArray(current + 1).map((_, idx) => {
              const last = current === idx;
              return (
                <div
                  key={idx}
                  className={classNames(
                    `prog-item-on ${cls} bg-opacity-100`,
                    last && (isRtl ? 'rounded-l-full' : 'rounded-r-full')
                  )}
                ></div>
              );
            })}
            {createArray(maxLen).map((_, idx) => {
              return <div key={idx} className={`prog-item ${cls} bg-opacity-0`}></div>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

export default memo(ProgressBar);
