import classNames from 'classnames';
import produce from 'immer';
import { includes, uniq } from 'lodash';
import { ChangeEventHandler, InputHTMLAttributes, memo, useState } from 'react';

import { noneAboveKey, sexualAssaultKey } from '../../../report/report-config';
import { useData } from '../../../report/use-data';
import { usePatientTransData } from '../../../report/use-patient-trans-data';
import { useReportState } from '../../hooks/use-report';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';
import { Key, QuestionType } from '../../patient-types';
import QsCollapsibleGroupItem from './QsCollapsibleGroupItem';

function QsCollapsibleGroup(
  props: InputHTMLAttributes<HTMLInputElement> &
    Omit<QuestionProps, 'question'> & {
      optionKey: Key;
      question: QuestionType;
    }
) {
  const { updateAnswer, optionKey: groupKey, locale, treeKey, question, error } = props;
  const [, { getQuestionByKey }] = useData();
  const [, { t }] = useTranslations({ locale });

  const { questionKey } = question;

  const [selectedGroup, setSelectedGroup] = useState<Key | null>(null);
  const [stagedSx] = useState<Key | null>(null);
  const [{ reportState }, { setReportState }] = useReportState({ treeKey: stagedSx || '' });

  // Load selected group keys
  const optionQuestion = getQuestionByKey(selectedGroup);
  const { answerKeys: optionAnswerKeys } = optionQuestion || {};

  // console.log('selectedGroup', selectedGroup);
  // console.log('optionQuestion', optionQuestion);
  // console.log('optionAnswerKeys', optionAnswerKeys);

  const [{ loadingTrans }] = usePatientTransData({
    keys: optionAnswerKeys,
  });

  const mainTreeState = reportState.treeStates.find((ts) => ts.treeKey === treeKey);

  const mainTreeAnswerKeys = mainTreeState?.answerMap[question.questionKey]?.answerKeys || [];
  const nonAboveSelected = includes(mainTreeAnswerKeys, noneAboveKey);
  const selectedKeys = mainTreeAnswerKeys;

  const selected = selectedGroup === groupKey;

  const sexualAssltSelected = includes(mainTreeAnswerKeys, sexualAssaultKey);

  const isNoneAboveOption = groupKey === noneAboveKey;
  const isSexuAssltOption = groupKey === sexualAssaultKey;

  const handleTopOptions: ChangeEventHandler<HTMLInputElement> = (v) => {
    const { value, checked } = v.target;

    const isNoneAboveOptionT = value === noneAboveKey;

    if (!treeKey) return;

    if (isNoneAboveOptionT && checked) {
      // TODO confirm discarding data

      setReportState(
        produce(reportState, (draft) => {
          // 1. only select others
          const treeIndex = draft.treeStates.findIndex((ts) => ts.treeKey === treeKey);
          if (!draft.treeStates[treeIndex].answerMap[question.questionKey]) {
            draft.treeStates[treeIndex].answerMap[question.questionKey] = {};
          }

          // @ts-ignore
          draft.treeStates[treeIndex].answerMap[question.questionKey].answerKeys = [noneAboveKey];

          // 2. delete selected symptom data
          const sxKeysToDelete = selectedKeys.filter((k) => k !== noneAboveKey) || [];

          draft.treeStates = draft.treeStates.filter((ts) => {
            return !sxKeysToDelete.includes(ts.treeKey);
          });
        })
      );

      return;
    }

    const nKeys = checked
      ? uniq([...selectedKeys, value])
      : // exclude none above key when another is selected
        selectedKeys.filter((nv) => nv !== value);

    if (!updateAnswer) return;
    updateAnswer({
      questionKey,
      answerKeys: nKeys.filter((k) => k !== noneAboveKey),
    });
  };

  if (isNoneAboveOption) {
    return (
      <div key={groupKey} className='t_QsCollapsibleGroup_nao form-control'>
        <label className='label cursor-pointer gap-2 py-3'>
          <span className='label-text text-base'>{t(groupKey)}</span>
          <input
            type='checkbox'
            className='checkbox checkbox-primary checkbox-sm rounded'
            value={groupKey}
            checked={nonAboveSelected}
            onChange={handleTopOptions}
          />
        </label>
      </div>
    );
  }

  if (isSexuAssltOption) {
    return (
      <div key={groupKey} className='t_QsCollapsibleGroup_sao form-control'>
        <label className='label cursor-pointer gap-2 py-3 px-5'>
          <span className='label-text text-base'>{t(groupKey)}</span>
          <input
            type='checkbox'
            className={classNames(
              'checkbox checkbox-sm rounded',
              nonAboveSelected ? 'bg-black bg-opacity-10' : 'checkbox-primary'
            )}
            checked={sexualAssltSelected}
            value={groupKey}
            onChange={handleTopOptions}
          />
        </label>
      </div>
    );
  }

  return (
    <>
      <div
        key={groupKey}
        className={classNames(
          't_QsCollapsibleGroup',
          'collapse collapse-arrow bg-base-100 rounded-none ',
          'border-b border-t-0',
          selected && 'collapse-open',
          error && 'border-error'
        )}
      >
        <div
          className='cursor-pointer collapse-title text-base font-medium'
          onClick={() => {
            if (selected) {
              setSelectedGroup(null);
            } else {
              setSelectedGroup(groupKey);
            }
          }}
        >
          {t(groupKey)}
        </div>
        <div className='collapse-content py-0 pl-3 pr-0'>
          {loadingTrans && <span className='loading loading-spinner loading-sm'></span>}

          {!loadingTrans &&
            optionAnswerKeys?.map((oAKey) => {
              return <QsCollapsibleGroupItem key={oAKey} {...props} optionKey={oAKey} />;
            })}
        </div>
      </div>
    </>
  );
}

export default memo(QsCollapsibleGroup);
