import { useEffect } from 'react';
export function useGlobalKeydown({ onEscapeKey, onEnterKey, onClick, onKeydown, }, deps) {
    useEffect(() => {
        function handleKeyDown(e) {
            if (onEnterKey && e.code === 'Enter') {
                onEnterKey();
            }
            if (onEscapeKey && e.code === 'Escape') {
                onEscapeKey();
            }
            if (onKeydown) {
                onKeydown(e);
            }
        }
        function handleClick(e) {
            if (onClick) {
                onClick(e);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mouseup', handleClick);
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mouseup', handleClick);
        };
    }, deps);
}
