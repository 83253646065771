import BaseBadWordsFilter from 'bad-words';
import { size } from 'lodash';
// https://github.com/web-mech/badwords/pull/95
class BadWords extends BaseBadWordsFilter {
    splitRegex;
    clean(string) {
        try {
            const joinMatch = this.splitRegex.exec(string);
            const joinString = joinMatch?.[0] || '';
            return string
                .split(this.splitRegex)
                .map((word) => {
                // @ts-ignore
                return this.isProfane(word) ? this.replaceWord(word) : word;
            })
                .join(joinString);
        }
        catch (e) {
            return string;
        }
    }
}
export function hasBadwords(str) {
    const filter = new BadWords();
    const cleaned = filter.clean(str);
    const hasBad = size(str) > 0 && str !== cleaned;
    return [hasBad, cleaned];
}
