import {
  OnsetDateFormat,
  PatientDobFormat,
  QuestionFragment,
  SystemDate,
  formatDate,
  notNull,
  parseDateV2,
} from '@cyren/common-lib';
import produce from 'immer';
import { difference, pick } from 'lodash';
import { convQs } from '../../gql-tools/utils/convert';
import { TQuestion, TQuestionInput } from '../../graphql-enhanced';
import { PaDataRepoStateT, SystemValueMap } from '../../patient/patient-types';

export const systemKeys = ['firstName', 'lastName', 'cc', 'sex', 'dob', 'phone', 'custom1', 'custom2', 'custom3',
    'address_1', 'address_2', 'city', 'state', 'zipcode', 'country', 
];
export const dateTypeSystemKey = ['dob'];

// optional system keys don't need to be stored at the root of the patient visit data
// so remove them from the visitFormData
export const omitSystemKeys = ['phone', 'custom1', 'custom2', 'custom3', 'address_1', 'address_2', 'city', 'state', 'zipcode', 'country'];

export function getVisitFormDataFromSystemValueMap({
  systemValueMap,
}: {
  systemValueMap?: SystemValueMap;
}) {
  const uvFields = systemKeys;
  const visitFormData = pick(systemValueMap, difference(uvFields, omitSystemKeys));

  dateTypeSystemKey.forEach((dateKey) => {
    const value = visitFormData[dateKey];

    if (value) {
      const dateObj = parseDateV2({ dateStr: value, formatStr: PatientDobFormat });
      if (dateObj) {
        visitFormData[dateKey] = formatDate(dateObj, {
          formatStr: SystemDate,
        });
        return;
      }

      // to mitigate format mismatch
      const dateObj2 = parseDateV2({ dateStr: value, formatStr: OnsetDateFormat });
      if (dateObj2) {
        visitFormData[dateKey] = formatDate(dateObj2, {
          formatStr: SystemDate,
        });
      }
    }
  });

  return visitFormData;
}

export function getAutocompletePropForQs({ question }: { question: TQuestion }) {
  if (question?.systemKey?.includes('firstName')) return 'first-name';
  if (question?.systemKey?.includes('lastName')) return 'last-name';

  return '';
}

export function updateQsDataRepo({
  data,
  st,
}: {
  data?: QuestionFragment[];
  st: PaDataRepoStateT;
}) {
  const list = data?.map(convQs).filter(notNull);

  return produce(st, (draft) => {
    list?.forEach((item) => {
      if (draft.doctorsTerms == null) draft.doctorsTerms = [];

      const idx = draft.questions.findIndex((t) => t.id === item?.id);
      if (idx > -1) {
        draft.questions[idx] = item;
      } else {
        draft.questions.push(item);
      }
    });
  });
}

export function getMutualExclusiveAnswerKeys({
  question,
}: {
  question: TQuestion | TQuestionInput | undefined;
}) {
  const { answerKeys, answerOptionsMap } = question || {};

  const mutExcKeys = (answerKeys || [])?.filter((k) => !!answerOptionsMap?.[k]?.mutuallyExclusive);

  return mutExcKeys;
}

export function getRequireNoteAnswerKeys({
  question,
}: {
  question: TQuestion | TQuestionInput | undefined;
}) {
  const { answerKeys, answerOptionsMap } = question || {};

  const mutExcKeys = (answerKeys || [])?.filter((k) => !!answerOptionsMap?.[k]?.requireNote);

  return mutExcKeys;
}

export function getMainOptionAnswerKeys({
  question,
}: {
  question: TQuestion | TQuestionInput | undefined;
}) {
  const { answerKeys, answerOptionsMap } = question || {};

  const mutExcKeys = (answerKeys || [])?.filter((k) => !!answerOptionsMap?.[k]?.mainOption);

  return mutExcKeys;
}

export function getOmitInSearchAnswerKeys({
  question,
}: {
  question: TQuestion | TQuestionInput | undefined;
}) {
  const { answerKeys, answerOptionsMap } = question || {};

  const mutExcKeys = (answerKeys || [])?.filter((k) => !!answerOptionsMap?.[k]?.omitInSearch);

  return mutExcKeys;
}
