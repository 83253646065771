import { isEmpty } from 'lodash';
import { ConditionType } from '../patient-types';

export function filterEligibleConditions({
  treeKey,
  showConditions,
}: {
  treeKey?: string;
  showConditions?: ConditionType[];
}) {
  if (!treeKey) {
    return showConditions || [];
  }

  const showConditionTreeFiltered = showConditions?.filter((condition) => {
    const { targetTreeKeys } = condition;

    // remove target trees do not include current tree key
    if (!isEmpty(targetTreeKeys) && !targetTreeKeys?.includes(treeKey || '')) {
      return false;
    }

    return true;
  });

  return showConditionTreeFiltered;
}
