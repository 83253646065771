import { first, last } from 'lodash';
import { notEmpty } from './array';
export const getBracketVarFromStr = ({ str }) => {
    const matches = str.match(/\{\{([^}{]+)\}\}/g);
    const results = matches?.map((match) => {
        return match.replace(/^\{\{|\}\}$/g, '');
    });
    return first(results);
};
export const strStrArrMerger = ({ token } = {}) => (prev, curr) => {
    if (!curr)
        return prev;
    let nList = [...prev];
    if (token && notEmpty(nList) && last(nList) !== token) {
        nList.push(token);
    }
    if (typeof curr === 'string') {
        nList.push(curr);
        return nList;
    }
    else {
        nList = nList.concat(curr);
    }
    return nList;
};
