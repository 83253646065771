import classNames from 'classnames';
import { InputHTMLAttributes, memo } from 'react';

function QsFileInput(props: InputHTMLAttributes<HTMLInputElement>) {
  const { className } = props;

  return (
    <div>
      <input
        type='file'
        className={classNames('input input-bordered w-full', className)}
        {...props}
      />
    </div>
  );
}

export default memo(QsFileInput);
