import { memo, useState } from "react";
import { Key, QuestionTreeType, TreeState } from "../../../patient-types";
import classNames from "classnames";
import { useTranslations } from "../../../hooks/use-translations";
import { BiPencil } from "react-icons/bi";
import { useReportState } from "../../../hooks/use-report";
import produce from "immer";
import { QuestionProps } from "../../../pa-prop-types";
import useConfirmationDialog from "../../../../hooks/use-confirmation-dialog";
import { useStaticLocales } from "../../../../admin/hooks/locales/use-static-locale";
import ModalSxCollector from "../../ModalSxCollector";
import { uniq } from "lodash";
import { noneAboveKey } from "../../../../report/report-config";

function QsSelectedSymptom(
  {
    sxTree,
    questionProps,
  }: {
    sxTree: QuestionTreeType
    questionProps: QuestionProps
  }
) {
  const { locale, question, treeKey, suppressSxCollectors } = questionProps;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();
  const [confirmDialog, confirmationDialogUX] = useConfirmationDialog();

  const [stagedSx, setStagedSx] = useState<Key | null>(null);
  const [{ reportState }, { setReportState }] =
    useReportState({
      treeKey,
    });

  if (!question) {
    return null;
  }

  const { questionKey } = question;
  const mainTreeStateIdx = reportState.treeStates.findIndex((ts) => ts.treeKey === treeKey);
  const mainTreeState = reportState.treeStates[mainTreeStateIdx] as TreeState | undefined;
  const mainTreeAnswerKeys = mainTreeState?.answerMap[question.questionKey]?.answerKeys || [];

  const stagedTreeState = reportState.treeStates.find((tr) => tr.treeKey === stagedSx);
  const isEditingTreeState = !!stagedTreeState;



  const optionKey = sxTree.treeKey;

  function updateReportData(modalSxState: TreeState) {
    // eslint-disable-next-line
    // console.log('modalSxState', modalSxState);

    if (!mainTreeState || mainTreeStateIdx === -1) return;

    const sxTreeStateIdx = reportState.treeStates.findIndex(
      (ts) => ts.treeKey === stagedSx
    );

    const nReportState = produce(reportState, (draft) => {
      // update question state in main tree
      if (mainTreeState?.treeKey && mainTreeStateIdx !== -1) {
        // remove none above key
        const nKeys = uniq([...mainTreeAnswerKeys, stagedSx]).filter(
          (n) => n !== noneAboveKey
        ) as string[];

        draft.treeStates[mainTreeStateIdx] = {
          ...mainTreeState,
          answerMap: {
            ...mainTreeState?.answerMap,
            [questionKey]: {
              ...mainTreeState?.answerMap[questionKey],
              answerKeys: nKeys,
            },
          },
        };
      }

      if (sxTreeStateIdx !== -1) {
        // update detail of collapsable
        // sx tree state found
        draft.treeStates[sxTreeStateIdx] = modalSxState;
      } else {
        // sxtree not found
        draft.treeStates.push(modalSxState);
      }
    });

    setReportState(nReportState);
  }

  function deleteSymptom() {
    // delete
    const sxTreeStateIdx = reportState.treeStates.findIndex(
      (ts) => ts.treeKey === optionKey
    );

    // found
    if (sxTreeStateIdx !== -1) {
      setReportState((st) =>
        produce(st, (draft) => {
          if (!mainTreeState?.treeKey) return;

          draft?.treeStates.splice(sxTreeStateIdx, 1);

          // delete it from q-category
          const nKeys = mainTreeState?.answerMap?.[questionKey]?.answerKeys || [];
          draft.treeStates[mainTreeStateIdx] = {
            ...mainTreeState,
            answerMap: {
              ...mainTreeState?.answerMap,
              [questionKey]: {
                ...mainTreeState?.answerMap[questionKey],
                answerKeys: nKeys.filter((k) => k !== optionKey),
              },
            },
          };
        })
      );
    }
  }

  return (
    <div
      key={sxTree.treeKey}
      className={classNames(
        't_QsSelectedSymptom',
        'px-4 py-2 border rounded-lg bg-primary bg-opacity-5 flex',
        " cursor-pointer"
      )}
      onClick={() => {
        setStagedSx(optionKey);
      }}
    >
      {confirmationDialogUX}
      <div className="flex-row gap-3 items-center"

      >
        <input
          type='checkbox'
          checked
          className={classNames(
            'checkbox checkbox-lg rounded checkbox-primary'
          )}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
          }}

          onChange={async (e) => {
            e.stopPropagation();
            e.preventDefault();

            const yes = await confirmDialog("", sltStr({ key: 'msg-delete-answers' }), undefined, sltStr({ key: 'btn-delete-symptom' }));
            if (!yes) return;

            deleteSymptom();
          }}

        />

        <div className={classNames(`o_${sxTree.treeKey}`, 'flex-1 flex-center-y')}>{t(sxTree.treeKey)}</div>
      </div>

      <div className="flex gap flex-nowrap ml-auto items-center">
        <button
          className='btn btn-ghost px-0'
          title={sltStr({ key: 'btn-edit' })}
          aria-label={sltStr({ key: 'btn-edit' })}
          onClick={() => {
            setStagedSx(sxTree.treeKey);
          }}
        >
          <BiPencil size={22} className='cursor-pointer' />

        </button>
      </div>

      {stagedSx && (
        <ModalSxCollector
          locale={locale}
          sxKey={stagedSx}
          updateTreeState={isEditingTreeState}
          suppressSxCollectors={suppressSxCollectors}
          onClose={() => {
            setStagedSx(null);
          }}
          onSave={(modalSxState) => {
            updateReportData(modalSxState);
            setStagedSx(null);
          }}
          onInterimUpdate={(modalSxState) => {
            updateReportData(modalSxState);
          }}
          onDelete={() => {
            setStagedSx(null);
            deleteSymptom();
          }}
        />
      )}

    </div>
  );
}

export default memo(QsSelectedSymptom); 
