/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import produce from 'immer';
import { memo, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { AppStateCookie } from '../app/use-app-state';
import envs from '../common/envs';
import Logo from '../components/Logo';
import { SignInAAD } from './SignInModalAAD';
import SignInGoogle from './SignInModalGoogle';
import Version from '../app/Version';

function SignInSection() {
  const [error, setError] = useState('');

  const setAppStateCookie = useSetRecoilState(AppStateCookie);

  useEffect(() => {
    setAppStateCookie((st) =>
      produce(st, (dr) => {
        dr.msAuth = false;
      }),
    );
  }, []);
  return (
    <div className='flex-center h-screen'>
      <div className='flex-col max-w-lg gap-4 p-3 flex-center-x'>
        <div className='w-12'>
          <Logo />
        </div>
        <div>
          <div className='flex flex-col flex-1 flex-center'>
            <div className='flex-auto flex-center'>
              <div className='flex flex-col gap-4'>
                <h3
                  className='text-2xl font-bold'
                  onClick={() => {
                    window.electron?.ipcRenderer?.sendMessage('send', ['hello']);
                  }}
                >
                  Sign in
                </h3>
                <div className='flex flex-col gap-3 py-4'>
                  {envs.auth.google && (
                    <SignInGoogle
                      onError={(err) => {
                        setError(err || '');
                      }}
                    />
                  )}

                  {envs.auth.ms && (
                    <SignInAAD
                      onError={(err) => {
                        setError(err || '');
                      }}
                    />
                  )}
                </div>
                {error && <div className='text-error'>{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0"><Version/></div>
    </div>
  );
}

export default memo(SignInSection);
