import { startsWith } from 'lodash';
import { useLocation } from 'react-router';

export default function useInAdminDashboard() {
  const loc = useLocation();

  const inAdminPanel = startsWith(loc.pathname, '/admin');
  const inAdminTemplatePanel =
    loc.pathname.includes('/template') && startsWith(loc.pathname, '/admin');

  return { inAdminTemplatePanel, inAdminPanel } as const;
}
