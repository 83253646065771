import classNames from 'classnames';
import paLogo from '../assets/logos/CyrenCare for patients.svg';

export default function LogoPatient({ className }: { className?: string }) {
  return (
    <img
      src={paLogo}
      alt='cyrencare for patient'
      className={classNames('w-full h-full aspect-auto', className)}
    />
  );
}
