import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var Enum_Auditlog_Logtype;
(function (Enum_Auditlog_Logtype) {
    Enum_Auditlog_Logtype["BeAccessIn"] = "be_access_in";
    Enum_Auditlog_Logtype["BeAccessOut"] = "be_access_out";
    Enum_Auditlog_Logtype["EhrIntegrationFailure"] = "ehr_integration_failure";
    Enum_Auditlog_Logtype["EhrIntegrationSuccess"] = "ehr_integration_success";
    Enum_Auditlog_Logtype["LlmSymptomSuggestionFailure"] = "llm_symptom_suggestion_failure";
    Enum_Auditlog_Logtype["ViewPatientRecord"] = "view_patient_record";
})(Enum_Auditlog_Logtype || (Enum_Auditlog_Logtype = {}));
export var Enum_Componentsurveyquestiontags_Questiontag;
(function (Enum_Componentsurveyquestiontags_Questiontag) {
    Enum_Componentsurveyquestiontags_Questiontag["Dob"] = "dob";
    Enum_Componentsurveyquestiontags_Questiontag["Sex"] = "sex";
})(Enum_Componentsurveyquestiontags_Questiontag || (Enum_Componentsurveyquestiontags_Questiontag = {}));
export var Enum_Configurationstring_Type;
(function (Enum_Configurationstring_Type) {
    Enum_Configurationstring_Type["NotificationPatient"] = "notification_patient";
    Enum_Configurationstring_Type["NotificationProvider"] = "notification_provider";
    Enum_Configurationstring_Type["NotificationSystem"] = "notification_system";
})(Enum_Configurationstring_Type || (Enum_Configurationstring_Type = {}));
export var Enum_Ehrintegration_Integration_Action;
(function (Enum_Ehrintegration_Integration_Action) {
    Enum_Ehrintegration_Integration_Action["EpicBasicPdfExport"] = "epic_basic_pdf_export";
})(Enum_Ehrintegration_Integration_Action || (Enum_Ehrintegration_Integration_Action = {}));
export var Enum_Ehrintegration_Integration_Type;
(function (Enum_Ehrintegration_Integration_Type) {
    Enum_Ehrintegration_Integration_Type["Mirth"] = "mirth";
})(Enum_Ehrintegration_Integration_Type || (Enum_Ehrintegration_Integration_Type = {}));
export var Enum_Issuereport_Reporting_For;
(function (Enum_Issuereport_Reporting_For) {
    Enum_Issuereport_Reporting_For["Colleague"] = "colleague";
    Enum_Issuereport_Reporting_For["Myself"] = "myself";
    Enum_Issuereport_Reporting_For["Patient"] = "patient";
})(Enum_Issuereport_Reporting_For || (Enum_Issuereport_Reporting_For = {}));
export var Enum_Migplan_Actiontype;
(function (Enum_Migplan_Actiontype) {
    Enum_Migplan_Actiontype["Archive"] = "archive";
    Enum_Migplan_Actiontype["ColdStorage"] = "coldStorage";
})(Enum_Migplan_Actiontype || (Enum_Migplan_Actiontype = {}));
export var Enum_Notification_Notification_Method;
(function (Enum_Notification_Notification_Method) {
    Enum_Notification_Notification_Method["Email"] = "email";
})(Enum_Notification_Notification_Method || (Enum_Notification_Notification_Method = {}));
export var Enum_Notification_Trigger_Type;
(function (Enum_Notification_Trigger_Type) {
    Enum_Notification_Trigger_Type["Completion"] = "completion";
})(Enum_Notification_Trigger_Type || (Enum_Notification_Trigger_Type = {}));
export var Enum_Patientmessage_Contenttype;
(function (Enum_Patientmessage_Contenttype) {
    Enum_Patientmessage_Contenttype["AddOnForm"] = "addOnForm";
    Enum_Patientmessage_Contenttype["Adhoc"] = "adhoc";
    Enum_Patientmessage_Contenttype["Notification"] = "notification";
    Enum_Patientmessage_Contenttype["Resource"] = "resource";
    Enum_Patientmessage_Contenttype["Verification"] = "verification";
})(Enum_Patientmessage_Contenttype || (Enum_Patientmessage_Contenttype = {}));
export var Enum_Patientmessage_Initiator;
(function (Enum_Patientmessage_Initiator) {
    Enum_Patientmessage_Initiator["Automatic"] = "automatic";
    Enum_Patientmessage_Initiator["Patient"] = "patient";
    Enum_Patientmessage_Initiator["User"] = "user";
})(Enum_Patientmessage_Initiator || (Enum_Patientmessage_Initiator = {}));
export var Enum_Patientmessage_Messagetype;
(function (Enum_Patientmessage_Messagetype) {
    Enum_Patientmessage_Messagetype["Email"] = "email";
    Enum_Patientmessage_Messagetype["Manual"] = "manual";
    Enum_Patientmessage_Messagetype["Sms"] = "sms";
})(Enum_Patientmessage_Messagetype || (Enum_Patientmessage_Messagetype = {}));
export var Enum_Patientvisit_Status;
(function (Enum_Patientvisit_Status) {
    Enum_Patientvisit_Status["Checked"] = "checked";
    Enum_Patientvisit_Status["Discard"] = "discard";
    Enum_Patientvisit_Status["Done"] = "done";
    Enum_Patientvisit_Status["Reported"] = "reported";
    Enum_Patientvisit_Status["Reporting"] = "reporting";
})(Enum_Patientvisit_Status || (Enum_Patientvisit_Status = {}));
export var Enum_Questiontree_Treetype;
(function (Enum_Questiontree_Treetype) {
    Enum_Questiontree_Treetype["Step"] = "step";
    Enum_Questiontree_Treetype["Symptom"] = "symptom";
    Enum_Questiontree_Treetype["System"] = "system";
})(Enum_Questiontree_Treetype || (Enum_Questiontree_Treetype = {}));
export var Enum_Question_Type;
(function (Enum_Question_Type) {
    Enum_Question_Type["BloodPressure"] = "blood_pressure";
    Enum_Question_Type["BodySelect"] = "body_select";
    Enum_Question_Type["BodySelectBack"] = "body_select_back";
    Enum_Question_Type["BodySelectBelly"] = "body_select_belly";
    Enum_Question_Type["BodySelectChest"] = "body_select_chest";
    Enum_Question_Type["BodySelectDental"] = "body_select_dental";
    Enum_Question_Type["BodySelectFace"] = "body_select_face";
    Enum_Question_Type["BodySelectHead"] = "body_select_head";
    Enum_Question_Type["BodySelectJoints"] = "body_select_joints";
    Enum_Question_Type["BodySelectLimbs"] = "body_select_limbs";
    Enum_Question_Type["BodySelectMouth"] = "body_select_mouth";
    Enum_Question_Type["BodySelectSensory"] = "body_select_sensory";
    Enum_Question_Type["BodySelectSubpart"] = "body_select_subpart";
    Enum_Question_Type["BodySelectWhole"] = "body_select_whole";
    Enum_Question_Type["Collapsible"] = "collapsible";
    Enum_Question_Type["CollapsibleItem"] = "collapsible_item";
    Enum_Question_Type["Date"] = "date";
    Enum_Question_Type["DateDob"] = "date_dob";
    Enum_Question_Type["Datetime"] = "datetime";
    Enum_Question_Type["DatetimeRelativeOnset"] = "datetime_relative_onset";
    Enum_Question_Type["DrawBody"] = "draw_body";
    Enum_Question_Type["Frequency"] = "frequency";
    Enum_Question_Type["Image"] = "image";
    Enum_Question_Type["Markdown"] = "markdown";
    Enum_Question_Type["Number"] = "number";
    Enum_Question_Type["NumberUnitSelect"] = "number_unit_select";
    Enum_Question_Type["Onset"] = "onset";
    Enum_Question_Type["OnsetV2"] = "onset_v2";
    Enum_Question_Type["PhoneNumber"] = "phone_number";
    Enum_Question_Type["Range"] = "range";
    Enum_Question_Type["Select"] = "select";
    Enum_Question_Type["SelectAbdLocation"] = "select_abd_location";
    Enum_Question_Type["SelectCountries"] = "select_countries";
    Enum_Question_Type["SelectCountry"] = "select_country";
    Enum_Question_Type["SelectSheet"] = "select_sheet";
    Enum_Question_Type["SelectV2"] = "select_v2";
    Enum_Question_Type["Signature"] = "signature";
    Enum_Question_Type["StageMilemarker"] = "stage_milemarker";
    Enum_Question_Type["SxCollect"] = "sx_collect";
    Enum_Question_Type["SxCollectAssociated"] = "sx_collect_associated";
    Enum_Question_Type["SxCollectIntelligent"] = "sx_collect_intelligent";
    Enum_Question_Type["SxSelect"] = "sx_select";
    Enum_Question_Type["Text"] = "text";
    Enum_Question_Type["Textarea"] = "textarea";
    Enum_Question_Type["Time"] = "time";
})(Enum_Question_Type || (Enum_Question_Type = {}));
export var Enum_Staff_Jobfunction;
(function (Enum_Staff_Jobfunction) {
    Enum_Staff_Jobfunction["AdvancedPracticeProvider"] = "advanced_practice_provider";
    Enum_Staff_Jobfunction["CommunityHealthWorker"] = "community_health_worker";
    Enum_Staff_Jobfunction["Nurse"] = "nurse";
    Enum_Staff_Jobfunction["Physician"] = "physician";
    Enum_Staff_Jobfunction["Registrar"] = "registrar";
})(Enum_Staff_Jobfunction || (Enum_Staff_Jobfunction = {}));
export var Enum_Staff_Role;
(function (Enum_Staff_Role) {
    Enum_Staff_Role["Admin"] = "admin";
    Enum_Staff_Role["Member"] = "member";
})(Enum_Staff_Role || (Enum_Staff_Role = {}));
export var Enum_Surveyeditlog_Type;
(function (Enum_Surveyeditlog_Type) {
    Enum_Surveyeditlog_Type["Label"] = "label";
})(Enum_Surveyeditlog_Type || (Enum_Surveyeditlog_Type = {}));
export var Enum_Surveymeta_Metatype;
(function (Enum_Surveymeta_Metatype) {
    Enum_Surveymeta_Metatype["Qna"] = "qna";
})(Enum_Surveymeta_Metatype || (Enum_Surveymeta_Metatype = {}));
export var Enum_Survey_Access_Restriction;
(function (Enum_Survey_Access_Restriction) {
    Enum_Survey_Access_Restriction["ConferenceDemo"] = "conference_demo";
    Enum_Survey_Access_Restriction["ProviderOnly"] = "provider_only";
})(Enum_Survey_Access_Restriction || (Enum_Survey_Access_Restriction = {}));
export var Enum_Survey_Type;
(function (Enum_Survey_Type) {
    Enum_Survey_Type["Addon"] = "addon";
    Enum_Survey_Type["ErFlow"] = "erFlow";
    Enum_Survey_Type["Other"] = "other";
})(Enum_Survey_Type || (Enum_Survey_Type = {}));
export var Enum_Transapi_Locale;
(function (Enum_Transapi_Locale) {
    Enum_Transapi_Locale["Arabic"] = "arabic";
    Enum_Transapi_Locale["Armenian"] = "armenian";
    Enum_Transapi_Locale["Bengali"] = "bengali";
    Enum_Transapi_Locale["Chinese"] = "chinese";
    Enum_Transapi_Locale["Croatian"] = "croatian";
    Enum_Transapi_Locale["English"] = "english";
    Enum_Transapi_Locale["French"] = "french";
    Enum_Transapi_Locale["German"] = "german";
    Enum_Transapi_Locale["Greek"] = "greek";
    Enum_Transapi_Locale["Gujarati"] = "gujarati";
    Enum_Transapi_Locale["HaitianCreole"] = "haitian_creole";
    Enum_Transapi_Locale["Hebrew"] = "hebrew";
    Enum_Transapi_Locale["Hindi"] = "hindi";
    Enum_Transapi_Locale["Hmong"] = "hmong";
    Enum_Transapi_Locale["Italian"] = "italian";
    Enum_Transapi_Locale["Japanese"] = "japanese";
    Enum_Transapi_Locale["Khmer"] = "khmer";
    Enum_Transapi_Locale["Korean"] = "korean";
    Enum_Transapi_Locale["Persian"] = "persian";
    Enum_Transapi_Locale["Polish"] = "polish";
    Enum_Transapi_Locale["Portuguese"] = "portuguese";
    Enum_Transapi_Locale["Punjabi"] = "punjabi";
    Enum_Transapi_Locale["Russian"] = "russian";
    Enum_Transapi_Locale["Spanish"] = "spanish";
    Enum_Transapi_Locale["Tagalog"] = "tagalog";
    Enum_Transapi_Locale["Tamil"] = "tamil";
    Enum_Transapi_Locale["Telugu"] = "telugu";
    Enum_Transapi_Locale["Thai"] = "thai";
    Enum_Transapi_Locale["Urdu"] = "urdu";
    Enum_Transapi_Locale["Vietnamese"] = "vietnamese";
    Enum_Transapi_Locale["Yiddish"] = "yiddish";
})(Enum_Transapi_Locale || (Enum_Transapi_Locale = {}));
export var Enum_Transapi_Service;
(function (Enum_Transapi_Service) {
    Enum_Transapi_Service["Deepl"] = "deepl";
    Enum_Transapi_Service["Google"] = "google";
    Enum_Transapi_Service["MasterFile"] = "masterFile";
})(Enum_Transapi_Service || (Enum_Transapi_Service = {}));
export var Enum_Transapi_Target_Locale;
(function (Enum_Transapi_Target_Locale) {
    Enum_Transapi_Target_Locale["Arabic"] = "arabic";
    Enum_Transapi_Target_Locale["Armenian"] = "armenian";
    Enum_Transapi_Target_Locale["Bengali"] = "bengali";
    Enum_Transapi_Target_Locale["Chinese"] = "chinese";
    Enum_Transapi_Target_Locale["Croatian"] = "croatian";
    Enum_Transapi_Target_Locale["English"] = "english";
    Enum_Transapi_Target_Locale["French"] = "french";
    Enum_Transapi_Target_Locale["German"] = "german";
    Enum_Transapi_Target_Locale["Greek"] = "greek";
    Enum_Transapi_Target_Locale["Gujarati"] = "gujarati";
    Enum_Transapi_Target_Locale["HaitianCreole"] = "haitian_creole";
    Enum_Transapi_Target_Locale["Hebrew"] = "hebrew";
    Enum_Transapi_Target_Locale["Hindi"] = "hindi";
    Enum_Transapi_Target_Locale["Hmong"] = "hmong";
    Enum_Transapi_Target_Locale["Italian"] = "italian";
    Enum_Transapi_Target_Locale["Japanese"] = "japanese";
    Enum_Transapi_Target_Locale["Khmer"] = "khmer";
    Enum_Transapi_Target_Locale["Korean"] = "korean";
    Enum_Transapi_Target_Locale["Persian"] = "persian";
    Enum_Transapi_Target_Locale["Polish"] = "polish";
    Enum_Transapi_Target_Locale["Portuguese"] = "portuguese";
    Enum_Transapi_Target_Locale["Punjabi"] = "punjabi";
    Enum_Transapi_Target_Locale["Russian"] = "russian";
    Enum_Transapi_Target_Locale["Spanish"] = "spanish";
    Enum_Transapi_Target_Locale["Tagalog"] = "tagalog";
    Enum_Transapi_Target_Locale["Tamil"] = "tamil";
    Enum_Transapi_Target_Locale["Telugu"] = "telugu";
    Enum_Transapi_Target_Locale["Thai"] = "thai";
    Enum_Transapi_Target_Locale["Urdu"] = "urdu";
    Enum_Transapi_Target_Locale["Vietnamese"] = "vietnamese";
    Enum_Transapi_Target_Locale["Yiddish"] = "yiddish";
})(Enum_Transapi_Target_Locale || (Enum_Transapi_Target_Locale = {}));
export var Enum_Translation_Datatype;
(function (Enum_Translation_Datatype) {
    Enum_Translation_Datatype["Option"] = "option";
    Enum_Translation_Datatype["QsLabel"] = "qsLabel";
    Enum_Translation_Datatype["QsTitle"] = "qsTitle";
    Enum_Translation_Datatype["TreeStep"] = "treeStep";
    Enum_Translation_Datatype["TreeSx"] = "treeSx";
    Enum_Translation_Datatype["TreeSystem"] = "treeSystem";
})(Enum_Translation_Datatype || (Enum_Translation_Datatype = {}));
export var Enum_Translation_Locale;
(function (Enum_Translation_Locale) {
    Enum_Translation_Locale["Arabic"] = "arabic";
    Enum_Translation_Locale["Armenian"] = "armenian";
    Enum_Translation_Locale["Bengali"] = "bengali";
    Enum_Translation_Locale["Chinese"] = "chinese";
    Enum_Translation_Locale["Croatian"] = "croatian";
    Enum_Translation_Locale["English"] = "english";
    Enum_Translation_Locale["French"] = "french";
    Enum_Translation_Locale["German"] = "german";
    Enum_Translation_Locale["Greek"] = "greek";
    Enum_Translation_Locale["Gujarati"] = "gujarati";
    Enum_Translation_Locale["HaitianCreole"] = "haitian_creole";
    Enum_Translation_Locale["Hebrew"] = "hebrew";
    Enum_Translation_Locale["Hindi"] = "hindi";
    Enum_Translation_Locale["Hmong"] = "hmong";
    Enum_Translation_Locale["Italian"] = "italian";
    Enum_Translation_Locale["Japanese"] = "japanese";
    Enum_Translation_Locale["Khmer"] = "khmer";
    Enum_Translation_Locale["Korean"] = "korean";
    Enum_Translation_Locale["Persian"] = "persian";
    Enum_Translation_Locale["Polish"] = "polish";
    Enum_Translation_Locale["Portuguese"] = "portuguese";
    Enum_Translation_Locale["Punjabi"] = "punjabi";
    Enum_Translation_Locale["Russian"] = "russian";
    Enum_Translation_Locale["Spanish"] = "spanish";
    Enum_Translation_Locale["Tagalog"] = "tagalog";
    Enum_Translation_Locale["Tamil"] = "tamil";
    Enum_Translation_Locale["Telugu"] = "telugu";
    Enum_Translation_Locale["Thai"] = "thai";
    Enum_Translation_Locale["Urdu"] = "urdu";
    Enum_Translation_Locale["Vietnamese"] = "vietnamese";
    Enum_Translation_Locale["Yiddish"] = "yiddish";
})(Enum_Translation_Locale || (Enum_Translation_Locale = {}));
export var Enum_Translation_Status;
(function (Enum_Translation_Status) {
    Enum_Translation_Status["DoneByApi"] = "doneByApi";
    Enum_Translation_Status["Required"] = "required";
})(Enum_Translation_Status || (Enum_Translation_Status = {}));
export var Enum_Translation_Type;
(function (Enum_Translation_Type) {
    Enum_Translation_Type["Doctor"] = "doctor";
    Enum_Translation_Type["Patient"] = "patient";
})(Enum_Translation_Type || (Enum_Translation_Type = {}));
export var Platform;
(function (Platform) {
    Platform["Android"] = "android";
    Platform["Ios"] = "ios";
    Platform["Web"] = "web";
})(Platform || (Platform = {}));
export const UserFragmentDoc = gql `
    fragment User on UsersPermissionsUserEntity {
  id
  attributes {
    username
    displayName
    profileUrl
  }
}
    `;
export const DeploymentFragmentDoc = gql `
    fragment Deployment on DeploymentEntity {
  id
  attributes {
    title
    desc
    uniqId
    creator {
      data {
        ...User
      }
    }
    survey {
      data {
        id
        attributes {
          short_id
        }
      }
    }
    organization {
      data {
        id
      }
    }
    updatedAt
    createdAt
  }
}
    ${UserFragmentDoc}`;
export const OrgFragmentDoc = gql `
    fragment Org on OrganizationEntity {
  id
  attributes {
    name
    slug
  }
}
    `;
export const IssueReportFragmentDoc = gql `
    fragment IssueReport on IssueReportEntity {
  id
  attributes {
    organization {
      data {
        ...Org
      }
    }
    reporter {
      data {
        ...User
      }
    }
    reporting_for
    summary
    details
    url
    attachments
    createdAt
  }
}
    ${OrgFragmentDoc}
${UserFragmentDoc}`;
export const PatientVisitPatientFragmentDoc = gql `
    fragment PatientVisitPatient on PatientVisitEntity {
  id
  attributes {
    surveyState
    firstName
    lastName
    sex
    dob
    visitedAt
    createdAt
    updatedAt
    status
    archived
    patientUpdatedAt
    providerViewedAt
    rootPatientVisitId
    hasAddOnForms
    verifiedPhone
    uuid
    survey_id
    organization_id
  }
}
    `;
export const PatientVisitFragmentDoc = gql `
    fragment PatientVisit on PatientVisitEntity {
  id
  attributes {
    surveyState
    firstName
    lastName
    sex
    dob
    visitedAt
    createdAt
    updatedAt
    status
    archived
    adHocState
    patientUpdatedAt
    providerViewedAt
    rootPatientVisitId
    hasAddOnForms
    verifiedPhone
    survey_id
    organization_id
    meta_data
    uuid
    assign {
      data {
        ...User
      }
    }
    survey {
      data {
        id
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const PatientVisitAdhocStateFragmentDoc = gql `
    fragment PatientVisitAdhocState on PatientVisitEntity {
  id
  attributes {
    adHocState
  }
}
    `;
export const PatientVisitResultsViewFragmentDoc = gql `
    fragment PatientVisitResultsView on PatientVisitEntity {
  id
  attributes {
    surveyState
    firstName
    lastName
    sex
    dob
    visitedAt
    createdAt
    updatedAt
    status
    archived
    patientUpdatedAt
    providerViewedAt
    survey_id
    organization_id
    uuid
    assign {
      data {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const PatientMessageFragmentDoc = gql `
    fragment PatientMessage on PatientMessageEntity {
  id
  attributes {
    organization {
      data {
        ...Org
      }
    }
    createdAt
    messageType
    recipientAddress
    messageContent
    initiator
    uniqueID
    contentType
    opened
    sender {
      data {
        ...User
      }
    }
  }
}
    ${OrgFragmentDoc}
${UserFragmentDoc}`;
export const PatientMessageContentFragmentDoc = gql `
    fragment PatientMessageContent on PatientMessageEntity {
  id
  attributes {
    messageType
    messageContent
    uniqueID
    contentType
  }
}
    `;
export const UploadFileFragmentDoc = gql `
    fragment UploadFile on UploadFileEntity {
  id
  attributes {
    url
    formats
  }
}
    `;
export const QuestionFragmentDoc = gql `
    fragment Question on QuestionEntity {
  id
  attributes {
    answerKeys
    createdAt
    customAnswerLimit
    desc
    descInline
    extNoteRequiredKeys
    maxLength
    maxSelectedOptions
    minSelectedOptions
    optionConditions
    questionKey
    exampleKey
    required
    showConditions
    type
    updatedAt
    enabled
    images {
      data {
        ...UploadFile
      }
    }
    global
    typeOptions
    answerOptionsMap
    systemKey
  }
}
    ${UploadFileFragmentDoc}`;
export const StaffFragmentDoc = gql `
    fragment Staff on StaffEntity {
  id
  attributes {
    role
    jobFunction
    organization {
      data {
        ...Org
      }
    }
  }
}
    ${OrgFragmentDoc}`;
export const UserMemberFragmentDoc = gql `
    fragment UserMember on UsersPermissionsUserEntity {
  id
  attributes {
    username
    displayName
    profileUrl
    email
  }
}
    `;
export const StaffMemberFragmentDoc = gql `
    fragment StaffMember on StaffEntity {
  id
  attributes {
    role
    jobFunction
    user {
      data {
        ...UserMember
      }
    }
    createdAt
  }
}
    ${UserMemberFragmentDoc}`;
export const SurveyMetaFragmentDoc = gql `
    fragment SurveyMeta on SurveyMetaEntity {
  id
  attributes {
    metaKey
    metaType
    metadata
  }
}
    `;
export const SurveyTemplateFragmentDoc = gql `
    fragment SurveyTemplate on SurveyEntity {
  id
  attributes {
    type
    template
  }
}
    `;
export const SurveyThumbPublicFragmentDoc = gql `
    fragment SurveyThumbPublic on SurveyEntity {
  id
  attributes {
    type
    title
    desc
    short_id
    deleted
    metadata
    organization {
      data {
        ...Org
      }
    }
    deployment {
      data {
        id
      }
    }
    questionTreeKeys
    access_restriction
    updatedAt
    createdAt
  }
}
    ${OrgFragmentDoc}`;
export const DeploymentThumbFragmentDoc = gql `
    fragment DeploymentThumb on DeploymentEntity {
  id
  attributes {
    title
    desc
    uniqId
    creator {
      data {
        ...User
      }
    }
    updatedAt
    createdAt
  }
}
    ${UserFragmentDoc}`;
export const SurveyThumbFragmentDoc = gql `
    fragment SurveyThumb on SurveyEntity {
  id
  attributes {
    type
    title
    desc
    short_id
    organization {
      data {
        ...Org
      }
    }
    questionTreeKeys
    creator {
      data {
        ...User
      }
    }
    deployment {
      data {
        ...DeploymentThumb
      }
    }
    updatedAt
    createdAt
  }
}
    ${OrgFragmentDoc}
${UserFragmentDoc}
${DeploymentThumbFragmentDoc}`;
export const SurveyFragmentDoc = gql `
    fragment Survey on SurveyEntity {
  id
  attributes {
    type
    title
    desc
    short_id
    deleted
    metadata
    access_restriction
    organization {
      data {
        ...Org
      }
    }
    deployment {
      data {
        ...DeploymentThumb
      }
    }
    questionTreeKeys
    creator {
      data {
        ...User
      }
    }
    updatedAt
    createdAt
  }
}
    ${OrgFragmentDoc}
${DeploymentThumbFragmentDoc}
${UserFragmentDoc}`;
export const TransCacheFragmentDoc = gql `
    fragment TransCache on TransApiEntity {
  id
  attributes {
    service
    output
    target
    locale
    target_locale
  }
}
    `;
export const TranslationFragmentDoc = gql `
    fragment Translation on TranslationEntity {
  id
  attributes {
    key
    locale
    type
    value
    dataType
  }
}
    `;
export const QuestionTreeFragmentDoc = gql `
    fragment QuestionTree on QuestionTreeEntity {
  id
  attributes {
    treeKey
    entryQuestionKeys
    treeType
    stepNameKey
    enabled
    stageBoundary
    showConditions
  }
}
    `;
export const QuestionTreeTemplateFragmentDoc = gql `
    fragment QuestionTreeTemplate on QuestionTreeEntity {
  id
  attributes {
    treeKey
    template
  }
}
    `;
export const QuestionTreeFullFragmentDoc = gql `
    fragment QuestionTreeFull on QuestionTreeEntity {
  id
  attributes {
    treeKey
    entryQuestionKeys
    treeType
    stepNameKey
    enabled
    template
    stageBoundary
    showConditions
  }
}
    `;
export const UserMeFragmentDoc = gql `
    fragment UserMe on UsersPermissionsMe {
  id
  username
  displayName
  profileUrl
  email
  confirmed
  blocked
  superAdmin
}
    `;
export const CreateDeploymentDocument = gql `
    mutation CreateDeployment($data: DeploymentInput!) {
  createDeployment(data: $data) {
    data {
      ...Deployment
    }
  }
}
    ${DeploymentFragmentDoc}`;
/**
 * __useCreateDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentMutation, { data, loading, error }] = useCreateDeploymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeploymentMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateDeploymentDocument, options);
}
export const DeleteDeploymentDocument = gql `
    mutation DeleteDeployment($id: ID!) {
  deleteDeployment(id: $id) {
    data {
      ...Deployment
    }
  }
}
    ${DeploymentFragmentDoc}`;
/**
 * __useDeleteDeploymentMutation__
 *
 * To run a mutation, you first call `useDeleteDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeploymentMutation, { data, loading, error }] = useDeleteDeploymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeploymentMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteDeploymentDocument, options);
}
export const DeploymentDocument = gql `
    query Deployment($id: ID) {
  deployment(id: $id) {
    data {
      ...Deployment
    }
  }
}
    ${DeploymentFragmentDoc}`;
/**
 * __useDeploymentQuery__
 *
 * To run a query within a React component, call `useDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeploymentQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(DeploymentDocument, options);
}
export function useDeploymentLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(DeploymentDocument, options);
}
export function useDeploymentSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(DeploymentDocument, options);
}
export const DeploymentsDocument = gql `
    query Deployments($filters: DeploymentFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  deployments(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Deployment
    }
  }
}
    ${DeploymentFragmentDoc}`;
/**
 * __useDeploymentsQuery__
 *
 * To run a query within a React component, call `useDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDeploymentsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(DeploymentsDocument, options);
}
export function useDeploymentsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(DeploymentsDocument, options);
}
export function useDeploymentsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(DeploymentsDocument, options);
}
export const PublicRedirectDocument = gql `
    query publicRedirect($uniqId: String!) {
  publicRedirect(uniqId: $uniqId)
}
    `;
/**
 * __usePublicRedirectQuery__
 *
 * To run a query within a React component, call `usePublicRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicRedirectQuery({
 *   variables: {
 *      uniqId: // value for 'uniqId'
 *   },
 * });
 */
export function usePublicRedirectQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PublicRedirectDocument, options);
}
export function usePublicRedirectLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PublicRedirectDocument, options);
}
export function usePublicRedirectSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PublicRedirectDocument, options);
}
export const VersionDocument = gql `
    query version {
  version
}
    `;
/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(VersionDocument, options);
}
export function useVersionLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(VersionDocument, options);
}
export function useVersionSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(VersionDocument, options);
}
export const ExecuteEhrIntegrationDocument = gql `
    mutation ExecuteEHRIntegration($data: EHRIntegrationData!) {
  executeEHRIntegration(data: $data)
}
    `;
/**
 * __useExecuteEhrIntegrationMutation__
 *
 * To run a mutation, you first call `useExecuteEhrIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteEhrIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeEhrIntegrationMutation, { data, loading, error }] = useExecuteEhrIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExecuteEhrIntegrationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ExecuteEhrIntegrationDocument, options);
}
export const UploadDocument = gql `
    mutation upload($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {
  upload(refId: $refId, ref: $ref, field: $field, file: $file, info: $info) {
    data {
      ...UploadFile
    }
  }
}
    ${UploadFileFragmentDoc}`;
/**
 * __useUploadMutation__
 *
 * To run a mutation, you first call `useUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMutation, { data, loading, error }] = useUploadMutation({
 *   variables: {
 *      refId: // value for 'refId'
 *      ref: // value for 'ref'
 *      field: // value for 'field'
 *      info: // value for 'info'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UploadDocument, options);
}
export const AcctInvDocument = gql `
    mutation AcctInv($input: AcctInvInput!) {
  acctInv(input: $input) {
    data {
      id
    }
  }
}
    `;
/**
 * __useAcctInvMutation__
 *
 * To run a mutation, you first call `useAcctInvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcctInvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acctInvMutation, { data, loading, error }] = useAcctInvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcctInvMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AcctInvDocument, options);
}
export const MyInviteDocument = gql `
    query MyInvite($input: MyInviteInput!) {
  myInvite(input: $input) {
    data {
      id
      attributes {
        expireAt
        used
        token
        createdAt
        updatedAt
        invitee {
          data {
            ...User
          }
        }
        organization {
          data {
            ...Org
          }
        }
        inviter {
          data {
            ...User
          }
        }
      }
    }
  }
}
    ${UserFragmentDoc}
${OrgFragmentDoc}`;
/**
 * __useMyInviteQuery__
 *
 * To run a query within a React component, call `useMyInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInviteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyInviteQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MyInviteDocument, options);
}
export function useMyInviteLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MyInviteDocument, options);
}
export function useMyInviteSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(MyInviteDocument, options);
}
export const RetrieveIssueReportDocument = gql `
    query retrieveIssueReport($id: ID!) {
  retrieveIssueReport(id: $id) {
    data {
      ...IssueReport
    }
  }
}
    ${IssueReportFragmentDoc}`;
/**
 * __useRetrieveIssueReportQuery__
 *
 * To run a query within a React component, call `useRetrieveIssueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveIssueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveIssueReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrieveIssueReportQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(RetrieveIssueReportDocument, options);
}
export function useRetrieveIssueReportLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(RetrieveIssueReportDocument, options);
}
export function useRetrieveIssueReportSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(RetrieveIssueReportDocument, options);
}
export const SubmitIssueReportDocument = gql `
    mutation submitIssueReport($orgId: ID!, $data: JSON!) {
  submitIssueReport(orgId: $orgId, data: $data)
}
    `;
/**
 * __useSubmitIssueReportMutation__
 *
 * To run a mutation, you first call `useSubmitIssueReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitIssueReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitIssueReportMutation, { data, loading, error }] = useSubmitIssueReportMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitIssueReportMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SubmitIssueReportDocument, options);
}
export const OrgDocument = gql `
    query Org($id: ID) {
  organization(id: $id) {
    data {
      ...Org
    }
  }
}
    ${OrgFragmentDoc}`;
/**
 * __useOrgQuery__
 *
 * To run a query within a React component, call `useOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(OrgDocument, options);
}
export function useOrgLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(OrgDocument, options);
}
export function useOrgSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(OrgDocument, options);
}
export const ArchivePatientVisitsDocument = gql `
    mutation archivePatientVisits($input: ArchivePatientVisitsInput!) {
  archivePatientVisits(input: $input) {
    ok
  }
}
    `;
/**
 * __useArchivePatientVisitsMutation__
 *
 * To run a mutation, you first call `useArchivePatientVisitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePatientVisitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePatientVisitsMutation, { data, loading, error }] = useArchivePatientVisitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePatientVisitsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ArchivePatientVisitsDocument, options);
}
export const AssignMePatientVisitsDocument = gql `
    mutation AssignMePatientVisits($input: AssignMePatientVisitsInput!) {
  assignMePatientVisits(input: $input) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __useAssignMePatientVisitsMutation__
 *
 * To run a mutation, you first call `useAssignMePatientVisitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMePatientVisitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMePatientVisitsMutation, { data, loading, error }] = useAssignMePatientVisitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignMePatientVisitsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AssignMePatientVisitsDocument, options);
}
export const GeneratePatientVisitReportsDocument = gql `
    query GeneratePatientVisitReports($ids: [ID]!) {
  generatePatientVisitReports(ids: $ids)
}
    `;
/**
 * __useGeneratePatientVisitReportsQuery__
 *
 * To run a query within a React component, call `useGeneratePatientVisitReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePatientVisitReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePatientVisitReportsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGeneratePatientVisitReportsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GeneratePatientVisitReportsDocument, options);
}
export function useGeneratePatientVisitReportsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GeneratePatientVisitReportsDocument, options);
}
export function useGeneratePatientVisitReportsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(GeneratePatientVisitReportsDocument, options);
}
export const MyVisitDocument = gql `
    query MyVisit($input: MyVisitInput!) {
  myVisit(input: $input) {
    patientVisit {
      data {
        ...PatientVisitPatient
      }
    }
    survey {
      data {
        ...SurveyThumbPublic
      }
    }
  }
}
    ${PatientVisitPatientFragmentDoc}
${SurveyThumbPublicFragmentDoc}`;
/**
 * __useMyVisitQuery__
 *
 * To run a query within a React component, call `useMyVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyVisitQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyVisitQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MyVisitDocument, options);
}
export function useMyVisitLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MyVisitDocument, options);
}
export function useMyVisitSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(MyVisitDocument, options);
}
export const PatientAddOnVisitsDocument = gql `
    query PatientAddOnVisits($id: ID!) {
  patientAddOnVisits(id: $id) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __usePatientAddOnVisitsQuery__
 *
 * To run a query within a React component, call `usePatientAddOnVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAddOnVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAddOnVisitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientAddOnVisitsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PatientAddOnVisitsDocument, options);
}
export function usePatientAddOnVisitsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PatientAddOnVisitsDocument, options);
}
export function usePatientAddOnVisitsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PatientAddOnVisitsDocument, options);
}
export const PatientProviderViewDocument = gql `
    query patientProviderView($uuid: String!) {
  patientProviderView(uuid: $uuid) {
    visit {
      data {
        ...PatientVisitPatient
      }
    }
    reports
  }
}
    ${PatientVisitPatientFragmentDoc}`;
/**
 * __usePatientProviderViewQuery__
 *
 * To run a query within a React component, call `usePatientProviderViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientProviderViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientProviderViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePatientProviderViewQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PatientProviderViewDocument, options);
}
export function usePatientProviderViewLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PatientProviderViewDocument, options);
}
export function usePatientProviderViewSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PatientProviderViewDocument, options);
}
export const PatientVisitDocument = gql `
    query PatientVisit($id: ID!, $meta_data: JSON) {
  patientVisit(id: $id, meta_data: $meta_data) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __usePatientVisitQuery__
 *
 * To run a query within a React component, call `usePatientVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientVisitQuery({
 *   variables: {
 *      id: // value for 'id'
 *      meta_data: // value for 'meta_data'
 *   },
 * });
 */
export function usePatientVisitQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PatientVisitDocument, options);
}
export function usePatientVisitLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PatientVisitDocument, options);
}
export function usePatientVisitSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PatientVisitDocument, options);
}
export const PatientVisitsDocument = gql `
    query PatientVisits($filters: PatientVisitFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  patientVisits(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...PatientVisitResultsView
    }
  }
}
    ${PatientVisitResultsViewFragmentDoc}`;
/**
 * __usePatientVisitsQuery__
 *
 * To run a query within a React component, call `usePatientVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientVisitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePatientVisitsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PatientVisitsDocument, options);
}
export function usePatientVisitsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PatientVisitsDocument, options);
}
export function usePatientVisitsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PatientVisitsDocument, options);
}
export const RetrieveAdhocMessagesDocument = gql `
    query retrieveAdhocMessages($uniqueID: String!) {
  retrieveAdhocMessages(uniqueID: $uniqueID) {
    data {
      ...PatientVisitAdhocState
    }
  }
}
    ${PatientVisitAdhocStateFragmentDoc}`;
/**
 * __useRetrieveAdhocMessagesQuery__
 *
 * To run a query within a React component, call `useRetrieveAdhocMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAdhocMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveAdhocMessagesQuery({
 *   variables: {
 *      uniqueID: // value for 'uniqueID'
 *   },
 * });
 */
export function useRetrieveAdhocMessagesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(RetrieveAdhocMessagesDocument, options);
}
export function useRetrieveAdhocMessagesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(RetrieveAdhocMessagesDocument, options);
}
export function useRetrieveAdhocMessagesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(RetrieveAdhocMessagesDocument, options);
}
export const SuggestSymptomsDocument = gql `
    mutation suggestSymptoms($uuid: String!, $possibleSymptoms: [String]!) {
  suggestSymptoms(uuid: $uuid, possibleSymptoms: $possibleSymptoms)
}
    `;
/**
 * __useSuggestSymptomsMutation__
 *
 * To run a mutation, you first call `useSuggestSymptomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestSymptomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestSymptomsMutation, { data, loading, error }] = useSuggestSymptomsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      possibleSymptoms: // value for 'possibleSymptoms'
 *   },
 * });
 */
export function useSuggestSymptomsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SuggestSymptomsDocument, options);
}
export const UpdateAdhocMessagesDocument = gql `
    mutation updateAdhocMessages($id: ID!, $input: PatientVisitInput!) {
  updateAdhocMessages(id: $id, input: $input) {
    data {
      ...PatientVisitAdhocState
    }
  }
}
    ${PatientVisitAdhocStateFragmentDoc}`;
/**
 * __useUpdateAdhocMessagesMutation__
 *
 * To run a mutation, you first call `useUpdateAdhocMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdhocMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdhocMessagesMutation, { data, loading, error }] = useUpdateAdhocMessagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdhocMessagesMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateAdhocMessagesDocument, options);
}
export const UpdatePatientVisitAddAdhocQuestionDocument = gql `
    mutation UpdatePatientVisitAddAdhocQuestion($id: ID!, $data: PatientVisitInput!) {
  updatePatientVisitAddAdhocQuestion(id: $id, data: $data) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __useUpdatePatientVisitAddAdhocQuestionMutation__
 *
 * To run a mutation, you first call `useUpdatePatientVisitAddAdhocQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientVisitAddAdhocQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientVisitAddAdhocQuestionMutation, { data, loading, error }] = useUpdatePatientVisitAddAdhocQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePatientVisitAddAdhocQuestionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdatePatientVisitAddAdhocQuestionDocument, options);
}
export const UpdatePatientVisitByAdminDocument = gql `
    mutation UpdatePatientVisitByAdmin($id: ID!, $data: PatientVisitInput!) {
  updatePatientVisitByAdmin(id: $id, data: $data) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __useUpdatePatientVisitByAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePatientVisitByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientVisitByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientVisitByAdminMutation, { data, loading, error }] = useUpdatePatientVisitByAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePatientVisitByAdminMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdatePatientVisitByAdminDocument, options);
}
export const UpdatePatientVisitUpdateAdhocCommentDocument = gql `
    mutation UpdatePatientVisitUpdateAdhocComment($id: ID!, $data: PatientVisitInput!) {
  updatePatientVisitUpdateAdhocComment(id: $id, data: $data) {
    data {
      ...PatientVisit
    }
  }
}
    ${PatientVisitFragmentDoc}`;
/**
 * __useUpdatePatientVisitUpdateAdhocCommentMutation__
 *
 * To run a mutation, you first call `useUpdatePatientVisitUpdateAdhocCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientVisitUpdateAdhocCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientVisitUpdateAdhocCommentMutation, { data, loading, error }] = useUpdatePatientVisitUpdateAdhocCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePatientVisitUpdateAdhocCommentMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdatePatientVisitUpdateAdhocCommentDocument, options);
}
export const UpdatePatientVisitDocument = gql `
    mutation UpdatePatientVisit($id: ID!, $data: PatientVisitInput!) {
  updatePatientVisit(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
/**
 * __useUpdatePatientVisitMutation__
 *
 * To run a mutation, you first call `useUpdatePatientVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientVisitMutation, { data, loading, error }] = useUpdatePatientVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePatientVisitMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdatePatientVisitDocument, options);
}
export const FetchPatientVisitMessagesDocument = gql `
    query FetchPatientVisitMessages($visitId: ID!, $orgId: ID!) {
  fetchPatientVisitMessages(visitId: $visitId, orgId: $orgId) {
    data {
      ...PatientMessage
    }
  }
}
    ${PatientMessageFragmentDoc}`;
/**
 * __useFetchPatientVisitMessagesQuery__
 *
 * To run a query within a React component, call `useFetchPatientVisitMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientVisitMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientVisitMessagesQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useFetchPatientVisitMessagesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(FetchPatientVisitMessagesDocument, options);
}
export function useFetchPatientVisitMessagesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(FetchPatientVisitMessagesDocument, options);
}
export function useFetchPatientVisitMessagesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(FetchPatientVisitMessagesDocument, options);
}
export const GetPatientNotificationOptionsDocument = gql `
    query getPatientNotificationOptions($orgId: ID!) {
  getPatientNotificationOptions(orgId: $orgId) {
    key
    text
  }
}
    `;
/**
 * __useGetPatientNotificationOptionsQuery__
 *
 * To run a query within a React component, call `useGetPatientNotificationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientNotificationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientNotificationOptionsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetPatientNotificationOptionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPatientNotificationOptionsDocument, options);
}
export function useGetPatientNotificationOptionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPatientNotificationOptionsDocument, options);
}
export function useGetPatientNotificationOptionsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(GetPatientNotificationOptionsDocument, options);
}
export const GetPatientResourceDetailsDocument = gql `
    query getPatientResourceDetails($orgId: ID!, $key: String!, $locale: String!) {
  getPatientResourceDetails(orgId: $orgId, key: $key, locale: $locale) {
    key
    messageText
    resourceUrl
    resourceText
  }
}
    `;
/**
 * __useGetPatientResourceDetailsQuery__
 *
 * To run a query within a React component, call `useGetPatientResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientResourceDetailsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      key: // value for 'key'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPatientResourceDetailsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPatientResourceDetailsDocument, options);
}
export function useGetPatientResourceDetailsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPatientResourceDetailsDocument, options);
}
export function useGetPatientResourceDetailsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(GetPatientResourceDetailsDocument, options);
}
export const GetPatientResourceOptionsDocument = gql `
    query getPatientResourceOptions($orgId: ID!) {
  getPatientResourceOptions(orgId: $orgId) {
    key
    messageText
    note
  }
}
    `;
/**
 * __useGetPatientResourceOptionsQuery__
 *
 * To run a query within a React component, call `useGetPatientResourceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientResourceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientResourceOptionsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetPatientResourceOptionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPatientResourceOptionsDocument, options);
}
export function useGetPatientResourceOptionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPatientResourceOptionsDocument, options);
}
export function useGetPatientResourceOptionsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(GetPatientResourceOptionsDocument, options);
}
export const InitiateWelcomeMessageDocument = gql `
    mutation initiateWelcomeMessage($id: ID!, $data: PatientMessageInput!) {
  initiateWelcomeMessage(id: $id, data: $data) {
    data {
      ...PatientMessage
    }
  }
}
    ${PatientMessageFragmentDoc}`;
/**
 * __useInitiateWelcomeMessageMutation__
 *
 * To run a mutation, you first call `useInitiateWelcomeMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateWelcomeMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateWelcomeMessageMutation, { data, loading, error }] = useInitiateWelcomeMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInitiateWelcomeMessageMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(InitiateWelcomeMessageDocument, options);
}
export const RetrieveMessageDocument = gql `
    query RetrieveMessage($uniqueID: String!) {
  retrieveMessage(uniqueID: $uniqueID) {
    data {
      ...PatientMessageContent
    }
  }
}
    ${PatientMessageContentFragmentDoc}`;
/**
 * __useRetrieveMessageQuery__
 *
 * To run a query within a React component, call `useRetrieveMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveMessageQuery({
 *   variables: {
 *      uniqueID: // value for 'uniqueID'
 *   },
 * });
 */
export function useRetrieveMessageQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(RetrieveMessageDocument, options);
}
export function useRetrieveMessageLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(RetrieveMessageDocument, options);
}
export function useRetrieveMessageSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(RetrieveMessageDocument, options);
}
export const SendAdHocQuestionsDocument = gql `
    mutation sendAdHocQuestions($id: ID!, $data: PatientMessageInput!) {
  sendAdHocQuestions(id: $id, data: $data) {
    data {
      ...PatientMessage
    }
  }
}
    ${PatientMessageFragmentDoc}`;
/**
 * __useSendAdHocQuestionsMutation__
 *
 * To run a mutation, you first call `useSendAdHocQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAdHocQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAdHocQuestionsMutation, { data, loading, error }] = useSendAdHocQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendAdHocQuestionsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SendAdHocQuestionsDocument, options);
}
export const SendAddOnFormDocument = gql `
    mutation SendAddOnForm($rootPatientVisit: ID!, $data: SendAddOnFormInput!) {
  sendAddOnForm(rootPatientVisit: $rootPatientVisit, data: $data) {
    data {
      ...PatientMessage
    }
  }
}
    ${PatientMessageFragmentDoc}`;
/**
 * __useSendAddOnFormMutation__
 *
 * To run a mutation, you first call `useSendAddOnFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAddOnFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAddOnFormMutation, { data, loading, error }] = useSendAddOnFormMutation({
 *   variables: {
 *      rootPatientVisit: // value for 'rootPatientVisit'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendAddOnFormMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SendAddOnFormDocument, options);
}
export const SendPatientNotificationDocument = gql `
    mutation sendPatientNotification($id: ID!, $data: PatientNotificationInput!) {
  sendPatientNotification(id: $id, data: $data) {
    data {
      ...PatientMessage
    }
  }
}
    ${PatientMessageFragmentDoc}`;
/**
 * __useSendPatientNotificationMutation__
 *
 * To run a mutation, you first call `useSendPatientNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientNotificationMutation, { data, loading, error }] = useSendPatientNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendPatientNotificationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SendPatientNotificationDocument, options);
}
export const SendVerificationCodeDocument = gql `
    mutation sendVerificationCode($id: ID!, $data: SendPatientVerificationInput!) {
  sendVerificationCode(id: $id, data: $data)
}
    `;
/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendVerificationCodeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SendVerificationCodeDocument, options);
}
export const ValidateVerificationCodeDocument = gql `
    mutation validateVerificationCode($id: ID!, $data: CheckPatientVerificationInput!) {
  validateVerificationCode(id: $id, data: $data)
}
    `;
/**
 * __useValidateVerificationCodeMutation__
 *
 * To run a mutation, you first call `useValidateVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateVerificationCodeMutation, { data, loading, error }] = useValidateVerificationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidateVerificationCodeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ValidateVerificationCodeDocument, options);
}
export const CopyQsToSurveyDocument = gql `
    mutation CopyQsToSurvey($input: CopyQsToSurveyInput!) {
  copyQsToSurvey(input: $input) {
    ok
    errors
  }
}
    `;
/**
 * __useCopyQsToSurveyMutation__
 *
 * To run a mutation, you first call `useCopyQsToSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyQsToSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyQsToSurveyMutation, { data, loading, error }] = useCopyQsToSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyQsToSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CopyQsToSurveyDocument, options);
}
export const CreateQsDocument = gql `
    mutation CreateQs($data: QuestionInput!) {
  createQuestion(data: $data) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
/**
 * __useCreateQsMutation__
 *
 * To run a mutation, you first call `useCreateQsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQsMutation, { data, loading, error }] = useCreateQsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateQsDocument, options);
}
export const PaQuestionsDocument = gql `
    query PaQuestions($input: PaQuestionsInput!) {
  paQuestions(input: $input) {
    questions {
      data {
        ...Question
      }
    }
    translations {
      data {
        ...Translation
      }
    }
  }
}
    ${QuestionFragmentDoc}
${TranslationFragmentDoc}`;
/**
 * __usePaQuestionsQuery__
 *
 * To run a query within a React component, call `usePaQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaQuestionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PaQuestionsDocument, options);
}
export function usePaQuestionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PaQuestionsDocument, options);
}
export function usePaQuestionsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(PaQuestionsDocument, options);
}
export const QuestionsDocument = gql `
    query Questions($filters: QuestionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionsDocument, options);
}
export function useQuestionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionsDocument, options);
}
export function useQuestionsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionsDocument, options);
}
export const SearchQsDocument = gql `
    query SearchQs($input: SearchQsInput!) {
  searchQs(input: $input) {
    questions {
      data {
        ...Question
      }
    }
    translations {
      data {
        ...Translation
      }
    }
  }
}
    ${QuestionFragmentDoc}
${TranslationFragmentDoc}`;
/**
 * __useSearchQsQuery__
 *
 * To run a query within a React component, call `useSearchQsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SearchQsDocument, options);
}
export function useSearchQsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SearchQsDocument, options);
}
export function useSearchQsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SearchQsDocument, options);
}
export const UpdateQsDocument = gql `
    mutation UpdateQs($id: ID!, $data: QuestionInput!) {
  updateQuestion(id: $id, data: $data) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
/**
 * __useUpdateQsMutation__
 *
 * To run a mutation, you first call `useUpdateQsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQsMutation, { data, loading, error }] = useUpdateQsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateQsDocument, options);
}
export const DeleteStaffDocument = gql `
    mutation DeleteStaff($id: ID!) {
  deleteStaff(id: $id) {
    data {
      ...StaffMember
    }
  }
}
    ${StaffMemberFragmentDoc}`;
/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteStaffDocument, options);
}
export const StaffsDocument = gql `
    query Staffs($filters: StaffFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  staffs(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...StaffMember
    }
  }
}
    ${StaffMemberFragmentDoc}`;
/**
 * __useStaffsQuery__
 *
 * To run a query within a React component, call `useStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useStaffsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(StaffsDocument, options);
}
export function useStaffsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(StaffsDocument, options);
}
export function useStaffsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(StaffsDocument, options);
}
export const UpdateStaffAttributesDocument = gql `
    mutation UpdateStaffAttributes($input: UpdateStaffAttributeInput!) {
  updateStaffAttributes(input: $input) {
    data {
      id
    }
  }
}
    `;
/**
 * __useUpdateStaffAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateStaffAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffAttributesMutation, { data, loading, error }] = useUpdateStaffAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffAttributesMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateStaffAttributesDocument, options);
}
export const CloneSurveyDocument = gql `
    mutation CloneSurvey($input: CloneSurveyInput!) {
  cloneSurvey(input: $input) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useCloneSurveyMutation__
 *
 * To run a mutation, you first call `useCloneSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSurveyMutation, { data, loading, error }] = useCloneSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CloneSurveyDocument, options);
}
export const CreateSurveyMetaDocument = gql `
    mutation CreateSurveyMeta($data: SurveyMetaInput!) {
  createSurveyMeta(data: $data) {
    data {
      ...SurveyMeta
    }
  }
}
    ${SurveyMetaFragmentDoc}`;
/**
 * __useCreateSurveyMetaMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMetaMutation, { data, loading, error }] = useCreateSurveyMetaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSurveyMetaMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateSurveyMetaDocument, options);
}
export const CreateSurveyDocument = gql `
    mutation CreateSurvey($data: SurveyInput!) {
  createSurvey(data: $data) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateSurveyDocument, options);
}
export const DeleteSurveyDocument = gql `
    mutation DeleteSurvey($id: ID!) {
  deleteSurvey(id: $id) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteSurveyDocument, options);
}
export const ImportSurveyDocument = gql `
    mutation ImportSurvey($input: ImportSurveyInput!) {
  importSurvey(input: $input) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useImportSurveyMutation__
 *
 * To run a mutation, you first call `useImportSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSurveyMutation, { data, loading, error }] = useImportSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ImportSurveyDocument, options);
}
export const SurveyMetasDocument = gql `
    query SurveyMetas($filters: SurveyMetaFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  surveyMetas(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SurveyMeta
    }
  }
}
    ${SurveyMetaFragmentDoc}`;
/**
 * __useSurveyMetasQuery__
 *
 * To run a query within a React component, call `useSurveyMetasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyMetasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyMetasQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSurveyMetasQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SurveyMetasDocument, options);
}
export function useSurveyMetasLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SurveyMetasDocument, options);
}
export function useSurveyMetasSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SurveyMetasDocument, options);
}
export const SurveyTemplateDocument = gql `
    query SurveyTemplate($id: ID) {
  survey(id: $id) {
    data {
      ...SurveyTemplate
    }
  }
}
    ${SurveyTemplateFragmentDoc}`;
/**
 * __useSurveyTemplateQuery__
 *
 * To run a query within a React component, call `useSurveyTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyTemplateQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SurveyTemplateDocument, options);
}
export function useSurveyTemplateLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SurveyTemplateDocument, options);
}
export function useSurveyTemplateSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SurveyTemplateDocument, options);
}
export const SurveyDocument = gql `
    query Survey($id: ID) {
  survey(id: $id) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SurveyDocument, options);
}
export function useSurveyLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SurveyDocument, options);
}
export function useSurveySuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SurveyDocument, options);
}
export const SurveysDocument = gql `
    query Surveys($filters: SurveyFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  surveys(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SurveyThumb
    }
  }
}
    ${SurveyThumbFragmentDoc}`;
/**
 * __useSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSurveysQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SurveysDocument, options);
}
export function useSurveysLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SurveysDocument, options);
}
export function useSurveysSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SurveysDocument, options);
}
export const TransSurveyV2Document = gql `
    mutation TransSurveyV2($id: ID!, $locales: [ENUM_TRANSAPI_LOCALE!]) {
  transSurveyV2(id: $id, locales: $locales)
}
    `;
/**
 * __useTransSurveyV2Mutation__
 *
 * To run a mutation, you first call `useTransSurveyV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransSurveyV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transSurveyV2Mutation, { data, loading, error }] = useTransSurveyV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      locales: // value for 'locales'
 *   },
 * });
 */
export function useTransSurveyV2Mutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(TransSurveyV2Document, options);
}
export const UpdateSurveyMetaDocument = gql `
    mutation UpdateSurveyMeta($id: ID!, $data: SurveyMetaInput!) {
  updateSurveyMeta(id: $id, data: $data) {
    data {
      ...SurveyMeta
    }
  }
}
    ${SurveyMetaFragmentDoc}`;
/**
 * __useUpdateSurveyMetaMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMetaMutation, { data, loading, error }] = useUpdateSurveyMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSurveyMetaMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSurveyMetaDocument, options);
}
export const UpdateSurveyTemplateDocument = gql `
    mutation UpdateSurveyTemplate($id: ID!, $data: SurveyInput!) {
  updateSurvey(id: $id, data: $data) {
    data {
      ...SurveyTemplate
    }
  }
}
    ${SurveyTemplateFragmentDoc}`;
/**
 * __useUpdateSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateMutation, { data, loading, error }] = useUpdateSurveyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSurveyTemplateMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSurveyTemplateDocument, options);
}
export const UpdateSurveyDocument = gql `
    mutation UpdateSurvey($id: ID!, $data: SurveyInput!) {
  updateSurvey(id: $id, data: $data) {
    data {
      ...Survey
    }
  }
}
    ${SurveyFragmentDoc}`;
/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSurveyDocument, options);
}
export const CreateTransDocument = gql `
    mutation CreateTrans($data: TranslationInput!) {
  createTranslation(data: $data) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useCreateTransMutation__
 *
 * To run a mutation, you first call `useCreateTransMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransMutation, { data, loading, error }] = useCreateTransMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTransMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateTransDocument, options);
}
export const DeleteTransDocument = gql `
    mutation DeleteTrans($id: ID!) {
  deleteTranslation(id: $id) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useDeleteTransMutation__
 *
 * To run a mutation, you first call `useDeleteTransMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransMutation, { data, loading, error }] = useDeleteTransMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteTransDocument, options);
}
export const ExportTranslationsForReviewDocument = gql `
    query exportTranslationsForReview($survey: ID!, $options: JSON) {
  exportTranslationsForReview(survey: $survey, options: $options)
}
    `;
/**
 * __useExportTranslationsForReviewQuery__
 *
 * To run a query within a React component, call `useExportTranslationsForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTranslationsForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTranslationsForReviewQuery({
 *   variables: {
 *      survey: // value for 'survey'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useExportTranslationsForReviewQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ExportTranslationsForReviewDocument, options);
}
export function useExportTranslationsForReviewLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ExportTranslationsForReviewDocument, options);
}
export function useExportTranslationsForReviewSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ExportTranslationsForReviewDocument, options);
}
export const ImportTranslationsReviewDocument = gql `
    mutation importTranslationsReview($survey: ID!, $data: JSON!, $options: JSON) {
  importTranslationsReview(survey: $survey, data: $data, options: $options)
}
    `;
/**
 * __useImportTranslationsReviewMutation__
 *
 * To run a mutation, you first call `useImportTranslationsReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTranslationsReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTranslationsReviewMutation, { data, loading, error }] = useImportTranslationsReviewMutation({
 *   variables: {
 *      survey: // value for 'survey'
 *      data: // value for 'data'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useImportTranslationsReviewMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ImportTranslationsReviewDocument, options);
}
export const TermsForQsKeysDocument = gql `
    query TermsForQsKeys($input: TermsForQsKeysInput!) {
  termsForQsKeys(input: $input) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useTermsForQsKeysQuery__
 *
 * To run a query within a React component, call `useTermsForQsKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsForQsKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsForQsKeysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTermsForQsKeysQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TermsForQsKeysDocument, options);
}
export function useTermsForQsKeysLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TermsForQsKeysDocument, options);
}
export function useTermsForQsKeysSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TermsForQsKeysDocument, options);
}
export const TransByApiDocument = gql `
    query TransByAPI($input: TransByAPIInput) {
  transByAPI(input: $input) {
    data {
      ...TransCache
    }
  }
}
    ${TransCacheFragmentDoc}`;
/**
 * __useTransByApiQuery__
 *
 * To run a query within a React component, call `useTransByApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransByApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransByApiQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransByApiQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TransByApiDocument, options);
}
export function useTransByApiLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TransByApiDocument, options);
}
export function useTransByApiSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TransByApiDocument, options);
}
export const TransForSxTemplateDocument = gql `
    query TransForSxTemplate($input: SurveyKeysInput!) {
  transForSxTemplate(input: $input) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useTransForSxTemplateQuery__
 *
 * To run a query within a React component, call `useTransForSxTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransForSxTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransForSxTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransForSxTemplateQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TransForSxTemplateDocument, options);
}
export function useTransForSxTemplateLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TransForSxTemplateDocument, options);
}
export function useTransForSxTemplateSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TransForSxTemplateDocument, options);
}
export const TransForTreesDocument = gql `
    query TransForTrees($input: TransForTreesInput!) {
  transForTrees(input: $input) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useTransForTreesQuery__
 *
 * To run a query within a React component, call `useTransForTreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransForTreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransForTreesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransForTreesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TransForTreesDocument, options);
}
export function useTransForTreesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TransForTreesDocument, options);
}
export function useTransForTreesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TransForTreesDocument, options);
}
export const TranslationsDocument = gql `
    query Translations($filters: TranslationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  translations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useTranslationsQuery__
 *
 * To run a query within a React component, call `useTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTranslationsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TranslationsDocument, options);
}
export function useTranslationsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TranslationsDocument, options);
}
export function useTranslationsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TranslationsDocument, options);
}
export const UpdateTransDocument = gql `
    mutation UpdateTrans($id: ID!, $data: TranslationInput!) {
  updateTranslation(id: $id, data: $data) {
    data {
      ...Translation
    }
  }
}
    ${TranslationFragmentDoc}`;
/**
 * __useUpdateTransMutation__
 *
 * To run a mutation, you first call `useUpdateTransMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransMutation, { data, loading, error }] = useUpdateTransMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTransMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateTransDocument, options);
}
export const UpsertTransListDocument = gql `
    mutation UpsertTransList($input: UpsertTransListInput!) {
  upsertTransList(input: $input) {
    ok
    errors
  }
}
    `;
/**
 * __useUpsertTransListMutation__
 *
 * To run a mutation, you first call `useUpsertTransListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTransListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTransListMutation, { data, loading, error }] = useUpsertTransListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTransListMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpsertTransListDocument, options);
}
export const UserNotesTransApisDocument = gql `
    mutation UserNotesTransApis($input: UserNoteTransInput!) {
  userNotesTransApis(input: $input) {
    data {
      ...TransCache
    }
  }
}
    ${TransCacheFragmentDoc}`;
/**
 * __useUserNotesTransApisMutation__
 *
 * To run a mutation, you first call `useUserNotesTransApisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserNotesTransApisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userNotesTransApisMutation, { data, loading, error }] = useUserNotesTransApisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserNotesTransApisMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UserNotesTransApisDocument, options);
}
export const CopyTreesToSurveyDocument = gql `
    mutation CopyTreesToSurvey($input: CopyTreesToSurveyInput!) {
  copyTreesToSurvey(input: $input) {
    ok
    errors
  }
}
    `;
/**
 * __useCopyTreesToSurveyMutation__
 *
 * To run a mutation, you first call `useCopyTreesToSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTreesToSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTreesToSurveyMutation, { data, loading, error }] = useCopyTreesToSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTreesToSurveyMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CopyTreesToSurveyDocument, options);
}
export const CreateQsTreeDocument = gql `
    mutation CreateQsTree($data: QuestionTreeInput!) {
  createQuestionTree(data: $data) {
    data {
      ...QuestionTree
    }
  }
}
    ${QuestionTreeFragmentDoc}`;
/**
 * __useCreateQsTreeMutation__
 *
 * To run a mutation, you first call `useCreateQsTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQsTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQsTreeMutation, { data, loading, error }] = useCreateQsTreeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQsTreeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateQsTreeDocument, options);
}
export const DeleteQsTreeDocument = gql `
    mutation DeleteQsTree($id: ID!) {
  deleteQuestionTree(id: $id) {
    data {
      ...QuestionTree
    }
  }
}
    ${QuestionTreeFragmentDoc}`;
/**
 * __useDeleteQsTreeMutation__
 *
 * To run a mutation, you first call `useDeleteQsTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQsTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQsTreeMutation, { data, loading, error }] = useDeleteQsTreeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQsTreeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteQsTreeDocument, options);
}
export const QuestionTreeTemplateDocument = gql `
    query QuestionTreeTemplate($id: ID) {
  questionTree(id: $id) {
    data {
      ...QuestionTreeTemplate
    }
  }
}
    ${QuestionTreeTemplateFragmentDoc}`;
/**
 * __useQuestionTreeTemplateQuery__
 *
 * To run a query within a React component, call `useQuestionTreeTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreeTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreeTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionTreeTemplateQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreeTemplateDocument, options);
}
export function useQuestionTreeTemplateLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreeTemplateDocument, options);
}
export function useQuestionTreeTemplateSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreeTemplateDocument, options);
}
export const QuestionTreeTemplatesDocument = gql `
    query QuestionTreeTemplates($filters: QuestionTreeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questionTrees(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...QuestionTreeTemplate
    }
  }
}
    ${QuestionTreeTemplateFragmentDoc}`;
/**
 * __useQuestionTreeTemplatesQuery__
 *
 * To run a query within a React component, call `useQuestionTreeTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreeTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreeTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionTreeTemplatesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreeTemplatesDocument, options);
}
export function useQuestionTreeTemplatesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreeTemplatesDocument, options);
}
export function useQuestionTreeTemplatesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreeTemplatesDocument, options);
}
export const QuestionTreeDocument = gql `
    query QuestionTree($id: ID) {
  questionTree(id: $id) {
    data {
      ...QuestionTree
    }
  }
}
    ${QuestionTreeFragmentDoc}`;
/**
 * __useQuestionTreeQuery__
 *
 * To run a query within a React component, call `useQuestionTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionTreeQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreeDocument, options);
}
export function useQuestionTreeLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreeDocument, options);
}
export function useQuestionTreeSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreeDocument, options);
}
export const QuestionTreesFullDocument = gql `
    query QuestionTreesFull($filters: QuestionTreeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questionTrees(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...QuestionTreeFull
    }
  }
}
    ${QuestionTreeFullFragmentDoc}`;
/**
 * __useQuestionTreesFullQuery__
 *
 * To run a query within a React component, call `useQuestionTreesFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreesFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreesFullQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionTreesFullQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreesFullDocument, options);
}
export function useQuestionTreesFullLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreesFullDocument, options);
}
export function useQuestionTreesFullSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreesFullDocument, options);
}
export const QuestionTreesWithLocaleDocument = gql `
    query QuestionTreesWithLocale($filters: QuestionTreeFiltersInput, $pagination: PaginationArg, $sort: [String], $transFilters: TranslationFiltersInput, $transPagination: PaginationArg, $transSort: [String]) {
  questionTrees(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...QuestionTree
    }
  }
  translations(
    filters: $transFilters
    pagination: $transPagination
    sort: $transSort
  ) {
    data {
      ...Translation
    }
  }
}
    ${QuestionTreeFragmentDoc}
${TranslationFragmentDoc}`;
/**
 * __useQuestionTreesWithLocaleQuery__
 *
 * To run a query within a React component, call `useQuestionTreesWithLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreesWithLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreesWithLocaleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      transFilters: // value for 'transFilters'
 *      transPagination: // value for 'transPagination'
 *      transSort: // value for 'transSort'
 *   },
 * });
 */
export function useQuestionTreesWithLocaleQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreesWithLocaleDocument, options);
}
export function useQuestionTreesWithLocaleLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreesWithLocaleDocument, options);
}
export function useQuestionTreesWithLocaleSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreesWithLocaleDocument, options);
}
export const QuestionTreesDocument = gql `
    query QuestionTrees($filters: QuestionTreeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questionTrees(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...QuestionTree
    }
  }
}
    ${QuestionTreeFragmentDoc}`;
/**
 * __useQuestionTreesQuery__
 *
 * To run a query within a React component, call `useQuestionTreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionTreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionTreesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionTreesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(QuestionTreesDocument, options);
}
export function useQuestionTreesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(QuestionTreesDocument, options);
}
export function useQuestionTreesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(QuestionTreesDocument, options);
}
export const UpdateQsTreeDocument = gql `
    mutation UpdateQsTree($id: ID!, $data: QuestionTreeInput!) {
  updateQuestionTree(id: $id, data: $data) {
    data {
      ...QuestionTree
    }
  }
}
    ${QuestionTreeFragmentDoc}`;
/**
 * __useUpdateQsTreeMutation__
 *
 * To run a mutation, you first call `useUpdateQsTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQsTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQsTreeMutation, { data, loading, error }] = useUpdateQsTreeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQsTreeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateQsTreeDocument, options);
}
export const UpdateSxTemplateDocument = gql `
    mutation UpdateSxTemplate($id: ID!, $data: QuestionTreeInput!) {
  updateQuestionTree(id: $id, data: $data) {
    data {
      ...QuestionTreeTemplate
    }
  }
}
    ${QuestionTreeTemplateFragmentDoc}`;
/**
 * __useUpdateSxTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSxTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSxTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSxTemplateMutation, { data, loading, error }] = useUpdateSxTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSxTemplateMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSxTemplateDocument, options);
}
export const ConnectAuthDocument = gql `
    mutation ConnectAuth($type: String!, $code: String!, $state: String, $username: String, $platform: Platform!) {
  connect(
    input: {type: $type, state: $state, code: $code, username: $username, platform: $platform}
  ) {
    jwt
    user {
      ...UserMe
    }
    staffs {
      data {
        ...Staff
      }
    }
    orgConfigurations {
      orgId
      key
      values
    }
  }
}
    ${UserMeFragmentDoc}
${StaffFragmentDoc}`;
/**
 * __useConnectAuthMutation__
 *
 * To run a mutation, you first call `useConnectAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAuthMutation, { data, loading, error }] = useConnectAuthMutation({
 *   variables: {
 *      type: // value for 'type'
 *      code: // value for 'code'
 *      state: // value for 'state'
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useConnectAuthMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ConnectAuthDocument, options);
}
export const DeleteMeDocument = gql `
    mutation DeleteMe($id: ID!) {
  deleteMe(id: $id) {
    success
  }
}
    `;
/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteMeDocument, options);
}
export const ForgotPasswordDocument = gql `
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ForgotPasswordDocument, options);
}
export const HelloDocument = gql `
    query Hello {
  hello {
    text
  }
}
    `;
/**
 * __useHelloQuery__
 *
 * To run a query within a React component, call `useHelloQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(HelloDocument, options);
}
export function useHelloLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(HelloDocument, options);
}
export function useHelloSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(HelloDocument, options);
}
export const InviteDocument = gql `
    mutation Invite($input: InviteInput!) {
  invite(input: $input) {
    invitees {
      data {
        id
      }
    }
  }
}
    `;
/**
 * __useInviteMutation__
 *
 * To run a mutation, you first call `useInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMutation, { data, loading, error }] = useInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(InviteDocument, options);
}
export const LoginDocument = gql `
    mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
  }
}
    `;
/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(LoginDocument, options);
}
export const LogoutDocument = gql `
    mutation logout {
  logout {
    success
  }
}
    `;
/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(LogoutDocument, options);
}
export const MeDocument = gql `
    query Me {
  meV2 {
    jwt
    user {
      ...UserMe
    }
    staffs {
      data {
        ...Staff
      }
    }
    orgConfigurations {
      orgId
      key
      values
    }
  }
}
    ${UserMeFragmentDoc}
${StaffFragmentDoc}`;
/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MeDocument, options);
}
export function useMeLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(MeDocument, options);
}
export const RefreshTokenDocument = gql `
    mutation RefreshToken {
  refreshToken(input: {renew: true}) {
    jwt
  }
}
    `;
/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RefreshTokenDocument, options);
}
export const ResetPasswordDocument = gql `
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    code: $code
  ) {
    jwt
  }
}
    `;
/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ResetPasswordDocument, options);
}
export const UpdateDisplayNameDocument = gql `
    mutation UpdateDisplayName($id: ID!, $displayName: String!) {
  updateUsersPermissionsUser(id: $id, data: {displayName: $displayName}) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
/**
 * __useUpdateDisplayNameMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayNameMutation, { data, loading, error }] = useUpdateDisplayNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpdateDisplayNameMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateDisplayNameDocument, options);
}
export const UpdateMeDocument = gql `
    mutation UpdateMe($id: ID!, $data: UsersPermissionsUserInput!) {
  updateMe(id: $id, data: $data) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateMeDocument, options);
}
export const UpdateUserDocument = gql `
    mutation UpdateUser($id: ID!, $username: String!) {
  updateUsersPermissionsUser(id: $id, data: {username: $username}) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateUserDocument, options);
}
export const UpdateProfilePhotoDocument = gql `
    mutation UpdateProfilePhoto($id: ID!, $profileUrl: String!) {
  updateUsersPermissionsUser(id: $id, data: {profileUrl: $profileUrl}) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
/**
 * __useUpdateProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePhotoMutation, { data, loading, error }] = useUpdateProfilePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profileUrl: // value for 'profileUrl'
 *   },
 * });
 */
export function useUpdateProfilePhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateProfilePhotoDocument, options);
}
export const UserDocument = gql `
    query User($username: String!) {
  userByUsername(username: $username) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserDocument, options);
}
export function useUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserDocument, options);
}
export function useUserSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(UserDocument, options);
}
export const UsernameAvailableDocument = gql `
    query UsernameAvailable($id: ID!, $username: String!) {
  usernameAvailable(id: $id, username: $username) {
    username
    available
  }
}
    `;
/**
 * __useUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailableQuery({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameAvailableQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UsernameAvailableDocument, options);
}
export function useUsernameAvailableLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UsernameAvailableDocument, options);
}
export function useUsernameAvailableSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(UsernameAvailableDocument, options);
}
export const UsersDocument = gql `
    query Users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UsersDocument, options);
}
export function useUsersSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(UsersDocument, options);
}
