import { focusWithId, focusWithIdTimeOut } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { difference, keys, uniq } from 'lodash';
import { memo, useRef, useState } from 'react';
import ReactSelect, { OptionProps } from 'react-select';
import { getMutualExclusiveAnswerKeys } from '../../../utils/models/question';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';
import { Keys } from '../../patient-types';
import QsTextInput from './QsTextInput';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';

type OptionT = { value: string; label: string; mutExcl: boolean };

const Option = (props: OptionProps<OptionT>) => {
  const { setValue, getValue, data, isSelected } = props;
  const answerKey = data.value;
  const options = getValue();

  return (
    <div
      key={data.value}
      className='t_QsInputSelector cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'
      onClick={() => {
        const nOptions = isSelected
          ? options.filter((o) => o.value !== data.value)
          : [...options, data];

        if (data.mutExcl) {
          setValue([data], 'select-option', data);
        } else {
          setValue(
            nOptions.filter((o) => !o.mutExcl),
            !isSelected ? 'select-option' : 'deselect-option',
            data
          );
        }
      }}
    >
      <div className='flex-1 w-full flex-row gap-4'>
        {/* <components.Option {...props} /> */}
        <input
          value={answerKey}
          type='checkbox'
          checked={isSelected}
          className={'checkbox checkbox-lg checkbox-primary'}
        />
        <span className='pt-[0.2rem]'>{data.label}</span>
      </div>
    </div>
  );
};

function QsInputSelector(props: QuestionProps) {
  const { updateValue, locale, question, updateAnswer, treeState } = props;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  // console.log('treeState', treeState);

  const [openSelect, setOpenSelect] = useState(false);

  if (!question) {
    return null;
  }

  const { questionKey, answerKeys } = question;

  const answerOptionsMap = question?.answerOptionsMap || {};
  const { answerMap } = treeState || {};

  const single = !question?.typeOptions?.multi;
  const multi = !single;

  const qAnswerMap = answerMap?.[questionKey];
  const { notesForKeys } = qAnswerMap || {};
  const selectedKeys = qAnswerMap?.answerKeys || [];
  // const selectedKeysRequireNote =

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });

  const options =
    answerKeys?.map((k) => ({
      value: k,
      label: t(k) || '',
      mutExcl: mutExcAnswerKeys.includes(k),
    })) || [];

  // const isLongOptions = size(answerKeys) > 4;
  // console.log('isLongOptions', isLongOptions);

  return (
    <>
      <div ref={ref} className='flex-col gap-2'>
        {multi && (
          <>
            <ReactSelect
              className='basic-single'
              classNamePrefix='select'
              onBlur={() => {
                setOpenSelect(false);
              }}
              onFocus={() => {
                setOpenSelect(true);
              }}
              isClearable={false}
              isSearchable={false}
              isMulti
              name='color'
              options={options}
              menuIsOpen={openSelect}
              hideSelectedOptions={false}
              components={{ Option }}
              value={options.filter((o) => selectedKeys.includes(o.value))}
              styles={{
                multiValue: (base) => ({
                  ...base,
                  display: openSelect ? 'flex' : 'none',
                }),
                option(base, iProps) {
                  const { isSelected } = iProps;
                  // eslint-disable-next-line
                  console.log('isSelected', isSelected);

                  return {
                    ...base,
                  };
                },
                menuList(base) {
                  return {
                    ...base,
                    padding: '0.25rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.3rem',
                  };
                },
              }}
              onChange={(e) => {
                const nKeys = e.map((i) => i.value);

                updateAnswer?.({
                  questionKey,
                  answerKeys: nKeys,
                });
              }}
            />
            <div className='flex-col gap-1'>
              {selectedKeys.map((answerKey) => {
                const isMutExcl = mutExcAnswerKeys.includes(answerKey);
                const extNoteRequired = answerOptionsMap?.[answerKey]?.requireNote;
                const optionChecked = selectedKeys?.includes(answerKey);
                const showExtNoteInput =
                  optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

                const extNoteId = `${questionKey}-ext-${answerKey}`;

                return (
                  <div key={answerKey}>
                    <label className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'>
                      <div className='flex-1 w-full flex-row gap-4'>
                        <input
                          value={answerKey}
                          type='checkbox'
                          name={questionKey}
                          checked
                          className={'checkbox checkbox-lg checkbox-primary'}
                          onChange={(v) => {
                            if (!updateAnswer) return;

                            const { value, checked } = v.target;

                            let nKeys: Keys = [];

                            if (checked) {
                              if (isMutExcl) {
                                nKeys = uniq([value]);
                              } else {
                                nKeys = uniq([...selectedKeys, value]);
                                nKeys = nKeys?.filter((k) => {
                                  return !mutExcAnswerKeys.includes(k);
                                });
                              }
                            } else {
                              nKeys = selectedKeys.filter((nv) => nv !== value);
                            }

                            // removing ext notes for deleted keys
                            const currKeys = keys(notesForKeys);
                            const nNotesForKeys = produce(notesForKeys, (draft) => {
                              const removingKeys = difference(currKeys, nKeys);
                              removingKeys?.forEach((rk) => {
                                delete draft?.[rk];
                              });
                            });

                            updateAnswer({
                              questionKey,
                              answerKeys: nKeys,
                              notesForKeys: nNotesForKeys,
                            });

                            if (extNoteRequired && v.target.checked && ref.current) {
                              focusWithId(ref.current, extNoteId);
                              focusWithIdTimeOut(ref.current, extNoteId, 400);
                            }
                          }}
                        />
                        <span className='pt-[0.2rem]'>{t(answerKey, {})}</span>
                      </div>

                      {showExtNoteInput && (
                        <label key={`${answerKey}-note`} className='flex flex-col'>
                          <QsTextInput
                            updateValue={updateValue}
                            locale={locale}
                            placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                            value={notesForKeys?.[answerKey] || ''}
                            className={classNames(
                              extNoteId,
                              'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                            )}
                            onUpdate={(value) => {
                              if (!updateAnswer) return;
                              updateAnswer({
                                questionKey,
                                notesForKeys: {
                                  ...notesForKeys,
                                  [answerKey]: value,
                                },
                              });
                            }}
                          />
                        </label>
                      )}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {single && (
          <ReactSelect
            className='basic-single'
            classNamePrefix='select'
            isClearable
            // isRtl={isRtl}
            // isSearchable={isSearchable}
            name='color'
            options={options}
          />
        )}

        {single &&
          answerKeys?.map((answerKey) => {
            const optionChecked = selectedKeys?.includes(answerKey);

            const extNoteRequired = answerOptionsMap?.[answerKey]?.requireNote;
            const showExtNoteInput = optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

            const extNoteId = `${questionKey}-ext-${answerKey}`;

            return (
              <div key={answerKey}>
                <label
                  key={answerKey}
                  className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'
                >
                  <div className='flex-1 w-full flex-row gap-4 items-start'>
                    <input
                      id={answerKey}
                      key={answerKey}
                      value={answerKey}
                      type='radio'
                      checked={optionChecked}
                      className={'radio radio-lg radio-primary'}
                      onChange={(v) => {
                        if (!updateAnswer) return;
                        updateAnswer({
                          questionKey,
                          answerKeys: [v.target.value],
                        });

                        if (extNoteRequired && v.target.checked && ref.current) {
                          focusWithId(ref.current, extNoteId);
                          focusWithIdTimeOut(ref.current, extNoteId, 400);
                        }
                      }}
                    />
                    <span className='pt-[0.2rem]'>{t(answerKey)}</span>
                  </div>

                  {showExtNoteInput && (
                    <label key={`${answerKey}-note`} className='flex flex-col'>
                      <QsTextInput
                        updateValue={updateValue}
                        locale={locale}
                        placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                        value={notesForKeys?.[answerKey] || ''}
                        className={classNames(
                          extNoteId,
                          'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                        )}
                        onUpdate={(value) => {
                          if (!updateAnswer) return;
                          updateAnswer({
                            questionKey,
                            notesForKeys: {
                              ...notesForKeys,
                              [answerKey]: value,
                            },
                          });
                        }}
                      />
                    </label>
                  )}
                </label>

                <div className={classNames('qs-item-trashcan', `h-0 w-0 overflow-hidden`)}></div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default memo(QsInputSelector);
