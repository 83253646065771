import { QuestionProps, UpdateAnswerHandler } from '../../pa-prop-types';

import { InputHTMLAttributes, memo } from 'react';
import { AnswerValue, QuestionType } from '../../patient-types';
import { useQuestion } from '../../tree/use-question';
import QsInputBodySelectQs from './QsInputBodySelectQs';
import QsInputBodySelectTree from './QsInputBodySelectTree';
import './body-select/body-select.scss';

function QsInputBodySelect(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      question: QuestionType;
      answerValue?: AnswerValue;
      updateAnswer?: UpdateAnswerHandler;
    }
) {
  const { question } = props;
  const { isBodySelect } = useQuestion(question);
  const isTreeView = isBodySelect;

  // MAIN BODY SELECT TREE RENDERING
  if (isTreeView) {
    return <QsInputBodySelectTree {...props} />;
  }

  // NESTED BODY SELECT QS RENDERING
  return <QsInputBodySelectQs {...props} />;
}

export default memo(QsInputBodySelect);
