import { Lottie } from '@crello/react-lottie';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import * as animationData from '../assets/logos/Loader_80.json';

export default function LoadingAnim({
  className,
  width,
  height,
}: HTMLAttributes<HTMLDivElement> & {
  width?: number;
  height?: number;
}) {
  return (
    <div className={classNames('flex-center', className)}>
      <Lottie
        config={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={`${width || 40}px`}
        width={`${height || width || 40}px`}
      />
    </div>
  );
}
