import { useForm } from 'react-hook-form';
import demoEmr from './assets/demoEmr.svg';
import demoEmr2 from './assets/demoEmr2.svg';
import demoEmr3 from './assets/demoEmr3.svg';

import './demo-emr.scss';

function DemoEmr2() {
  const { register, reset } = useForm<{
    firstName?: string;
    lastName?: string;
    sex?: string;
    age?: string;
    visited: boolean;
    middleName?: string;
    note?: string;
  }>({
    defaultValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      sex: '',
      visited: false,
      note: '',
      age: '',
    },
  });

  return (
    <div className=''>
      <div
        onClick={() => {
          reset();
        }}
      >
        <div className='emr1'>
          <img src={demoEmr} className='w-full' />
        </div>
        <div className='emr2'>
          <img src={demoEmr2} className='w-full' />
        </div>
        <div className='emr3'>
          <img src={demoEmr3} className='w-full' />
        </div>
      </div>
      <div className='p-8'>
        <div className='flex-1 flex-col gap-4 overflow-auto'>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>First name</span>
              </label>
              <input type='text' className='input input-bordered' {...register('firstName')} />
            </div>
          </div>

          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Middle name</span>
              </label>
              <input type='text' className='input input-bordered' {...register('middleName')} />
            </div>
          </div>

          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Last name</span>
              </label>
              <input type='text' className='input input-bordered' {...register('lastName')} />
            </div>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Sex</span>
              </label>
              <input type='text' className='input input-bordered' {...register('sex')} />
            </div>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Visited before</span>
              </label>
              <input type='checkbox' className='checkbox' {...register('visited')} />
            </div>
          </div>

          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Note</span>
              </label>
              <textarea className='textarea textarea-bordered h-20' {...register('note')} />
            </div>
          </div>

          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Age</span>
              </label>
              <input type='text' className='input input-bordered' {...register('age')} />
            </div>
          </div>
          <div>... more fields to come</div>
        </div>
      </div>
    </div>
  );
}

export default DemoEmr2;
