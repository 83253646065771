import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useStaticLocales } from '../admin/hooks/locales/use-static-locale';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  cancel?: string;
  ok?: string;
  onClose: (result: boolean) => void;
}
const handleClickInsideDialog = (event: React.MouseEvent) => {
  event.stopPropagation(); // Prevent event propagation to parent elements
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, title, message, cancel, ok }) => {

  const { sltStr } = useStaticLocales();

  return (
    <Dialog open={open} onClose={() => onClose(false)} onClick={handleClickInsideDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)}>{cancel || sltStr({ key: 'btn-cancel' })}</Button>
        <Button variant="contained" onClick={() => onClose(true)} color="primary">
          {ok || sltStr({ key: 'btn-ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
