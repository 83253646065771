import {
  fetchPmhxQuestions,
  fetchRosQuestions,
  fetchSdohQuestions,
  fetchSxQuestions,
  fetchTriageQuestions,
} from '@cyren/common-lib';
import produce from 'immer';
import { atom, useRecoilState } from 'recoil';
import { flows as flowsRaw, keyHelpers, trees as treesRaw } from './mock-data';
import { translations as translationsRaw } from './mock-data-trans';
import { QuestionTreeType, QuestionType, SurveyType, TranslationType, TreeState } from './types';

export const CsvDataRepoState = atom<{
  trees: QuestionTreeType[] | null;
  questions: QuestionType[] | null;
  translations: TranslationType[] | null;
  flows: SurveyType[] | null;
}>({
  key: 'CsvDataRepoState',
  default: {
    trees: treesRaw,
    // @ts-ignore
    questions: [
      ...fetchTriageQuestions(),
      ...fetchSxQuestions(),
      ...fetchRosQuestions(),
      ...fetchPmhxQuestions(),
      ...fetchSdohQuestions(),
    ],
    translations: translationsRaw,
    flows: flowsRaw,
  },
});

function isNotFalsy<T>(value: T | undefined): value is T {
  if (value == null) {
    return false;
  }

  return true;
}

export function useCsvData() {
  const [dataState, setDataState] = useRecoilState(CsvDataRepoState);
  const { trees, translations, flows, questions } = dataState;

  // eslint-disable-next-line
  // console.log('dataState', dataState);

  function getFlows() {
    return flows;
  }

  function getFlowByKey(key?: string | null) {
    if (!key) return null;

    return flows?.find((flow) => flow.shortId === key) || null;
  }

  function getTrees(searchKey?: string) {
    if (!searchKey) return trees || [];
    return trees?.filter((tree) => tree.treeKey.includes(searchKey)) || [];
  }

  function getTreeByKey(treeKey: string) {
    return trees?.find((tree) => tree.treeKey === treeKey) || null;
  }

  function getQuestionByKey(key: string) {
    return questions?.find((q) => q.questionKey === key);
  }

  function getReportLabel(key?: string) {
    if (!key) return undefined;
    const question = getQuestionByKey(key);
    return question?.reportLabel;
  }

  function getQuestionsByKeys(keys: string[]) {
    return keys.map((key) => getQuestionByKey(key)).filter(isNotFalsy);
  }

  function getTransByKey(key: string) {
    return translations?.find((q) => q.key === key);
  }

  function getTranssByKeys(keys: string[]) {
    return keys.map((key) => getTransByKey(key)).filter(isNotFalsy);
  }

  function updateTree(nTree?: QuestionTreeType | null) {
    if (!nTree) {
      return;
    }

    setDataState((st) => {
      return produce(st, (draft) => {
        draft.trees =
          trees?.map((tree) => {
            if (tree.treeKey === nTree.treeKey) {
              return nTree;
            }
            return tree;
          }) || null;
      });
    });
  }

  // DEPRECATED, no use
  function runKeyHelper({
    treeState,
    answerKeyGenerator,
    question,
  }: {
    treeState: TreeState;
    answerKeyGenerator: string;
    question: QuestionType;
  }) {
    const helperFunc = keyHelpers[answerKeyGenerator];

    if (!helperFunc) {
      return [];
    }

    return helperFunc({ question, treeState });
  }

  // console.log('dataState?.questions', dataState?.questions);
  // console.log('dataState?.translations', dataState?.translations);

  return [
    dataState,
    {
      runKeyHelper,
      updateTree,
      setDataState,
      getFlows,
      getFlowByKey,
      getTreeByKey,
      getTrees,
      getQuestionByKey,
      getReportLabel,
      getTranssByKeys,
      getQuestionsByKeys,
    },
  ] as const;
}
