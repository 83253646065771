import classNames from 'classnames';
import { first, isEmpty, size } from 'lodash';
import { memo, useEffect, useState } from 'react';
import Footer from '../../app/Footer';
import PaSurveyHeader from '../../app/PaSurveyHeader';
import LoadingAnim from '../../components/LoadingAnim';
import Question from '../tree/Question';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import { usePatientTransData } from '../../report/use-patient-trans-data';
import { Enum_Question_Type, Enum_Translation_Locale, useRetrieveAdhocMessagesLazyQuery, useUpdateAdhocMessagesMutation } from '@cyren/common-lib';
import { usePaSurveyParams } from '../hooks/use-survey-state';
import { useNavigate } from 'react-router';
import { AdhocQuestionType } from '../patient-types';
import { convVisit } from '../../gql-tools/utils/convert';
import { UpdateAnswerHandler } from '../pa-prop-types';
import { onError } from '../../utils/apollo-utils';

function PaAdhocHomePage() {

  const [adhocState, setAdhocState] = useState<AdhocQuestionType[]>([]);
  const [visitId, setVisitId] = useState('');

  const [fetchRetrieveAdhocMessages, { loading: loadingAdhocMessages }] = useRetrieveAdhocMessagesLazyQuery({
    onCompleted: () => {

    },
  });

  const params = usePaSurveyParams();
  const { orgId, shortId, adhocId } = params;

  const nav = useNavigate();


  const [updateAdhocQuestion] = useUpdateAdhocMessagesMutation({
    onError,
    onCompleted() {
      nav(`/s/${orgId}/${shortId}/adhoc/${adhocId}/finished`);
    },
  });



  useEffect(() => {
    async function run() {
      const result = await fetchRetrieveAdhocMessages({
        variables: {
          uniqueID: adhocId || ""
        },
      });

      const visit = convVisit(result.data?.retrieveAdhocMessages?.data);
      setAdhocState(visit?.adHocState?.adHocQuestions);
      setVisitId(visit?.id || '');
    }

    run();
  }, [shortId, adhocId]);

  const { slt, sltStr } = useStaticLocales();
  const [{ loadingTrans }] = usePatientTransData({
    keys: [],
  });


  const level = 1;
  const dirty = false;
  const error = false;
  const errorMsgs = null;
  const key = 'adhoc';
  const treeKey = 'adhoc';
  const treeState = null;
  const updateAnswer: UpdateAnswerHandler = (answerParams) => {
    const { questionKey, answerValues } = answerParams;

    const newAdhocState: AdhocQuestionType[] = adhocState.map((i => {
      return (`q-adhoc-text-${i.uniqueId}` === questionKey) ?
        {
          ...i,
          answer: first(answerValues)
        }
        : i;
    }));

    setAdhocState(newAdhocState);
  }


  const updateValue = () => { };
  const tOptions = {};
  const onUiMetadataChange = undefined;
  const locale = 'english' as Enum_Translation_Locale;
  const hasQuestions = size(adhocState) > 0;


  if (loadingTrans || loadingAdhocMessages) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-10 w-full' />
      </div>
    );
  }

  const isValid = hasQuestions && size(adhocState.filter(i => !isEmpty(i.answer))) === size(adhocState);

  return (
    <div className='flex-col flex-1 w-full gap-5 px-5 pt-2'>
      <div className='flex-col flex-1 w-full gap-6'>
        <PaSurveyHeader />

        <div className='text-xl flex-col gap-3'>
          <div className='' />
          <div className='h-1' />

          <h4 className='font-bold sm'>{slt({ key: 'adhoc-title' })}</h4>
          <p className='md text-light'>{slt({ key: hasQuestions ? 'adhoc-subtitle' : 'adhoc-subtitle-noquestions' })}</p>
        </div>

        <div className='flex-col gap-2'>
          {adhocState && adhocState.map(i => {

            const question = {
              answerKeys: [],
              answerOptionsMap: {},
              createdAt: Date.now(),
              customAnswerLimit: null,
              desc: null,
              descInline: null,
              enabled: true,
              exampleKey: null,
              extNoteRequiredKeys: null,
              global: null,
              id: "0",
              images: null,
              optionConditions: [],
              questionKey: `q-adhoc-text-${i.uniqueId}`,
              required: true,
              showConditions: [],
              systemKey: null,
              type: "text" as Enum_Question_Type,
              typeOptions: { max: '1', min: '1' },
              updatedAt: Date.now(),
            }
            const textOverrides = {
              questionText: i.translatedQuestionText || i.questionText,
              inlineDescription: i.translatedQuestionText ? `   [Auto-translated from "${i.questionText}"]` : undefined
            }


            return <Question
              level={level}
              dirty={dirty}
              error={error}
              errorMsgs={errorMsgs}
              key={key + i.uniqueId}
              treeKey={treeKey}
              question={question}
              treeState={treeState}
              updateAnswer={updateAnswer}
              updateValue={updateValue}
              tOptions={tOptions}
              onUiMetadataChange={onUiMetadataChange}
              locale={locale}
              textOverrides={textOverrides}
            />
          })}
        </div>

        <div className='flex-col gap-2'>
          {hasQuestions && <button
            className={classNames('btn btn-lg btn-primary', !isValid && 'btn-disabled')}
            onClick={() => {
              if (!isValid) return;

              updateAdhocQuestion({
                variables: {
                  id: visitId as string,
                  input: {
                    adHocState: {
                      adHocQuestions: adhocState
                    }
                  }
                },
              });
            }}
          >
            {sltStr({ key: 'btn-save', capit: true })}
          </button>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default memo(PaAdhocHomePage);
