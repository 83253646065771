import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router';
import App404Refresh from './App404Refresh';
import DefaultHelmet from './DefaultHelmet';

function RootLayout({ children }: { children?: ReactNode }) {
  return (
    <ErrorBoundary
      FallbackComponent={App404Refresh}
      onError={(e) => {
        // eslint-disable-next-line
        console.log('ErrorBoundary', e);
      }}
    >
      <DefaultHelmet />

      {children || <Outlet />}
    </ErrorBoundary>
  );
}

export default RootLayout;
