import produce from 'immer';
import { difference, first, isEmpty, last, set, truncate, uniq, unset, upperCase, values } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { BsFillPlusCircleFill, BsPlusCircle } from 'react-icons/bs';
import { FaRotate } from "react-icons/fa6";

import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { useOnNavChange } from '../../../../hooks/use-on-nav-change';
import {
  getMainOptionAnswerKeys,
  getRequireNoteAnswerKeys,
} from '../../../../utils/models/question';
import { useErrorState } from '../../../hooks/use-error-state';
import { useTranslations } from '../../../hooks/use-translations';
import { BodySelectorProps } from '../../../pa-prop-types';
import { BodySelectMap, Keys } from '../../../patient-types';
import BodyPartSelectorWholeBodySubpart, {
  BodyPartQsOptionKeys,
  optionToQsMap,
} from './BodyPartSelectorWholeBodySubpart';
import BodyMaleButtons from './buttons/BodyMaleButtons';
import BodyRotateMaleButtons from './buttons/BodyRotateMaleButtons';
import BodyFemaleButtons from './buttons/BodyFemaleButtons';
import BackFemaleButtons from './buttons/BackFemaleButtons';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useStaticLocales } from '../../../../admin/hooks/locales/use-static-locale';

function BodyPartSelectorWholeBody(props: BodySelectorProps) {
  const { question, onValueChange, standalone, answerValue: answerValueInit, locale, isMale } = props;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  const [answerValue, setAnswerValue] = useState(answerValueInit || {});

  const selectedKeys = answerValue.answerKeys || [];

  const uiMeta = answerValueInit?.uiMetadata as BodySelectMap;
  const [selectedMap, setSelectedMap] = useState<BodySelectMap>(uiMeta || {});
  const [selectedStack, setSelectedStack] = useState<Keys>([]);
  const [rotate, setRotate] = useState(false);

  const lastSelectedStackOption = last(selectedStack);

  useErrorState({
    containerKey: 'whole-body',
    resetOnMount: true,
  });

  const stackKeys =
    values(selectedMap)?.reduce((p, i) => {
      return uniq([...p, ...i]);
    }, []) || [];

  const selectedButtonKeys = uniq([...selectedKeys, ...stackKeys]);

  const requireNoteKeys = getRequireNoteAnswerKeys({ question });
  const mainOptionKeys = getMainOptionAnswerKeys({ question });
  const [requireNoteKey, setRequireNoteKey] = useState<string | null>(null);

  const notesForKeys = answerValue?.notesForKeys || {};

  const handleNavChange = useCallback(() => {
    if (!isEmpty(selectedStack)) {
      setSelectedStack((st) =>
        produce(st, (dr) => {
          dr.pop();
        })
      );
    }
  }, [selectedStack, setSelectedStack]);

  useOnNavChange({
    block: true,
    onChange: handleNavChange,
  });

  const questionKeyForLastStack = lastSelectedStackOption
    ? optionToQsMap?.[lastSelectedStackOption as BodyPartQsOptionKeys]
    : undefined;

  function handleClick({ id }: { id: string }) {
    // eslint-disable-next-line
    console.log('id', id);

    const optionToQsKey = optionToQsMap[id as BodyPartQsOptionKeys];

    if (optionToQsKey) {
      setSelectedStack([...selectedStack, id]);
      return;
    }

    const selected = selectedKeys.includes(id);

    let nKeys: Keys = [...selectedKeys];

    if (selected) {
      nKeys = nKeys.filter((i) => i !== id);
    } else {
      nKeys = uniq([...nKeys, id]);
    }

    const nValue = produce(answerValue, (dr) => {
      dr.answerKeys = nKeys;
      dr.uiMetadata = selectedMap;
    });

    setAnswerValue(nValue);
    onValueChange(nValue);

    setSelectedMap((st) =>
      produce(st, (draft) => {
        if (!selected) {
          set(draft, id, selectedStack);
        } else {
          unset(draft, id);
        }
      })
    );
  }

  return (
    <div className='t_BodyPartSelectorWholeBody flex-col overflow-hidden select-none'>
      <div className='flex-col p-4 overflow-hidden'>
        {lastSelectedStackOption ? (
          <>
            {questionKeyForLastStack && (
              <BodyPartSelectorWholeBodySubpart
                {...props}
                stackMapKeys={stackKeys}
                selectedStack={selectedStack}
                setSelectedStack={setSelectedStack}
                optionKey={lastSelectedStackOption as BodyPartQsOptionKeys}
                questionKey={questionKeyForLastStack}
                answerValue={answerValue}
                onValueChange={(n) => {
                  const addingKeys = difference(n.answerKeys || [], answerValue.answerKeys || []);
                  const removingKeys = difference(answerValue.answerKeys || [], n.answerKeys || []);

                  const addingKey = first(addingKeys);
                  const removingKey = first(removingKeys);
                  const adding = !!addingKey;

                  if (addingKey) {
                    const optionToQsKey = optionToQsMap[addingKey as BodyPartQsOptionKeys];

                    if (optionToQsKey) {
                      handleClick({ id: addingKey });
                      return;
                    }
                  }

                  const nMap = produce(selectedMap, (dr) => {
                    if (adding) {
                      dr[addingKey] = selectedStack;
                    }

                    if (removingKey) {
                      delete dr[removingKey];
                    }
                  });
                  setSelectedMap(nMap);
                  const nV = produce(n, (dr) => {
                    dr.uiMetadata = nMap;
                  });

                  setAnswerValue(nV);
                  onValueChange(nV);
                }}
              />
            )}
          </>
        ) : (
          <>
            <div className={classNames('flex-col')}>
              {isNotEmpty(mainOptionKeys) && (
                <div className='flex gap-2 flex-wrap'>
                  {mainOptionKeys?.map((mutKey) => {
                    const isRequireNote = requireNoteKeys.includes(mutKey);
                    const selected = selectedKeys.includes(mutKey);

                    return (
                      <button
                        key={mutKey}
                        className='flex-1 btn-base'
                        onClick={() => {
                          if (!selected) {
                            setRequireNoteKey(null);

                            if (isRequireNote) {
                              setRequireNoteKey(mutKey);
                              return;
                            }
                          }

                          handleClick({ id: mutKey });
                        }}
                      >
                        {selected ? (
                          <BsPlusCircle size={22} className='text-primary rotate-45' />
                        ) : (
                          <BsFillPlusCircleFill size={22} className='text-primary' />
                        )}
                        <span className='text-base'>{upperCase(t(mutKey))}</span>
                      </button>
                    );
                  })}
                </div>
              )}

              {requireNoteKey && (
                <div className='flex-col pt-3 gap-2'>
                  <TextField fullWidth
                    label={sltStr({ key: 'label-area-description', upper: true })}
                    value={notesForKeys?.[requireNoteKey] || ''}
                    onChange={(e) => {
                      const nValue = produce(answerValue || {}, (dr) => {
                        if (!dr.notesForKeys) dr.notesForKeys = {};
                        dr.notesForKeys[requireNoteKey] = e.target.value;
                      });

                      setAnswerValue(nValue);
                    }}
                    autoComplete='off'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            disabled={(!isNotEmpty(answerValue?.notesForKeys?.[requireNoteKey]))}
                            onClick={() => {
                              handleClick({ id: requireNoteKey });
                            }}
                            color="primary"
                          >
                            {sltStr({ key: 'btn-add' })}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            </div>

            <div className='relative max-w-full pt-2 overflow-auto'>
              <div className='py-3'>
                {/* BODY IMAGE */}
                <div className='relative z-10 flex-center'>
                  {isMale &&
                    <>
                      {rotate ? (
                        <BodyRotateMaleButtons
                          selectedKeys={selectedButtonKeys}
                          subQuestionKeys={Object.keys(optionToQsMap)}
                          className='relative left-2'
                          onClick={(e) => {
                            const el = e.target as HTMLElement;
                            const id = el.getAttribute('id');
                            if (id) {
                              // eslint-disable-next-line
                              console.log('id whole', id);

                              handleClick({ id });
                            }
                          }}
                        />
                      ) : (
                        <BodyMaleButtons
                          selectedKeys={selectedButtonKeys}
                          subQuestionKeys={Object.keys(optionToQsMap)}
                          className='relative left-2'
                          onClick={(e) => {
                            const el = e.target as HTMLElement;
                            const id = el.getAttribute('id');
                            if (id) {
                              // eslint-disable-next-line
                              console.log('id whole', id);

                              handleClick({ id });
                            }
                          }}
                        />
                      )
                      }
                    </>
                  }
                  {!isMale &&
                    <>
                      {rotate ? (
                        <BackFemaleButtons
                          selectedKeys={selectedButtonKeys}
                          subQuestionKeys={Object.keys(optionToQsMap)}
                          className='relative left-2'
                          onClick={(e) => {
                            const el = e.target as HTMLElement;
                            const id = el.getAttribute('id');
                            if (id) {
                              // eslint-disable-next-line
                              console.log('id whole', id);

                              handleClick({ id });
                            }
                          }}
                        />
                      ) : (
                        <BodyFemaleButtons
                          selectedKeys={selectedButtonKeys}
                          subQuestionKeys={Object.keys(optionToQsMap)}
                          className='relative left-2'
                          onClick={(e) => {
                            const el = e.target as HTMLElement;
                            const id = el.getAttribute('id');
                            if (id) {
                              // eslint-disable-next-line
                              console.log('id whole', id);

                              handleClick({ id });
                            }
                          }}
                        />
                      )}
                    </>
                  }
                </div>

                <div className='z-0 absolute flex-center top-0 w-full flex justify-between opacity-60'>
                  <span className='p-5 text-sm'>{sltStr({ key: 'label-right', upper: true })}</span>
                  <span className='p-5 text-sm'>{sltStr({ key: 'label-left', upper: true })}</span>
                </div>
              </div>
            </div>
          </>
        )}

        {isEmpty(selectedStack) && (
          <div className='flex-center pt-6'>
            <button
              className='btn'
              onClick={() => {
                setRotate(!rotate);
              }}
            >
              <FaRotate></FaRotate> {sltStr({ key: 'btn-rotate', upper: true })}
            </button>
          </div>
        )}
      </div>

      {!isEmpty(selectedKeys) && (
        <div
          className={classNames('flex-none flex-col pt-4 gap-3 border-t', !standalone && 'px-4')}
        >
          <div className='flex gap-3 flex-wrap'>
            {selectedKeys.map((answerKey) => {
              const note = answerValue?.notesForKeys?.[answerKey];
              return (
                <button
                  key={answerKey}
                  className='btn btn-outline btn-primary border-primary p-2 min-h-9 max-w-full flex gap-2'
                  onClick={() => {
                    handleClick({ id: answerKey });
                  }}
                >
                  <span className='uppercase'>{t(answerKey)}</span>{note ? ` (${truncate(note, {length: 75})})` : null}
                  <MdClose size={16} />
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(BodyPartSelectorWholeBody);
