import { memo } from "react";
import { useReportState } from '../../hooks/use-report';
import { QuestionProps } from '../../pa-prop-types';
import { useTranslations } from '../../hooks/use-translations';
import QsSelectedSymptom from "./components/QsSelectedSymptom";
import QsCollapsibleGroupItem from "./QsCollapsibleGroupItem";
import { difference, includes, size, union } from "lodash";
import LoadingAnim from "../../../components/LoadingAnim";
import { usePatientTreeData } from "../../../report/use-patient-tree-data";
import classNames from "classnames";
import { noneAboveKey } from "../../../report/report-config";
import { useTreeHelpers } from "../../hooks/use-tree-helpers";


function QsInputSxAssociatedCollector(props: QuestionProps) {
  const { locale, question, treeKey } = props;

  const [{ treeState, selectedSxTrees }, { updateAnswer }] =
    useReportState({
      treeKey,
    });

  const [, { getAssociatedSymptomKeys }] = useTreeHelpers({});

  const [, { t }] = useTranslations({ locale });

  const [
    {
      loadingTrees,
    },
  ] = usePatientTreeData();

  if (!question) {
    return <></>;
  }

  if (loadingTrees) {
    return (
      <div className='flex-1 flex-center'>
        <LoadingAnim />
      </div>
    );
  }

  const noMatchOption = noneAboveKey;
  const nonAboveSelected = includes(treeState?.answerMap[question.questionKey]?.answerKeys, noneAboveKey);
  const disableNoMatchOption = size(treeState?.answerMap[question.questionKey]?.answerKeys?.filter(key => key !== noMatchOption)) > 0;

  const associatedSymptomKeys = getAssociatedSymptomKeys(question.typeOptions?.intelligentOnly ? "includeIntelligent" : "excludeIntelligent");
  const selectedAssociatedSymptoms = selectedSxTrees.filter(sxTree => includes(associatedSymptomKeys, sxTree.treeKey));

  if (loadingTrees) {
    return (
      <div className='flex-1 flex-center'>
        <LoadingAnim />
      </div>
    );
  }


  return (
    <div className='t_QsInputSxAssociatedCollector flex-col gap-8'>
      <div className='flex-col gap-3'>
        <div className='flex-col gap-3'>
          {selectedAssociatedSymptoms?.map((sxTree) => {
            return <QsSelectedSymptom key={sxTree.treeKey} sxTree={sxTree} questionProps={{ ...props, suppressSxCollectors: true }} ></QsSelectedSymptom>
          })}

          {associatedSymptomKeys?.map((sxKey) => {
            const isSelected = includes(selectedSxTrees.map(i => i.treeKey), sxKey);
            return <div key={sxKey} className={classNames("border rounded-lg", isSelected && "hidden")}>
              <QsCollapsibleGroupItem
                {...props}
                optionKey={sxKey}
                treeKey={treeKey}
                question={question!}
                suppressSxCollectors
              />
            </div>
          })}
        </div>

        {noMatchOption &&
          <div
            className='border rounded-lg form-control cursor-pointer'
          >
            <label
              className={classNames(
                'label gap-2 px-4 py-3 cursor-pointer',
                nonAboveSelected && ' bg-primary bg-opacity-5 rounded-lg',
                disableNoMatchOption && 'opacity-50'
              )}
            >
              <span className='label-text text-base font-medium'>{t(noMatchOption)}</span>
              <input
                type='checkbox'
                checked={nonAboveSelected}
                disabled={disableNoMatchOption}
                className={classNames(
                  'checkbox checkbox-lg rounded',
                  nonAboveSelected ? 'bg-black bg-opacity-10' : 'checkbox-primary',
                )}
                onChange={(e) => {
                  e.stopPropagation();

                  const currentAnswers = treeState?.answerMap[question.questionKey]?.answerKeys || [];
                  if (e.target.checked) {
                    updateAnswer({
                      questionKey: question.questionKey,
                      answerKeys: union(currentAnswers, [noMatchOption]),
                    });
                  }
                  else {
                    updateAnswer({
                      questionKey: question.questionKey,
                      answerKeys: difference(currentAnswers, [noMatchOption]),
                    });
                  }
                }}
              />
            </label>
          </div>
        }
      </div>
    </div>

  );
}

export default memo(QsInputSxAssociatedCollector);