import {
  Enum_Translation_Datatype,
  Enum_Translation_Type,
  useTranslationsLazyQuery,
} from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { capitalize, isEmpty, trim } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useSetRecoilState } from 'recoil';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import useAppState from '../../../app/use-app-state';
import lens from '../../../assets/ill/lens.svg';
import Modal from '../../../components/Modal';
import { convTrans } from '../../../gql-tools/utils/convert';
import { TTranslation } from '../../../graphql-enhanced';
import { useDebInputState } from '../../../hooks/use-deb-input-state';
import { useOnNavChange } from '../../../hooks/use-on-nav-change';
import { isEqualTrans } from '../../../models/translation';
import { PaDataRepoState } from '../../../report/use-data';
import { useReportState } from '../../hooks/use-report';
import { useSurveyState } from '../../hooks/use-survey-state';
import { QuestionProps } from '../../pa-prop-types';
import QsCollapsible from './QsCollapsible';
import QsCollapsibleGroupItem from './QsCollapsibleGroupItem';
import QsSelectedSymptom from './components/QsSelectedSymptom';
import { useTreeHelpers } from '../../hooks/use-tree-helpers';

function QsInputSxCollector(props: QuestionProps) {
  const { locale, question, treeKey, onUiMetadataChange } = props;
  const [{ isRtl }] = useAppState();
  const { sltStr } = useStaticLocales();

  // eslint-disable-next-line
  // console.log('treeState', treeState);
  // console.log('props', props);
  // console.log('treeKey', treeKey);

  const [{ surveyId }] = useSurveyState();
  const [{ treeState, selectedSxTrees }, { updateAnswer }] =
    useReportState({
      treeKey,
    });
  const [, { getAssociatedSymptomKeys }] = useTreeHelpers({});

  const [openSxSelect, setOpenSxSelect] = useState(false);

  const { input, ref, setInput, debInput } = useDebInputState();
  const setDataState = useSetRecoilState(PaDataRepoState);
  const uiMetadata = treeState?.answerMap[question?.questionKey || ""]?.uiMetadata;

  const [searchResult, setSearchResult] = useState<TTranslation[]>([]);
  const [fetchSxTreeNames, { loading: fetching }] = useTranslationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (resp) => {
      const list = resp?.translations?.data?.map(convTrans) || [];

      setSearchResult(list);

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            const idx = draft.translations.findIndex((trans1) =>
              isEqualTrans({ trans1, trans2: item })
            );

            if (idx > -1) {
              draft.translations[idx] = item;
            } else {
              draft.translations.push(item);
            }
          });
        });
      });
    },
  });

  useEffect(() => {
    if (isEmpty(debInput)) return;

    fetchSxTreeNames({
      variables: {
        filters: {
          value: {
            containsi: debInput,
          },
          locale: {
            eq: locale,
          },
          dataType: {
            eq: Enum_Translation_Datatype.TreeSx,
          },
          survey_id: {
            eq: parseInt(surveyId || "", 10)
          },
          type: {
            eq: Enum_Translation_Type.Patient,
          },
        },
      },
    });
  }, [debInput]);

  useOnNavChange({
    block: openSxSelect,
    onChange: useCallback(() => {
      setOpenSxSelect(false);
    }, [setOpenSxSelect]),
  });

  const suppressSxCollectors = !!question?.typeOptions?.suppressSxCollectors;

  useEffect(() => {
    if (!question || !onUiMetadataChange) {
      return;
    }

    const newAssociatedSymptomKeys = getAssociatedSymptomKeys("excludeIntelligent")
    const currentlyHasAssociatedSymptoms = uiMetadata?.hasAssociatedSymptoms === 'true';
    const currentlyHasSelection = uiMetadata?.hasSelectedSymptoms === 'true';

    if ((isEmpty(newAssociatedSymptomKeys) === currentlyHasAssociatedSymptoms) ||
      (isEmpty(answerKeys) === currentlyHasSelection)) {

      onUiMetadataChange({
        uiMetadata: {
          ...(uiMetadata || {}),
          hasAssociatedSymptoms: isEmpty(newAssociatedSymptomKeys) ? 'false' : 'true',
          hasSelectedSymptoms: isEmpty(answerKeys) ? 'false' : 'true'
        },
        questionKey: question.questionKey
      });
    }

  }, [selectedSxTrees]);

  if (!surveyId || !question) {
    return null;
  }

  const { questionKey } = question;
  const { answerMap } = treeState || {};

  const qAnswerMap = answerMap?.[questionKey];

  const answerValues = qAnswerMap?.answerValues || [];
  const answerKeys = qAnswerMap?.answerKeys || [];
  const firstValue = answerValues?.[0];

  const isUserTexting = !isEmpty(trim(debInput));

  return (
    <div className='t_QsInputSxCollector flex-col gap-3'>
      {/* selected symptoms */}
      <div className='flex-col gap-3'>
        {selectedSxTrees?.map((sxTree) => {
          return <QsSelectedSymptom key={sxTree.treeKey} sxTree={sxTree} questionProps={{ ...props, suppressSxCollectors }}></QsSelectedSymptom>
        })}
      </div>

      <button
        className='btn btn-outline btn-ghost btn-lg'
        onClick={() => {
          setOpenSxSelect(true);
        }}
      >
        <MdAddCircleOutline size={22} />{' '}
        <span className='text-base uppercase'>{sltStr({ key: 'symptoms' })}</span>
      </button>

      {openSxSelect && (
        <Modal open className='p-0 rounded-none overflow-hidden'>
          <div className='flex-col flex-1'>
            <div className='border-b'>
              <div className='relative w-full text-center p-3'>
                <span className='text-sm'>{capitalize(sltStr({ key: 'symptoms' }))}</span>
              </div>

              <div className={classNames('absolute top-1', isRtl ? 'left-1 ' : 'right-1 ')}>
                <button
                  className='btn btn-ghost hover:bg-transparent'
                  onClick={() => {
                    setOpenSxSelect(false);
                  }}
                >
                  <MdClose size={20} />
                </button>
              </div>
            </div>
            <div className='flex-1 flex-col overflow-hidden'>
              <div className='border-b hidden'>
                <div className='form-control'>
                  <div className='flex'>
                    <span className={classNames('flex-none flex-center p-2 px-3 bg-transparent')}>
                      <div
                        className={classNames(
                          'block relative w-0 overflow-visible h-0',
                          !fetching && 'opacity-0'
                        )}
                      >
                        <div className='absolute -top-2'>
                          <span className='loading loading-spinner loading-xs opacity-50'></span>
                        </div>
                      </div>
                      <PiMagnifyingGlass
                        size={18}
                        className={classNames('opacity-60', fetching && 'opacity-0')}
                      />
                    </span>
                    <input
                      type='text'
                      ref={ref}
                      value={input}
                      placeholder='SEARCH'
                      className='flex-1 border-none py-3 pr-2 outline-none text-sm'
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='overflow-auto pb-32'>
                {isUserTexting ? (
                  <>
                    {searchResult?.map((tran) => {
                      return (
                        <div key={tran.key} className='p-2 border-b'>
                          <QsCollapsibleGroupItem
                            {...props}
                            optionKey={tran.key}
                            treeKey={treeKey}
                            question={question}
                          />
                        </div>
                      );
                    })}

                    {isEmpty(searchResult) && (
                      <div className='flex-col'>
                        <div className='flex-center flex-col py-3'>
                          <div>
                            <img src={lens} />
                          </div>
                          <div>No match found</div>
                        </div>
                        <QsCollapsible
                          {...props}
                          locale={locale}
                          treeKey={treeKey}
                          question={question}
                          value={firstValue || ''}
                          // error={error}
                          updateAnswer={updateAnswer}
                          suppressSxCollectors={suppressSxCollectors}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className='mb-10'>
                      <QsCollapsible
                        {...props}
                        locale={locale}
                        treeKey={treeKey}
                        question={question}
                        value={firstValue || ''}
                        // error={error}
                        updateAnswer={updateAnswer}
                        suppressSxCollectors={suppressSxCollectors}
                      />
                    </div>
                  </>
                )}

                {(!isEmpty(selectedSxTrees) || !isEmpty(answerKeys)) && (
                  <>
                    <div className='fixed bottom-8 w-full flex-center'>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          setOpenSxSelect(false);
                        }}
                      >
                        {sltStr({ key: 'btn-done', upper: true })}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default memo(QsInputSxCollector);
