import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { getTitle } from '../common/envs';

function DefaultHelmet() {
  return (
    <Helmet>
      <title>{getTitle()}</title>
    </Helmet>
  );
}

export default memo(DefaultHelmet);
