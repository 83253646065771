import { useRecoilState } from 'recoil';
import { PaReportState } from './use-report';

export function useGlobalPaState() {
  const [reportState, setReportState] = useRecoilState(PaReportState);
  const systemValueMap = reportState.systemValueMap || {};

  const isMale = !(systemValueMap.sex === 'o-female');

  return [{ reportState, isMale }, { setReportState }] as const;
}
