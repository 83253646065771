import { CSSProperties, SVGAttributes } from 'react';
import { Keys } from '../../../../patient-types';

const ButtonRadius = 12;

const BodySelectButton = ({
  selectedKeys,
  subQuestionKeys,
  alternateAnswerKeys,
  d,
  selectStyle,
  ...props
}: SVGAttributes<SVGCircleElement> & {
  selectStyle?: CSSProperties;
  selectedKeys?: Keys;
  subQuestionKeys?: Keys;
  alternateAnswerKeys?: Map<string, string>
  d: string;
}) => {
  const { id } = props;
  const answerId = alternateAnswerKeys?.has(id || "") ? alternateAnswerKeys.get(id || "") : id;

  const selected = id && selectedKeys?.includes(id);

  const fill1 = !selected ? '#3455FC' : '#fff';
  const fill2 = selected ? '#3455FC' : '#fff';

  const nums = d.match(/-?\d+(\.\d+)?/g) || [];

  const num2 = nums[1];
  const num4 = nums[3];

  const transform = !selected && num2 && num4 ? '' : `rotate(45 ${num4} ${num2})`;

  const showDrillDown = !!subQuestionKeys?.find(i => i === id);

  return (
    <g className='cursor-pointer'>
      <circle {...props} id={answerId} r={ButtonRadius} fill={fill1} strokeWidth={1.5} stroke='#3455FC'></circle>
      <circle {...props} id={answerId} r={ButtonRadius + 10} fill={'transparent'} strokeWidth={1.5} />
      {!showDrillDown &&
        <path
          id={answerId}
          stroke={fill2}
          transform={transform}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          style={selected ? selectStyle : {}}
          d={d}
        />
      }
      {/*<!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->*/}
      {showDrillDown &&
        <g transform={props.transform} id={answerId}>
          <g transform='translate(3 3)'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
              fill={fill2} width="16" height="16"

            >
              {/* https://fontawesome.com/icons/magnifying-glass-plus?f=classic&s=light */}
              {/*<!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->*/}
              <path d="M208 32a176 176 0 1 1 0 352 176 176 0 1 1 0-352zm0 384c51.7 0 99-18.8 135.3-50L484.7 507.3c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L366 343.3c31.2-36.4 50-83.7 50-135.3C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416zM192 304c0 8.8 7.2 16 16 16s16-7.2 16-16l0-80 80 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-80 0 0-80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 80-80 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l80 0 0 80z" />
            </svg>
          </g>
        </g>
      }
    </g>
  );
};

export default BodySelectButton;
