import rosQuestions from '../../assets/files/ros-questions.json';
import { notNull } from '../utils';
import { convertItemToQuestion, mapItemToDescLocale, mapItemToLocale } from '../utils/csv';
export function fetchRosQsLocales() {
    const list = rosQuestions.map(mapItemToLocale);
    const descList = rosQuestions.map(mapItemToDescLocale);
    const nList = [...list, ...descList];
    return nList;
}
export function fetchRosQsTreeByKey(key, options) {
    const trQs = fetchRosQuestionsByTreeKey(key);
    return {
        ...options,
        treeKey: key,
        entryQuestionKeys: trQs.map((q) => q.questionKey),
    };
}
export function fetchRosQuestions() {
    return rosQuestions.map(convertItemToQuestion).filter(notNull);
}
export function fetchRosQuestionsByTreeKey(treeKey) {
    return rosQuestions
        .filter((item) => item['tree-key'] === treeKey)
        .map(convertItemToQuestion)
        .filter(notNull);
}
