import classNames from 'classnames';
import produce from 'immer';
import { includes, isEmpty, uniq } from 'lodash';
import { InputHTMLAttributes, memo, useState } from 'react';

import { BiPencil } from 'react-icons/bi';
import { noneAboveKey } from '../../../report/report-config';
import { isTreeStateAnswered } from '../../../utils/models/question-answer';
import { useReportState } from '../../hooks/use-report';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';
import { Key, QuestionType, TreeState } from '../../patient-types';
import ModalSxCollector from '../ModalSxCollector';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import useConfirmationDialog from '../../../hooks/use-confirmation-dialog';
import FaProPlusCircle from '../../../components/FaPro/FaProPlusCircle';

function QsCollapsibleGroupItem(
  props: InputHTMLAttributes<HTMLInputElement> &
    Omit<QuestionProps, 'question'> & {
      optionKey: Key;
      question: QuestionType;
    },
) {
  const { optionKey, locale, treeKey, question, suppressSxCollectors } = props;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();
  const [confirmDialog, confirmationDialogUX] = useConfirmationDialog();

  const { questionKey } = question;

  const [stagedSx, setStagedSx] = useState<Key | null>(null);
  const [{ reportState }, { setReportState }] = useReportState({});

  // console.log('selectedGroup', selectedGroup);
  // console.log('optionQuestion', optionQuestion);
  // console.log('optionAnswerKeys', optionAnswerKeys);
  // console.log('reportState.treeStates', reportState.treeStates);

  const mainTreeStateIdx = reportState.treeStates.findIndex((ts) => ts.treeKey === treeKey);
  const mainTreeState = reportState.treeStates.find((ts) => ts.treeKey === treeKey);

  const mainTreeAnswerKeys = mainTreeState?.answerMap[question.questionKey]?.answerKeys || [];
  const mainTreeHasSxKey = mainTreeAnswerKeys?.includes(optionKey);

  const nonAboveSelected = includes(mainTreeAnswerKeys, noneAboveKey);

  const sxState = reportState.treeStates?.find((ts) => {
    return ts.treeKey === optionKey;
  });

  const stagedTreeState = reportState.treeStates.find((tr) => tr.treeKey === stagedSx);
  const treeHasAnswer = isTreeStateAnswered(stagedTreeState);

  const existingSymptomTree = reportState.treeStates.find((ts) => ts.treeKey === optionKey);

  const isEditingTreeState = treeHasAnswer;
  const checked = (mainTreeHasSxKey && !isEmpty(sxState?.answerMap)) || !!existingSymptomTree;

  const updateReportData = (modalSxState: TreeState) => {

    if (!mainTreeState || mainTreeStateIdx === -1) return null;

    const sxTreeStateIdx = reportState.treeStates.findIndex(
      (ts) => ts.treeKey === stagedSx,
    );

    const nReportState = produce(reportState, (draft) => {
      // update question state in main tree
      if (mainTreeState?.treeKey && mainTreeStateIdx !== -1) {
        // remove none above key
        const nKeys = uniq([...mainTreeAnswerKeys, stagedSx]).filter(
          (n) => n !== noneAboveKey,
        ) as string[];

        draft.treeStates[mainTreeStateIdx] = {
          ...mainTreeState,
          answerMap: {
            ...mainTreeState?.answerMap,
            [questionKey]: {
              ...mainTreeState?.answerMap[questionKey],
              answerKeys: nKeys,
            },
          },
        };
      }

      if (sxTreeStateIdx !== -1) {
        // update detail of collapsible
        // sx tree state found
        draft.treeStates[sxTreeStateIdx] = modalSxState;
      } else {
        // sxtree not found
        draft.treeStates.push(modalSxState);
      }
    });

    setReportState(nReportState);
    return nReportState;
  }

  function deleteSymptom() {
    // delete
    const sxTreeStateIdx = reportState.treeStates.findIndex(
      (ts) => ts.treeKey === optionKey,
    );

    // found
    if (sxTreeStateIdx !== -1) {
      setReportState(
        produce(reportState, (draft) => {
          if (!mainTreeState?.treeKey) return;

          draft?.treeStates.splice(sxTreeStateIdx, 1);

          // delete it from q-category
          const nKeys = mainTreeState?.answerMap?.[questionKey]?.answerKeys || [];
          draft.treeStates[mainTreeStateIdx] = {
            ...mainTreeState,
            answerMap: {
              ...mainTreeState?.answerMap,
              [questionKey]: {
                answerKeys: nKeys.filter((k) => k !== optionKey),
              },
            },
          };
        }),
      );
    }
  }

  return (
    <>
      {stagedSx && (
        <ModalSxCollector
          locale={locale}
          sxKey={stagedSx}
          suppressSxCollectors={suppressSxCollectors}
          updateTreeState={isEditingTreeState}
          onInterimUpdate={(modalSxState) => {
            if (isTreeStateAnswered(modalSxState)) {
              return updateReportData(modalSxState);
            }
            return null;
          }}
          onClose={() => {
            setStagedSx(null);
          }}
          onSave={(modalSxState) => {
            updateReportData(modalSxState);
            setStagedSx(null);
          }}
          onDelete={() => {
            setStagedSx(null);
            deleteSymptom();
          }}
        />
      )}

      <div
        key={optionKey}
        className='t_QsCollapsibleGroupItem form-control cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setStagedSx(optionKey);
        }}
      >
        {confirmationDialogUX}

        <label
          className={classNames(
            'label gap-2 px-4 py-3 cursor-pointer',
            checked && ' bg-primary bg-opacity-5 rounded-lg',
          )}
        >
          <div className="flex-row gap-3 items-center">
            <input
              type='checkbox'
              checked={checked}
              className={classNames(
                'checkbox checkbox-lg rounded',
                nonAboveSelected ? 'bg-black bg-opacity-10' : 'checkbox-primary',
              )}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onChange={async (e) => {
                e.stopPropagation();
                e.preventDefault();

                if (!checked) {
                  setStagedSx(optionKey);
                  return;
                }

                const yes = await confirmDialog("", sltStr({ key: 'msg-delete-answers' }), undefined, sltStr({ key: 'btn-delete-symptom' }));
                if (!yes) return;

                deleteSymptom();

              }}

            />
            <span className={classNames(`o_${optionKey}`, 'label-text text-base font-medium')}>{t(optionKey)}</span>
          </div>
          {!checked ? (
            <div className='flex gap flex-nowrap items-center'>
              <FaProPlusCircle />
            </div>
          ) : (
            <div className="flex gap flex-nowrap ml-auto items-center">
              <button
                className='btn btn-ghost px-0'
                title={sltStr({ key: 'btn-edit' })}
                aria-label={sltStr({ key: 'btn-edit' })}
                onClick={() => {
                  setStagedSx(optionKey);
                }}
              >
                <BiPencil size={22} className='cursor-pointer' />

              </button>
            </div>
          )}
        </label >
      </div >
    </>
  );
}

export default memo(QsCollapsibleGroupItem);
