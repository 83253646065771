import { memo } from 'react';
import LoadingAnim from '../../components/LoadingAnim';
import { useRetrieveMessageQuery } from '@cyren/common-lib';
import { useParams } from 'react-router';
import Footer from '../../app/Footer';
import PaSurveyHeader from '../../app/PaSurveyHeader';
import ReactMarkdown from 'react-markdown';

function PaPatientMessageRedirect() {

  const params = useParams<{
    referralMessageId?: string;
  }>();


  const { data, loading } = useRetrieveMessageQuery({
    fetchPolicy: 'cache-and-network',
    skip: !params.referralMessageId,
    variables: {
      uniqueID: params.referralMessageId!,
    },
    onCompleted: (nData) => {
      const messageHyperlink = nData?.retrieveMessage?.data?.attributes?.messageContent?.resourceUrl;

      if (messageHyperlink) {
        window.location.href = messageHyperlink;
      }
    },
  });


  if (loading || data?.retrieveMessage?.data?.attributes?.messageContent?.resourceUrl) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-10 w-full' />
      </div>
    );
  }

  return (
    <div className='flex-col flex-1 w-full gap-5 px-5 pt-2'>
      <div className='flex-col flex-1 w-full gap-6'>
        <PaSurveyHeader />

        <div className='text-xl flex-col gap-3'>
          <div className='prose'>
            <ReactMarkdown>{data?.retrieveMessage?.data?.attributes?.messageContent?.resourceText}</ReactMarkdown>
          </div>
        </div>

        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default memo(PaPatientMessageRedirect);
