import {
  fetchPmhxQsTreeByKey,
  fetchRosQsTreeByKey,
  fetchSdohQsTreeByKey,
  fetchSxQsTreeByKey,
  fetchSxStartTree,
  fetchTriageTree,
} from '@cyren/common-lib';
import { isArray, isEmpty, keys } from 'lodash';
import { Key, KeyHelper, Keys, QuestionTreeType, SurveyType } from './types';

export const CCnPIsTreeKey = 'tr-cc-and-pis';
export const CCnPIsQsKey = 'q-cc-and-pis';
export const LocToSxTreeKey = 'loc-to-sx';
export const StartQsTreeKey = 'start';

export const keyHelpers: Record<string, KeyHelper> = {
  // deprecated, not used
  sxToPiKeys: ({ question, treeState }) => {
    const consolidatedAnswerKeys = keys(treeState?.answerMap).reduce((p, qsKey) => {
      const answerMap = treeState?.answerMap[qsKey];

      if (!answerMap || question?.questionKey === qsKey) {
        return p;
      }

      if (
        answerMap?.answerKeys &&
        !isEmpty(answerMap?.answerKeys) &&
        isArray(answerMap?.answerKeys)
      ) {
        return [...p, ...answerMap.answerKeys];
      }

      return p;
    }, [] as Keys);

    return consolidatedAnswerKeys;
  },
};

export const flows: SurveyType[] = [
  {
    shortId: 'er-flow',
    treeKeys: ['patient-info', 'triage', 'cc-v2', 'start', 'ros', 'pmhx', 'sdoh'],
  },
];

export const trees: QuestionTreeType[] = [
  // sx trees
  ...fetchSxStartTree(),

  fetchSxQsTreeByKey('patient-info', { step: 0 }),
  {
    step: 1,
    ...fetchTriageTree(),
  },
  {
    step: 2,
    treeKey: 'cc-v2',
    entryQuestionKeys: ['q-chief-complaint-text'],
  },
  /* { */
  /*   step: 2, */
  /*   treeKey: CCnPIsTreeKey, */
  /*   entryQuestionKeys: [CCnPIsQsKey], */
  /* }, */
  fetchSxQsTreeByKey('start', { step: 3 }),
  fetchRosQsTreeByKey('ros', { step: 4 }),
  fetchPmhxQsTreeByKey('pmhx', { step: 5 }),
  fetchSdohQsTreeByKey('sdoh', { step: 6 }),
  {
    treeKey: 'tree-not-found',
    entryQuestionKeys: [],
  },
];

// console.log('trees', trees);

// console.log('getTreesByKey("sx-altered-consciousness")', getTreesByKey('sx-altered-consciousness'));

// const duplicTrees = trees
//   .filter((t) => {
//     const tlist = getTreesByKey(t.treeKey);

//     return size(tlist) > 1 ? t : null;
//   })
//   .filter(notNull);

// console.log('duplicTrees', duplicTrees);

export function getTreeByKey(key: Key) {
  // const lastTree = last(trees) as QuestionTreeType;

  return trees.find((t) => t.treeKey === key) || null;
}

export function getTreesByKey(key: Key) {
  return trees.filter((t) => t.treeKey === key) || [];
}
