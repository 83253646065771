import { memo, useEffect, useState } from 'react';

function App404Refresh() {
  const [time, setTime] = useState(30);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => clearInterval(interval);
    }

    window.location.reload();

    return () => {};
  }, [time]);

  return (
    <div className='p-6 flex-col gap-3'>
      <div>Sorry, the app had been updated.</div>

      <div>
        <button
          className='btn btn-primary'
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh to get update (Auto refresh in {time} seconds.)
        </button>
      </div>
    </div>
  );
}

export default memo(App404Refresh);
