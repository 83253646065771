import { memo, useState, SyntheticEvent } from 'react';

import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Box,
  Grid,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { TPatientVisit } from '../../../graphql-enhanced';
import { FaEdit, FaComments, FaBook, FaBell } from 'react-icons/fa';
import PanelSendAddOnForm from './PanelSendAddOnForm';
import PanelAddAdHocQuestions from './PanelAddAdHocQuestions';
import PanelNotifyPatient from './PanelNotifyPatient';
import PanelSendResource from './PanelSendResource';
import classNames from 'classnames';



type CommunicationMethod = "select" | "addon" | "adhoc" | "notification" | "resource";
type PanelButton = {
  icon: React.ReactNode;
  text: string;
  displayView: CommunicationMethod;
  title: string;
  classes?: string;
}

export type SubmitButtonState = {
  loading: boolean;
  enabled: boolean;
  message: string;
  callback: (() => void) | null;
  temp?: string;
}

function ModalSendCommunication(props: {
  onClose: () => void;
  visit: TPatientVisit;
}) {
  const { onClose, visit } = props;

  const [displayView, setDisplayView] = useState<CommunicationMethod>("select");

  const getDefaultSubmitButtonState = () => {
    return { loading: false, enabled: false, message: "Send", callback: null, temp: 'parent' };
  }

  const [submitButtonState, setSubmitButtonState] = useState<SubmitButtonState>(getDefaultSubmitButtonState);


  const handleClose = (event: SyntheticEvent, reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };



  const buttons: PanelButton[] = [
    { icon: <FaEdit size={50} />, text: 'Additional Forms', displayView: 'addon', title: 'Send Additional Form', classes: 'joyride-step-additional-forms' },
    { icon: <FaComments size={50} />, text: 'Custom Questions', displayView: 'adhoc', title: 'Send Custom Questions', classes: 'joyride-step-custom-questions' },
    { icon: <FaBell size={50} />, text: 'Notifications Messages', displayView: 'notification', title: 'Send Notifications', classes: 'joyride-step-notification-messages' },
    { icon: <FaBook size={50} />, text: 'Resources Materials', displayView: 'resource', title: 'Send Resource Materials', classes: 'joyride-step-resource-materials' },
  ];

  const [activePanelButton, setActivePanelButton] = useState<PanelButton | null>(null);

  return (
    <Dialog open
      onClose={handleClose}
      onClick={(event: React.MouseEvent) => { event.stopPropagation() }}
      maxWidth="sm"
    >
      <DialogTitle>
        {!activePanelButton &&
          <>Send Patient Communication</>
        }
        {activePanelButton &&
          <>
            <IconButton
              aria-label="back"
              onClick={() => {
                setDisplayView("select");
                setActivePanelButton(null);
                setSubmitButtonState(getDefaultSubmitButtonState);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            {activePanelButton.title}
          </>
        }
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {(displayView === "select") &&
          <div>
            <Typography>What type of communication would you like to send?</Typography>
            <Box sx={{ px: 4, py: 2, maxWidth: 400 }}>
              <Grid container spacing={4} className="box-border">
                {buttons.map((button, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    <div className={classNames("flex-center w-full", button.classes)}>
                      <Button
                        variant="outlined"
                        color="inherit"

                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          aspectRatio: '1 / 1',
                          minHeight: 150,
                          maxHeight: 150,
                          maxWidth: 150,
                          padding: 4,
                          textTransform: 'none'
                        }}
                        onClick={() => {
                          setDisplayView(button.displayView);
                          setActivePanelButton(button);
                        }}
                      >
                        {button.icon}
                        <Typography fontSize="0.875rem" fontWeight={600} className="pt-2">{button.text}</Typography>
                      </Button>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>}
        {(displayView === "addon") &&
          <PanelSendAddOnForm visit={visit} setSubmitButtonState={setSubmitButtonState} onClose={onClose} />
        }
        {(displayView === "adhoc") &&
          <PanelAddAdHocQuestions visit={visit} setSubmitButtonState={setSubmitButtonState} onClose={onClose} />
        }
        {(displayView === "notification") &&
          <PanelNotifyPatient visit={visit} setSubmitButtonState={setSubmitButtonState} onClose={onClose} />
        }
        {(displayView === "resource") &&
          <PanelSendResource visit={visit} setSubmitButtonState={setSubmitButtonState} onClose={onClose} />
        }
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          {(displayView !== "select") &&
            <Button variant="contained" color="primary"
              onClick={() => { if (submitButtonState.callback) submitButtonState.callback(); }}
              disabled={!submitButtonState.enabled}
            >
              {submitButtonState.loading && <span className='loading loading-spinner'></span>}
              {submitButtonState.message}
            </Button>
          }
        </DialogActions>
      </DialogContent>
    </Dialog >
  );
}

export default memo(ModalSendCommunication);
