import { uniq } from 'lodash';
import slugify from 'slugify';
import demoQuestions from '../../assets/files/demographic-questions.json';
import startQuestions from '../../assets/files/start-questions.json';
import sxQsCommon from '../../assets/files/sx-questions-common.json';
import sxQsSpecific from '../../assets/files/sx-questions-specific.json';
import { notEmpty } from '../utils';
import { convertItemToQuestion, convertStringToArray, filterQuestions, mapItemToDescLocale, mapItemToLocale, } from '../utils/csv';
const sxQuestions = [
    // ...oldSxQuestions,
    ...filterQuestions(sxQsCommon),
    ...filterQuestions(sxQsSpecific),
    ...filterQuestions(demoQuestions),
    ...filterQuestions(startQuestions),
];
export function fetchSxQsLocales() {
    const list = sxQuestions.map(mapItemToLocale);
    const descList = sxQuestions.map(mapItemToDescLocale);
    // select questions that have options desc but options value is empty.
    // we will try to slugify them and use it
    // case 1. when options-en AVALIABLE and option-keys NOT
    const optionDescs = sxQuestions
        // @ts-ignore
        .filter((q) => q.type === 'select' && !q?.['option-keys'] && q?.['options-en'])
        // @ts-ignore
        .map((q) => q['options-en'])
        .filter(notEmpty);
    const optionsLocale = optionDescs.reduce((p, optionDesc) => {
        const locales = convertStringToArray(optionDesc).map((v) => {
            return {
                key: slugify(v, { lower: true }),
                value: v,
                locale: 'english',
            };
        });
        return uniq([...p, ...locales]);
    }, []);
    // TODO add options and options-en available cases
    // case 2. when both AVALIABLE
    const bothAvailableList = sxQuestions
        .filter((q) => (q.type === 'select' || q.type === 'collapsible-item') &&
        q?.['option-keys'] &&
        // @ts-ignore
        q['options-en'])
        .filter(notEmpty)
        .map((q) => {
        return {
            // @ts-ignore
            ['options-en']: q['options-en'],
            ['option-keys']: q['option-keys'],
        };
    });
    const localeSeqMappedList = bothAvailableList.reduce((p, optionStr) => {
        const localeList = convertStringToArray(optionStr['options-en']);
        const locales = convertStringToArray(optionStr['option-keys']).map((v, idx) => {
            const locale = localeList[idx];
            return {
                key: slugify(v, { lower: true }),
                value: locale,
                locale: 'english',
            };
        });
        return uniq([...p, ...locales]);
    }, []);
    return [...list, ...descList, ...optionsLocale, ...localeSeqMappedList];
}
export function fetchSxQsTreeByKey(key, options) {
    const trQs = fetchSxQuestionsByTreeKey(key);
    return {
        ...options,
        treeKey: key,
        entryQuestionKeys: trQs.map((q) => q.questionKey),
    };
}
export function fetchSxQuestions() {
    return sxQuestions.map(convertItemToQuestion).filter(notEmpty);
}
export function fetchSxQuestionsByTreeKey(treeKey) {
    return sxQuestions
        .filter((item) => item['tree-key'] === treeKey)
        .map(convertItemToQuestion)
        .filter(notEmpty);
}
