import { useSearchParams } from 'react-router-dom';

// TO test electron env
// http://localhost:3404/admin/auth/login?forceElec=1
export function useElectron() {
  const [params] = useSearchParams();
  const forceElec = !!params.get('forceElec');

  const isElectron = forceElec || !!window.electron;

  return {
    isElectron,
    // isElectron: !isElectron,
    elecEnvs: window.electron?.envs,
  } as const;
}
