import { useEffect } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { Keys } from '../patient-types';

export const ErrorState = atomFamily<
  {
    errorKeys: Keys;
  },
  string
>({
  key: 'ErrorState',
  default: {
    errorKeys: [],
  },
});

export function useErrorState({
  containerKey,
  resetOnMount,
}: {
  resetOnMount?: boolean;
  containerKey: string;
}) {
  const [errorState, setErrorState] = useRecoilState(ErrorState(containerKey));

  useEffect(() => {
    if (resetOnMount) {
      setErrorState({
        errorKeys: [],
      });
    }
  }, []);

  return { errorState, setErrorState } as const;
}
