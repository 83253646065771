import {
  Enum_Translation_Locale,
  Maybe,
  WatchQueryFetchPolicy,
  useQuestionTreesLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AppStateCookie } from '../app/use-app-state';
import { convTree } from '../gql-tools/utils/convert';
import { Keys } from './types';
import { PaDataRepoState } from './use-data';

const pageSize = 10000;

export function usePatientTreesData({ keys }: { keys?: Maybe<Keys> }) {
  const [appStateCookie] = useRecoilState(AppStateCookie);
  const locale = appStateCookie?.localeKey || Enum_Translation_Locale.English;

  const [dataState, setDataState] = useRecoilState(PaDataRepoState);
  const { trees, survey } = dataState;

  const treeKeysNotFound = (keys || []).filter((key) => !trees.find((tr) => tr.treeKey === key));

  const [fetchTreesGql, { data: dataTrees, loading: loadingTrees }] = useQuestionTreesLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const list = data?.questionTrees?.data?.map(convTree);

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            const idx = draft.trees.findIndex((t) => t.treeKey === item.treeKey);

            if (idx > -1) {
              draft.trees[idx] = item;
            } else {
              draft.trees.push(item);
            }
          });
        });
      });
    },
  });

  const fetchTrees = async ({
    fetchPolicy,
    keys: nKeys,
  }: {
    fetchPolicy?: WatchQueryFetchPolicy;
    keys: string[];
  }) => {
    if (isEmpty(nKeys)) return;

    // check cache and skip it when there is one fetched
    fetchTreesGql({
      fetchPolicy,
      variables: {
        filters: {
          treeKey: {
            in: nKeys,
          },
          survey: {
            id: {
              eq: survey?.id,
            },
          },
        },
        pagination: {
          pageSize,
        },
      },
    });
  };

  // selected treeId
  useEffect(() => {
    fetchTrees({
      keys: treeKeysNotFound,
    });
  }, [survey?.id, locale, `${treeKeysNotFound}`]);

  return [
    {
      dataState,
      dataTrees,
      loadingTrees,
      survey,
    },
    {
      fetchTrees,
    },
  ] as const;
}
