import { Platform, useConnectAuthMutation } from '@cyren/common-lib';
import { GoogleOAuthProvider, UseGoogleLoginOptions, useGoogleLogin } from '@react-oauth/google';
import classNames from 'classnames';
import produce from 'immer';
import { useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { VscChromeClose } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useElectron } from '../admin/hooks/use-electron';
import { AppStateCookie } from '../app/use-app-state';
import envs from '../common/envs';
import LoadingAnim from '../components/LoadingAnim';
import Modal from '../components/Modal';
import { getErrorMsg } from '../graphql/utils/error';
import { onError as onGqlError } from '../utils/apollo-utils';
import useAuth from './use-auth';

function SignInGoogleContent({
  onError,
  onSuccess,
}: {
  onError?: (err?: string | null) => void;
  onSuccess?: () => void;
}) {
  const { isElectron } = useElectron();
  const [, { handleLogin }] = useAuth();

  const setAppStateCookie = useSetRecoilState(AppStateCookie);

  const [connect, { loading: connectLoading, error }] = useConnectAuthMutation({
    onError: onGqlError,
    onCompleted: async (connectData) => {
      if (connectData?.connect.user) {
        await handleLogin(connectData?.connect);

        setAppStateCookie((st) =>
          produce(st, (dr) => {
            dr.msAuth = false;
          }),
        );
        onSuccess?.();
      }
    },
  });

  const gqlError = getErrorMsg(error);
  useEffect(() => {
    onError?.(gqlError);
  }, [gqlError]);

  const redirectUxConfig: UseGoogleLoginOptions = {
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${envs.clientUrl}/admin/auth/google/callback`,
  };
  const popupUxConfig: UseGoogleLoginOptions = {
    flow: 'implicit',
    // hosted_domain: envs.clientUrl,
    onSuccess: async (codeResponse) => {
      await connect({
        variables: {
          platform: Platform.Web,
          code: codeResponse.access_token,
          type: 'google',
        },
      });
    },
    onError(err) {
      onError?.(`${err}`);
    },
  };

  const authConfig = isElectron ? redirectUxConfig : popupUxConfig;
  // @ts-ignore
  const login = useGoogleLogin(authConfig);

  return (
    <button
      className={classNames(
        'btn btn-lg gap-2 bg-white hover:bg-white border-0 text-black lg:bg-base-200 lg:hover:bg-base-200 justify-start',
      )}
      onClick={async () => {
        login();
      }}
    >
      {connectLoading ? <LoadingAnim width={20} /> : <FcGoogle size='1.4rem' />}
      Continue with Google
    </button>
  );
}

export function SignInModalModal({
  open,
  setOpen,
  onSuccess,
  onError,
}: {
  open?: boolean;
  setOpen?: (n: boolean) => void;
  onSuccess?: () => void;
  onError?: (err?: string | null) => void;
}) {
  return (
    <Modal
      open={open}
      className='lg:w-[400px]'
      onClickBackdrop={() => {
        setOpen?.(false);
      }}
    >
      <div className='flex flex-col flex-1 h-full'>
        <div className='flex justify-end flex-none text-3xl text-center lg:hidden'>
          <span
            className='cursor-pointer'
            onClick={() => {
              setOpen?.(false);
            }}
          >
            <VscChromeClose className='w-6 opacity-70' />
          </span>
        </div>
        <div className='flex-auto flex-center'>
          <div className='flex flex-col gap-4'>
            <h3 className='text-2xl font-bold'>Sign in</h3>
            <div>
              <GoogleOAuthProvider clientId={envs.googleClientId || ''}>
                <SignInGoogleContent
                  onError={onError}
                  onSuccess={() => {
                    onSuccess?.();
                    setOpen?.(false);
                  }}
                />
              </GoogleOAuthProvider>
            </div>
            <p className='pt-4 opacity-50'>
              <span className='text-xs'>
                {
                  'By clicking "Continue with Google", you acknowledge that you agree to CyrenCare’s'
                }{' '}
                <Link
                  to='/page/terms'
                  className='link'
                  onClick={() => {
                    setOpen?.(false);
                  }}
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  to='/page/privacy-policy'
                  className='link'
                  onClick={() => {
                    setOpen?.(false);
                  }}
                >
                  Privacy Policy.
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function SignInGoogle({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (err?: string | null) => void;
}) {
  return (
    <GoogleOAuthProvider clientId={envs.googleClientId || ''}>
      <SignInGoogleContent onError={onError} onSuccess={onSuccess} />
    </GoogleOAuthProvider>
  );
}

export default SignInGoogle;
