import { Enum_Translation_Locale, formatDate, formatDTsToReportDateFormat, notNull, NoYearDateTimeFormat, parseDateV2, SystemDateTimeSec, useUpdatePatientVisitUpdateAdhocCommentMutation } from '@cyren/common-lib';
import classNames from 'classnames';
import { HTMLAttributes, memo, useRef, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { FaShareSquare, FaArrowCircleDown, FaPaperPlane } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';
import { useCopyToClipboard } from 'usehooks-ts';
import { H5 } from '../../common/typo/H';
import { FlowLineConfigT, PHITranslated } from '../../survey/template/template-types';
import { consolidateFlowItem } from '../../survey/template/utils/flow-template';
import { getStringFromLine, getStringFromPhis } from '../../utils/models/template';
import { useElectron } from '../hooks/use-electron';
import { useToastMsgs } from '../hooks/use-toast-msgs';
import useAuth from '../../auth/use-auth';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty, isNil } from 'lodash';
import { PaTreePure } from '../../patient/tree/PaTreePage';
import { TPatientVisit } from '../../graphql-enhanced';
import { AdhocCommentType } from '../../patient/patient-types';
import FaProCommentMedical from '../../components/FaPro/FaProCommentMedical';
import ModalAddComment from './components/ModalAddComment';
import { genMessageLongId } from '../nav-utils';
import { onError } from '../../utils/apollo-utils';
import { GrMoreVertical } from 'react-icons/gr';
import useConfirmationDialog from '../../hooks/use-confirmation-dialog';

const html2pdf = require('html2pdf.js');

function ReportCard({
  line,
  phis,
  demoSignature,
  keyId,
  isAddOnForm,
  adHocData,
  visit,
}: HTMLAttributes<HTMLDivElement> & {
  line: FlowLineConfigT;
  phis?: PHITranslated[];
  demoSignature?: string | null;
  keyId: string;
  isAddOnForm: boolean;
  adHocData?: {
    adHocComments: AdhocCommentType[] | null;
  } | null,
  visit: TPatientVisit | null;
}) {
  const [, copy] = useCopyToClipboard();
  const { isElectron } = useElectron();
  const { showNothingToCopyMsg, showCopiedMsg } = useToastMsgs();
  const [isSendingData, setIsSendingData] = useState(false);

  const [{ user }] = useAuth();
  const [openModalAddComment, setOpenModalAddComment] = useState(false);
  const [editComment, setEditComment] = useState<AdhocCommentType | null>(null);
  const [confirmDialog, confirmationDialogUX] = useConfirmationDialog();

  const [updateAdhocComment, { loading: updatingAdhoc }] = useUpdatePatientVisitUpdateAdhocCommentMutation({
    onError,
  });


  const [{ jobFunction }] = useAuth();

  const downloadableForm = useRef(null);
  const treeKey = line.items[0].reportConfig?.treeKey;

  const isPhiLine = phis && line.items[0].type === 'rep-phi-list';

  const forceVisible = !isNil(line.visibility?.visible?.find(i => i === jobFunction));
  const forceHidden = !isNil(line.visibility?.hidden?.find(i => i === jobFunction));
  const forceCollapsed = !isNil(line.visibility?.collapsed?.find(i => i === jobFunction));
  if (forceHidden || (line.visibility?.default === 'Hidden' && !forceVisible && !forceCollapsed)) {
    return <></>
  }

  const collapsed = forceCollapsed || (line.visibility?.default === 'Collapsed' && !forceVisible && !forceHidden);

  const comments = adHocData?.adHocComments?.filter(comment => comment.deleted !== true && comment.template === line.id &&
    (comment.visitId === line.visitId || (!isAddOnForm && isEmpty(line.visitId))));

  return (
    <Accordion defaultExpanded={!collapsed || false}
      sx={{
        borderRadius: '1rem',
        margin: '0 0 0 0',
        '&.MuiAccordion-root:first-of-type':
        {
          borderRadius: '1rem'
        },
        '&.MuiAccordion-root.Mui-expanded':
        {
          margin: '0 0 0 0',
        },
        '&.MuiAccordion-root:last-of-type':
        {
          borderRadius: '1rem'
        },
        '&:before': { display: 'none' }  // to hide shadow top border element
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${keyId}-content`}
        id={`${keyId}-header`}
        sx={{
          padding: '0 0.5rem',
          margin: '0.25rem 0',
          minHeight: '0.25rem',
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '.MuiAccordionSummary-content': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '&.Mui-expanded': {
            margin: '0.25rem 0rem 0rem 0rem',
            minHeight: '0.25rem',
          }
        }}
      >
        <div className='card-body p-1 gap-0 flex-row justify-between'>
          <div className='flex-center'>
            <H5>
              <div className='flex items-center'>
                {line.label}
                {isAddOnForm &&
                  <FaPaperPlane className="ml-1" size={17} />
                }
              </div>
            </H5>
          </div>

          <div className='flex-row'>
            <div className='flex flex-end relative items-center'>

              <span className='px-2 cursor-pointer h-fit w-fit'
                role="button"
                title="Add Comment"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenModalAddComment(true);
                }}>
                <FaProCommentMedical width={17} />
              </span>

              {demoSignature && (
                <span className='p-1 cursor-pointer h-fit w-fit'
                  role="button"
                  title="Download PDF"
                  tabIndex={0}
                  onClick={(event) => {
                    event.stopPropagation();
                    const element = downloadableForm.current;

                    const filename = `${visit?.firstName || visit?.id} ${visit?.lastName || visit?.id} ${treeKey}.pdf`

                    html2pdf()
                      // @ts-ignore
                      .from(element.innerHTML)
                      .set({
                        html2canvas: {
                          width: 1024,
                          scale: 1,
                        },
                        filename,
                        jsPDF: { format: 'a4' },
                        margin: [10, 10, 10, 10],
                        pagebreak: { avoid: 'img' }
                      })
                      .save();

                  }}>
                  <FaArrowCircleDown size={17} />
                </span>
              )}

              {isElectron && (
                <span
                  className='p-1 cursor-pointer h-fit w-fit'
                  onClick={(event) => {
                    event.stopPropagation();

                    let outputStr = '';
                    if (isPhiLine) {
                      outputStr = getStringFromPhis({ line, phis });
                    } else {
                      outputStr = getStringFromLine(line);
                    }

                    if (outputStr) {
                      setIsSendingData(true);
                      window.electron?.ipcRenderer?.invokeMessage('send', [`${outputStr}\n`]).finally(() => setIsSendingData(false));
                    }
                  }}
                >
                  {isSendingData ? <span className='loading loading-xs loading-spinner'></span> : <FaShareSquare size={17} />}
                </span>
              )}

              <span
                className='p-1 cursor-pointer h-fit w-fit'
                onClick={(event) => {
                  event.stopPropagation();
                  let outputStr = '';
                  if (isPhiLine) {
                    outputStr = getStringFromPhis({ line, phis });
                  } else {
                    outputStr = getStringFromLine(line);
                  }

                  if (outputStr) {
                    copy(`${outputStr}\n`);
                    showCopiedMsg();
                  } else {
                    showNothingToCopyMsg();
                  }
                }}
              >
                <IoMdCopy size={17} />
              </span>
              <span className='p-2 cursor-pointer h-fit w-fit hidden'>
                <BiPencil size={17} />
              </span>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        id={`${keyId}-content`}
        sx={{
          margin: '0px 0px',
          padding: '0rem 0.5rem 0.25rem 0.5rem'
        }}
      >
        <div className='card-body p-4 md:p-1 gap-0'>
          <div>
            {line.items
              .map((item) => {
                const { id: itemId, type, reportConfig } = item;

                if (type === 'rep-report-template') {
                  return (
                    <div key={itemId}>
                      <div>{item.label}</div>
                      {reportConfig?.lines.map((reportLine, lineIdx) => {
                        return (
                          <div key={`${lineIdx}-${reportLine.id}`}>
                            <div className="flex-row gap-2">
                              <span className="flex-center">
                                {reportLine.output}
                              </span>
                              {demoSignature && lineIdx === 0 &&
                                <>
                                  <span className="flex-center" style={{ maxWidth: '5em' }}>
                                    <img src={demoSignature} alt="A patient signature" />
                                  </span>
                                </>}
                            </div>
                            {reportLine.sublines.map((subline, sublineIdx) => {
                              return (
                                <div key={`subline-${sublineIdx}`}>
                                  {subline.output}
                                  {subline.items.map((sublineItem) => {
                                    return (
                                      <div key={sublineItem.id} className='pl-6'>
                                        {sublineItem.output}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                // rendering PHI section
                if (phis && type === 'rep-phi-list') {
                  return (
                    <div key={itemId}>
                      {phis.map((phi, phiIdx, array) => {
                        // don't show redundant date information
                        const onsetDate = formatDTsToReportDateFormat(phi.onsetDate);
                        const lastOnsetDate = phiIdx > 0 ? formatDTsToReportDateFormat(array[phiIdx - 1].onsetDate) : '';
                        const showOnsetDate = onsetDate !== lastOnsetDate;

                        return (
                          <div className={classNames(phiIdx && "pt-2")} key={`${phiIdx} - ${phi.treeKey}-${phi.onsetDate}`}>
                            {showOnsetDate && <div >{onsetDate}</div>}
                            <div className='pl-20'>{phi.lineOutput}</div>
                            {phi.sublinesOutput.map((sublineOutput, sublineIdx) => {
                              return (
                                <div className='pl-20' key={`subline-${sublineIdx}`}>
                                  {sublineOutput}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                const itemOutput = consolidateFlowItem({
                  item,
                });

                return itemOutput;
              })
              // .filter((lineStr) => !!trim(lineStr))
              .filter(notNull)}
          </div>

          {confirmationDialogUX}
          {!isEmpty(comments) &&
            <div>
              <hr className="my-2" />
              {
                comments?.map(comment => {
                  const usersOwnComment = comment.userId === user?.id;
                  return (
                    <div key={comment.uniqueId} className="text-xs p-1 flex-row">
                      <div>
                        <div className="opacity-75">
                          <b>{comment.userName}</b>
                          <span className="text-xs-extra pl-2">
                            {formatDate(parseDateV2({ dateStr: comment.timestamp }),
                              { formatStr: NoYearDateTimeFormat })}
                          </span>
                          {comment.editedTimestamp && <span className="text-xs-extra pl-2"><i>Edited</i></span>}

                        </div>
                        <div className="opacity-75">{comment.commentText}</div>
                      </div>
                      {usersOwnComment &&
                        <div className="ml-auto">
                          <div className=" flex-row" >
                            <div className='dropdown dropdown-bottom dropdown-end'>
                              <label tabIndex={0} className='btn btn-ghost opacity-75 px-0 ml-2 py-0'>
                                <GrMoreVertical />
                              </label>
                              <ul
                                tabIndex={0}
                                className='dropdown-content z-[100] menu p-2 shadow bg-base-100 rounded-box'
                              >
                                <li>
                                  <a
                                    onClick={() => {
                                      setEditComment(comment);
                                      setOpenModalAddComment(true);
                                    }}
                                  >
                                    <span className=''>Edit</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={async () => {
                                      if (!await confirmDialog("", "Are you sure you want to delete this comment?")) {
                                        return;
                                      }

                                      await updateAdhocComment({
                                        variables: {
                                          id: visit?.id || "0",
                                          data: {
                                            adHocState: {
                                              adHocComments: [{
                                                ...comment,
                                                deleted: true
                                              }]
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    <span className=''>Delete</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  );
                })
              }
            </div>
          }

          {openModalAddComment &&
            <ModalAddComment
              existingComment={editComment}
              onClose={() => { setOpenModalAddComment(false); setEditComment(null); }}
              onSave={(comment: string) => {
                updateAdhocComment({
                  variables: {
                    id: visit?.id || "0",
                    data: {
                      adHocState: {
                        adHocComments: [{
                          visitId: line.visitId || visit?.id || "",
                          template: line.id,
                          userId: user?.id || "",
                          userName: user?.displayName || "",
                          commentText: comment,
                          uniqueId: genMessageLongId(),
                          timestamp: formatDate(new Date(), { formatStr: SystemDateTimeSec }) // SystemDateTimeSec
                        }]
                      }
                    }
                  },
                }).then(() => {
                  setOpenModalAddComment(false);
                  setEditComment(null);
                });
              }}
              onEdit={(editedComment: AdhocCommentType) => {
                updateAdhocComment({
                  variables: {
                    id: visit?.id || "0",
                    data: {
                      adHocState: {
                        adHocComments: [
                          editedComment
                        ]
                      }
                    }
                  },
                }).then(() => {
                  setOpenModalAddComment(false);
                  setEditComment(null);
                });
              }}
            />}
          {(demoSignature && treeKey) && (
            <div ref={downloadableForm} style={{ display: "none" }}>
              <PaTreePure
                treeKey={treeKey}
                preloadNext={false}
                mode="pdf"
                locale={Enum_Translation_Locale.English}
                noHeader
              />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion >
  );
}

export default memo(ReportCard);
